<template>
  <div
    v-if="checkboxField && presetCheckboxValues !== null"
    class="d-flex form-label align-items-center"
  >
    <div class="me-2">
      <GreenifiedCheckbox
        size="large"
        :required="checkboxField.required === 0 ? false : true"
        :id="checkboxField.key"
        :name="checkboxField.key"
        :modelValue="localCheckboxValues"
        :isChecked="checked"
        @input="(event: Event) => {
          if (checkboxField) inputCheckbox(event, checkboxField, trueValue, falseValue)
          }"
        :trueValue="trueValue"
        :falseValue="falseValue"
      />
    </div>

    <label v-if="checkboxField.type === 'checkbox'" :for="option" :key="option">
      <div
        v-html="
          checkboxField.name !== 'lest_og_godkjent'
            ? option
            : option.replace(/<a(.*?)>/, `<a href='${localeUrl}'>`)
        "
      ></div>
    </label>
    <label v-else v-html="checkboxField.message"> </label>
  </div>
</template>

<script setup lang="ts">
import {
  CheckboxFieldType,
  TrueFalseFieldType
} from "@/library/types/structure/subfieldTypes";
import { useI18n } from "vue-i18n";
import { withDefaults, defineProps, defineEmits, ref } from "vue";
import { FormFields } from "@/library/types/structure/formStructure";
import GreenifiedCheckbox from "@/components/common/GreenifiedCheckbox.vue";

export type InputValue = {
  key: string;
  name: string;
  value: string | number | null;
};

type CheckboxFieldProps = {
  presetCheckboxValues: FormFields["key"];
  checkboxField: CheckboxFieldType | TrueFalseFieldType | null;
  checked?: boolean;
  trueValue?: InputValue["value"];
  falseValue?: InputValue["value"];
  option?: string;
};

const checkboxFieldProps = withDefaults(defineProps<CheckboxFieldProps>(), {
  presetCheckboxValues: () => ({}),
  checkboxField: null,
  checked: false,
  trueValue: null,
  falseValue: null,
  option: ""
});

const { locale } = useI18n();
const emit = defineEmits(["input", "update:presetCheckboxValues", "delete"]);
const localCheckboxValues = ref(
  checkboxFieldProps.presetCheckboxValues as number
);

const inputCheckbox = (
  event: Event,
  field: CheckboxFieldType | TrueFalseFieldType,
  trueValue: InputValue["value"],
  falseValue: InputValue["value"]
) => {
  const eventTarget = event.target as HTMLInputElement;
  if (eventTarget.value === "on" || eventTarget.checked) {
    localCheckboxValues.value = trueValue as number;
    emit("update:presetCheckboxValues", localCheckboxValues.value);
    emit("input", {
      key: field.key,
      name: field.name,
      value: trueValue
    });
  } else {
    emit("delete");
    emit("input", {
      key: field.key,
      name: field.name,
      value: falseValue
    });
  }
};

const localeUrl = () => {
  let url = "";
  switch (locale.value.toLocaleLowerCase()) {
    case "sv":
      url = "https://files.inputinterior.se/index.php/s/yMt5RDaR83we8SA";
      break;
    case "da":
      url = "https://files.inputinterior.se/index.php/s/XDzRNwHXWyqaRxG";
      break;
    case "fi":
      url = "https://files.inputinterior.se/index.php/s/ZaM9BRGMZK7edLs";
      break;
    case "no":
      url = "https://files.inputinterior.se/index.php/s/Z5EZ8cMA9bNpMEc";
      break;
    default:
      url = "https://files.inputinterior.se/index.php/s/yMt5RDaR83we8SA";
      break;
  }
  return url;
};
</script>
