<template>
  <div class="custom-select" @blur="openMenu = false">
    <div
      class="selected nav-link"
      :class="{ open: openMenu }"
      @click="openMenu = !openMenu"
    >
      <p class="cursor-pointer">
        {{
          languageOptions
            ? languageOptions[currLanguage.toLocaleLowerCase() as keyof Domains]
            : ""
        }}
      </p>
      <img :src="dropArrow" alt="dropdown-arrow" class="dropdown-arrow" />
    </div>
    <div class="items" :class="{ selectHide: !openMenu }">
      <div
        v-for="(option, optionKey) of languageOptions"
        :key="optionKey"
        @click="selectLanguage(optionKey.toLocaleLowerCase() as I18nLocale)"
      >
        {{ option }}
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, computed } from "vue";
import dropArrow from "@/assets/img/drop-arrow-white.svg";
import { useI18n } from "vue-i18n";
import { Domains, I18nMessage } from "@/library/types/locales/i18nMessage";
import { I18nLocale } from "@/library/types/locales/locales";
import { useIndexStore } from "@/store";
import { useRouter } from "vue-router";
import { useAuthStore } from "@/store/auth";
import { getLocaleFromLocationHost } from "@/library/helpers/getLocaleFromLocationHost";

const { messages, locale } = useI18n();
const store = useIndexStore();
const authStore = useAuthStore();
const currLanguage = ref(
  store.currentProjectLanguage ??
    getLocaleFromLocationHost(window.location.host)
);
const openMenu = ref(false);

const { currentRoute } = useRouter();

const selectLanguage = async (selectedLanguage: I18nLocale) => {
  if (selectedLanguage === store.currentProjectLanguage) {
    openMenu.value = false;
    return;
  }
  openMenu.value = false;
  currLanguage.value = selectedLanguage;
  store.changeProjectLanguage(selectedLanguage, locale);

  if (!authStore.isAuthenticated) return;

  if (currentRoute.value.params.token) {
    await store.getGuestLocaleTranslations(
      store.currentProject,
      currentRoute.value.params.token as string,
      messages.value[selectedLanguage]
    );
    return;
  }
  await store.getLocaleTranslations(
    store.currentProject,
    messages.value[selectedLanguage]
  );
  return;
};

const languageOptions = computed(() => {
  const currentHost = window.location.host;

  if (currentHost === "inventory-staging.greenified.se") {
    return (messages.value["sv"] as I18nMessage).list.domains?.[
      "inventory-staging.greenified.se"
    ]?.locales;
  }
  const currentLocale = locale.value.toLocaleLowerCase();
  return (messages.value[currentLocale] as I18nMessage)?.list?.domains?.[
    window.location.host as keyof I18nMessage["list"]["domains"]
  ]?.locales;
});
</script>

<style scoped>
.custom-select {
  position: relative;
  text-align: left;
  outline: none;
  font-weight: 500;
  white-space: nowrap;
  font-size: 12.5px;
  min-width: max-content;
}

.custom-select .selected {
  border-radius: 6px;
  border: 1px solid transparent;
  padding-left: 0.3rem;
  cursor: pointer;
  user-select: none;
  display: flex;
  align-items: center;
}

.selected p {
  min-width: 1rem;
  max-width: 6rem;
}

.dropdown-arrow {
  margin-left: 0.5rem;
  width: 8px;
  height: 8px;
  transform: rotate(-90deg);
}

.custom-select .selected.open {
  border-radius: 6px 6px 0px 0px;
}

#white:after {
  border-color: #000000 transparent transparent transparent !important;
}

#black:after {
  border-color: white transparent transparent transparent !important;
}

.custom-select .items {
  margin-top: 0.6rem;
  border-radius: 0px 0px 6px 6px;
  overflow: hidden;
  position: absolute;
  min-width: 3.4rem;
  left: 0;
  z-index: 10;
  min-width: 100%;
  font-weight: 600;
  background-color: #000000;
}

.custom-select .items div {
  padding: 0.4rem;
  cursor: pointer;
  user-select: none;
}

.custom-select .items div:hover {
  text-decoration: underline;
}

.selectHide {
  display: none;
}
</style>
