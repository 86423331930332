<template>
  <div class="pb-4 position-relative">
    <TableHeaderRow
      class="mt-3"
      v-if="!productHeaders.length"
      :tableHeaders="null"
      :noRowsText="noRowsText"
    />

    <template
      v-for="(productHeader, index) in sortedGreenifiedHeaderRows(
        filteredProductHeaders,
        mainTableSortingOptions
      )"
      :key="index"
    >
      <TableHeaderRow
        v-if="index === 0"
        :tableHeaders="getSaleTableColumns(productHeader, tableType).columns"
        v-model:sortingOptions="mainTableSortingOptions"
        type="main"
        :hideHeaderCheckbox="true"
        isSticky
      />
      <div class="border-bottom" v-if="productHeader.items.length">
        <MainTableRow
          :tableColumns="getSaleTableColumns(productHeader, tableType).columns"
          :rowImage="productHeader.images?.bild_1?.url ?? ''"
          :toggleSubTable="
            showSubTable &&
            selectedProductGroupForShowingItems === productHeader.id
          "
          :showCheckbox="false"
          @showSubTable="toggleSubTable(productHeader)"
          @showProductInformation="
            selectedProductForShowingInformation = productHeader
          "
          :type="tableType"
          :rowAction="
            productHeader.missingOnGreenified &&
            !productHeader.loadingStatus &&
            !productHeader.loading
              ? ['tooltip']
              : ['handleVariant']
          "
          :buttonText="t('button.seeProducts')"
          :disabled="productHeader.missingOnGreenified"
          :rowStatus="getRowStatus(productHeader)"
          @selectAction="action => handleSelectedAction(action, productHeader)"
          :filteredAmount="
            productHeader.quantity !== productHeader.items.length
              ? productHeader.items.length
              : null
          "
        />
        <div
          class="gr-color subtable position-relative mb-4"
          v-if="
            selectedProductGroupForShowingItems === productHeader.id &&
            showSubTable
          "
        >
          <div class="left-frame gr-color"></div>
          <template
            v-for="(productItem, index) in sortedGreenifiedItemRows(
              productHeader.items,
              subTableSortingOptions
            )"
            :key="index"
          >
            <TableHeaderRow
              v-if="index === 0"
              :tableHeaders="getSubSaleColumns(productItem, tableType).columns"
              v-model:sortingOptions="subTableSortingOptions"
              type="sub"
              :hideHeaderCheckbox="true"
              :soldProducts="productsMarkedAsSold"
              :productsForSale="productHeader.salda"
              :toggle-checkbox="productHeader.salda === productsMarkedAsSold"
              @update:toggleCheckbox="
                isChecked => {
                  if (isChecked) return markAllProductsAsSold(productHeader);
                  return unmarkAllProductsAsSold(productHeader);
                }
              "
            />
            <template v-if="checkFilter(productItem, productHeader)">
              <SubTableRow
                :subTableColumns="getSubSaleColumns(productItem, tableType)"
                :tableType="tableType"
                hideCheckbox
                @showHistory="
                  modalType = 'history';
                  productHistoryId = productItem.id;
                "
                :rowIsChecked="
                  productsForUpdatingSoldStatus[productItem.id] === '1'
                "
                @selectSubRow="(checked: boolean) => {
                  if (checked) return handleSelectSubRow(productItem, productHeader.salda)
                  return handleDeselectSubRow(productItem, productHeader.salda)
              }"
                :disableCheckbox="productHeader.salda === productsMarkedAsSold"
              />
            </template>
          </template>
          <div class="right-frame gr-color"></div>
        </div>
      </div>
    </template>
  </div>
  <TableFooter
    v-if="showTableFooter"
    :footerText="`${t(
      'label.markedAsSold'
    )} ${productsMarkedAsSold}/${totalAmountOfSoldProducts}`"
    :actionStatuses="['US']"
    :toggleDisabledButtons="
      Object.keys(productsForUpdatingSoldStatus).length === 0
    "
    @action="verifyUpdateSoldProductStatus = true"
  />

  <GreenifiedModal
    type="verification"
    v-if="verifyUpdateSoldProductStatus"
    @close="verifyUpdateSoldProductStatus = false"
    @confirm="
      updateSoldProducts();
      showTableFooter = false;
    "
    :title="t('title.updateSoldProducts')"
    :text="t('paragraph.verifyUpdateSoldStatus')"
  />

  <GreenifiedModal
    v-if="showUpdateSuccess"
    type="response"
    :title="t('title.updateSuccessful')"
    :text="t('paragraph.soldStatusIsUpdated')"
    @close="
      showUpdateSuccess = false;
      emit('getProducts');
    "
  />

  <HistoryModal
    v-if="modalType === 'history'"
    :productId="productHistoryId"
    @close="modalType = null"
  />

  <ProductGroupInformation
    v-if="selectedProductForShowingInformation"
    @update="
      selectedProductForShowingInformation = null;
      emit('getProducts');
    "
    @close="selectedProductForShowingInformation = null"
    :productId="selectedProductForShowingInformation.items[0].id"
    :productName="selectedProductForShowingInformation.benamn"
    :supplierCode="selectedProductForShowingInformation.levkod"
  ></ProductGroupInformation>

  <GreenifiedModal
    v-if="modalType === 'productIsReserved'"
    @close="modalType = null"
    :type="'response'"
    :closable="false"
    :title="t('title.productIsReservedOnGreenified')"
    ><template #container>
      <div class="row">
        <div class="col">
          <p class="text-small">
            {{ t("paragraph.productIsReservedOnGreenified") }}
          </p>
        </div>
      </div>
      <div class="row mt-3">
        <div class="col-12">
          <p class="fw-bolder">
            {{ t("title.contact") }}
          </p>
        </div>
      </div>
      <div class="row mt-2">
        <div class="col-12 d-flex align-items-center">
          <p class="me-3">+46 (0)200 77 00 60</p>
          <p>inventory@greenified.se</p>
        </div>
      </div>
    </template></GreenifiedModal
  >
  <RemoveGreenifiedProductModal
    v-if="productToDelete"
    @close="productToDelete = null"
    @refresh="
      productToDelete = null;
      emit('getProducts');
    "
    :greenifiedProductHeaderToRemove="productToDelete"
  />
</template>

<script lang="ts" setup>
import { GreenifiedProductHeader } from "@/library/types/product/productGroup";
import {
  withDefaults,
  defineProps,
  ref,
  Ref,
  watch,
  defineEmits,
  computed
} from "vue";
import { GreenifiedItem } from "@/library/types/product/productItem";
import SubTableRow from "./tableRows/SubTableRow.vue";
import TableHeaderRow from "./tableHeader/TableHeaderRow.vue";
import MainTableRow from "./tableRows/MainTableRow.vue";
import {
  getSaleTableColumns,
  getSubSaleColumns,
  SelectableAction
} from "@/library/helpers/structureTableColumns";
import {
  sortedGreenifiedHeaderRows,
  sortedGreenifiedItemRows
} from "@/library/helpers/sortTableRows";
import HistoryModal from "../modal/HistoryModal.vue";
import { useI18n } from "vue-i18n";
import { Actions } from "@/library/types/actions/actions";
import { FilterOptions } from "@/library/types/filter/filter";
import { SortingSettings } from "@/library/types/sorting/sorting";
import ProductGroupInformation from "../ProductGroupInformation.vue";

import GreenifiedModal from "../modal/GreenifiedModal.vue";
import RemoveGreenifiedProductModal from "../modal/RemoveGreenifiedProductModal.vue";
import TableFooter from "./tableFooter/TableFooter.vue";
import { updateProductsSoldStatus } from "@/library/api/products";
import { useIndexStore } from "@/store";

type GreenifiedProductTableProps = {
  tableType?: "sale" | "sold";
  filter: FilterOptions;
  sortingSettings: SortingSettings;
  translateHeaders: string[];
  noRowsText?: string;
  productHeaders: GreenifiedProductHeader[];
};

const greenifiedProductTableProps = withDefaults(
  defineProps<GreenifiedProductTableProps>(),
  {
    tableType: "sale",
    filter: () => ({
      categories: {},
      intervals: {}
    }),
    sortingSettings: () => ({
      col: "updated_at",
      asc: false
    }),
    translateHeaders: () => [],
    noRowsText: "",
    rowAction: "handleVariant",
    productHeaders: () => [],
    totalSales: 0,
    salesTitle: ""
  }
);

const { t } = useI18n();
const emit = defineEmits(["getProducts"]);
const indexStore = useIndexStore();
const selectedProductForShowingInformation: Ref<GreenifiedProductHeader | null> =
  ref(null);
const showUpdateSuccess = ref(false);
const verifyUpdateSoldProductStatus = ref(false);
const showTableFooter = ref(false);
const productToDelete: Ref<GreenifiedProductHeader | null> = ref(null);
const productsForUpdatingSoldStatus: Ref<{ [key: string]: "0" | "1" }> = ref(
  {}
);
const totalAmountOfSoldProducts = ref(0);
const mainTableSortingOptions = ref(
  greenifiedProductTableProps.sortingSettings
);
const subTableSortingOptions = ref(greenifiedProductTableProps.sortingSettings);
const showSubTable = ref(false);
const productHistoryId = ref("");

const modalType: Ref<Actions | null> = ref(null);

const selectedProductGroupForShowingItems: Ref<
  GreenifiedProductHeader["id"] | null
> = ref(null);

const filteredProductHeaders = computed(() => {
  const productHeaders = [...greenifiedProductTableProps.productHeaders];
  const currFilteredProductHeaders: GreenifiedProductHeader[] = [];

  productHeaders.forEach(productHeader => {
    const isGroupMatching = checkFilter(null, productHeader);
    const matchingItems = filteredProductItems(productHeader);
    const hasMatchingItems = matchingItems.length > 0;

    if (isGroupMatching || hasMatchingItems) {
      currFilteredProductHeaders.push({
        ...productHeader,
        items: matchingItems
      });
    }
  });

  if (Object.keys(greenifiedProductTableProps.filter.categories).length) {
    indexStore.calculateMaxQuantity(currFilteredProductHeaders);
  } else {
    indexStore.calculateMaxQuantity(productHeaders);
  }

  return currFilteredProductHeaders;
});

const filteredProductItems = (productHeader: GreenifiedProductHeader) => {
  return productHeader.items.filter(productItem =>
    checkFilter(productItem, productHeader)
  );
};

const productsMarkedAsSold = computed(() => {
  let soldProductsAmount = 0;

  for (const key in productsForUpdatingSoldStatus.value) {
    if (productsForUpdatingSoldStatus.value[key] === "1") {
      soldProductsAmount += 1;
    }
  }

  return soldProductsAmount;
});

const updateSoldProducts = async () => {
  const result = await updateProductsSoldStatus(
    productsForUpdatingSoldStatus.value
  );

  if (!result.success) return;
  showUpdateSuccess.value = true;
};

const handleSelectSubRow = (
  productItem: GreenifiedItem,
  amountOfSoldProducts: number
) => {
  if (
    amountOfSoldProducts ===
    Object.keys(productsForUpdatingSoldStatus.value).length
  )
    return;

  if (
    productItem.id in productsForUpdatingSoldStatus.value &&
    productsForUpdatingSoldStatus.value[productItem.id] === "1"
  )
    return;

  productsForUpdatingSoldStatus.value[productItem.id] = "1";
  showTableFooter.value = true;

  totalAmountOfSoldProducts.value = amountOfSoldProducts;

  return;
};

const handleDeselectSubRow = (
  productItem: GreenifiedItem,
  amountOfSoldProducts: number
) => {
  const productItemIsSavedAsSold = productItem.sold === "1";

  if (productItemIsSavedAsSold) {
    productsForUpdatingSoldStatus.value[productItem.id] = "0";
  } else {
    delete productsForUpdatingSoldStatus.value[productItem.id];
  }

  totalAmountOfSoldProducts.value = amountOfSoldProducts;
  showTableFooter.value = true;

  return;
};

const markAllProductsAsSold = (productHeader: GreenifiedProductHeader) => {
  if (productHeader.salda === productsMarkedAsSold.value) return;

  for (let i = 0; i < productHeader.items.length; i++) {
    const productItem = productHeader.items[i];
    productsForUpdatingSoldStatus.value[productItem.id] = "1";

    if (productHeader.salda === productsMarkedAsSold.value) {
      break;
    }
  }

  totalAmountOfSoldProducts.value = productHeader.salda;
  showTableFooter.value = true;
  return;
};

const unmarkAllProductsAsSold = (productHeader: GreenifiedProductHeader) => {
  productHeader.items.forEach(productItem => {
    if (productItem.id in productsForUpdatingSoldStatus.value) {
      const productItemIsSavedAsSold = productItem.sold === "1";
      if (!productItemIsSavedAsSold) {
        delete productsForUpdatingSoldStatus.value[productItem.id];
      }
    }
  });
  totalAmountOfSoldProducts.value = productHeader.salda;
  showTableFooter.value = true;

  return;
};

const handleSelectedAction = (
  action: SelectableAction["value"],
  productGroup: GreenifiedProductHeader
) => {
  if (action === 1) {
    window.open(productGroup.permalink, "_blank", "noreferrer");
  } else if (action === 2) {
    window.open(productGroup.editUrl, "_blank", "noreferrer");
  } else if (action === 3) {
    if (productGroup.reserved.length > 0) {
      modalType.value = "productIsReserved";
      return;
    }
    productToDelete.value = productGroup;
  }
};

const getRowStatus = (productHeader: GreenifiedProductHeader) => {
  if (!productHeader.missingOnGreenified) return null;
  if (!productHeader.loading && !("loadingStatus" in productHeader))
    return null;
  if (productHeader.loading) return t("label.publishing");
  return productHeader.loadingStatus
    ? t("paragraph.fetchingGreenifiedData")
    : t("paragraph.removedFromGreenified");
};

const checkFilter = (
  productItem: GreenifiedItem | null,
  productGroup: GreenifiedProductHeader
) => {
  const filterCategories = { ...greenifiedProductTableProps.filter.categories };
  if (
    greenifiedProductTableProps.tableType === "sale" &&
    productGroup.salda > 0 &&
    productGroup.quantity === 0
  )
    return false;
  if (
    !Object.keys(filterCategories).length &&
    !Object.keys(greenifiedProductTableProps.filter.intervals).length
  ) {
    return true;
  }

  if (!productGroupMatchesIntervalAmount(productGroup)) {
    return false;
  }

  for (const key in filterCategories) {
    const categories = filterCategories[key];
    const filterType = key;

    if (
      filterType === "search" &&
      !productMatchesSearchValue(categories, productGroup, productItem)
    ) {
      return false;
    }

    if (filterType !== "search") {
      if (
        productItem &&
        !productItemMatchesFilterType(filterType, categories, productItem)
      ) {
        return false;
      }

      if (
        !productGroupMatchesFilterType(filterType, categories, productGroup)
      ) {
        return false;
      }
    }
  }

  return true;
};

const productMatchesSearchValue = (
  categories: FilterOptions["categories"]["key"],
  productGroup: GreenifiedProductHeader,
  productItem: GreenifiedItem | null
) => {
  for (const category of categories) {
    const searchValue = String(category.value).toLowerCase();

    const combined = { ...productGroup, ...(productItem || {}) };

    for (const key in combined) {
      const value = combined[key as keyof typeof combined];
      if (typeof value === "string") {
        if (value.toLowerCase().includes(searchValue)) {
          return true;
        }
      }

      if (greenifiedProductTableProps.translateHeaders.includes(key)) {
        const translatedValue = t(`${key}.${value}`);
        if (
          translatedValue &&
          translatedValue.toLowerCase().includes(searchValue)
        ) {
          return true;
        }
      }
    }
  }

  return false;
};

const productItemMatchesFilterType = (
  filterType: string,
  categories: FilterOptions["categories"]["key"],
  productItem: GreenifiedItem
) => {
  if (filterType in productItem) {
    return categories.some(category => {
      if (filterType === "levkod" && category.value === "ex")
        return productItem[filterType].substring(0, 2) === "ex";
      return (
        String(productItem[filterType as keyof GreenifiedItem]) ===
        String(category.value)
      );
    });
  }

  return true;
};

const productGroupMatchesIntervalAmount = (
  productGroup: GreenifiedProductHeader
) => {
  for (const key in greenifiedProductTableProps.filter.intervals) {
    const interval = greenifiedProductTableProps.filter.intervals[key];

    if (key in productGroup) {
      if (
        "from" in interval &&
        !(
          interval.from <=
          Number(productGroup[key as keyof GreenifiedProductHeader])
        )
      ) {
        return false;
      }
      if (
        "to" in interval &&
        !(
          interval.to >=
          Number(productGroup[key as keyof GreenifiedProductHeader])
        )
      ) {
        return false;
      }
    }
  }
  return true;
};

const productGroupMatchesFilterType = (
  filterType: string,
  categories: FilterOptions["categories"]["key"],
  productGroup: GreenifiedProductHeader
) => {
  if (filterType in productGroup) {
    return categories.some(category => {
      if (filterType === "levkod" && category.value === "ex")
        return productGroup[filterType].substring(0, 2) === "ex";
      return (
        String(productGroup[filterType as keyof GreenifiedProductHeader]) ===
        category.value
      );
    });
  }

  return true;
};

const toggleSubTable = (productGroup: GreenifiedProductHeader) => {
  showTableFooter.value = false;

  if (selectedProductGroupForShowingItems.value === productGroup.id) {
    showSubTable.value = !showSubTable.value;
  } else {
    productsForUpdatingSoldStatus.value = {};
    showSubTable.value = true;
    selectedProductGroupForShowingItems.value = productGroup.id;
    if (productGroup.salda === 0) return;

    productGroup.items.forEach(productItem => {
      if (productItem.sold === "1") {
        productsForUpdatingSoldStatus.value[productItem.id] = "1";
      }
    });
  }
};

watch(
  () => greenifiedProductTableProps.sortingSettings,
  newSortingSettings => {
    mainTableSortingOptions.value = newSortingSettings;
    subTableSortingOptions.value = newSortingSettings;
  },
  { deep: true }
);
</script>

<style scoped>
.subtable {
  padding-top: 16px;
  padding-bottom: 28px;
}

.left-frame {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 28px;
}

.right-frame {
  position: absolute;
  right: 0;
  top: 0;
  height: 100%;
  width: 28px;
  background-color: black;
}
</style>
