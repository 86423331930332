import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, unref as _unref, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "container-fluid ps-0 pe-0" }
const _hoisted_2 = { class: "col-8 ps-0" }
const _hoisted_3 = {
  key: 0,
  class: "col-4 d-flex justify-content-end"
}
const _hoisted_4 = { class: "col-8" }
const _hoisted_5 = { class: "col-4" }

import RotateArrow from "../common/RotateArrow.vue";
import AccordionContainer from "../common/AccordionContainer.vue";
import { SelectableProject } from "../../library/types/project/project";
import { ref, watch } from "vue";
import { useI18n } from "vue-i18n";
import { useIndexStore } from "@/store";
import GreenifiedButton from "../common/GreenifiedButton.vue";

type ProjectListProps = {
  projects: SelectableProject[];
  title: string;
  isCollapsed?: boolean;
  projectType: number;
  accordionBg?: string;
  isExpandable?: boolean;
};


export default /*@__PURE__*/_defineComponent({
  __name: 'ProjectList',
  props: {
    projects: { default: () => [] },
    title: { default: "" },
    isCollapsed: { type: Boolean, default: false },
    projectType: { default: 1 },
    accordionBg: { default: "white" },
    isExpandable: { type: Boolean, default: true }
  },
  emits: ["projectIsSelected", "expandedField", "isLoading"],
  setup(__props: any, { emit: __emit }) {

const projectListProps = __props;

const { t, messages, locale } = useI18n();
const currentProject = ref("");
const isLoading = ref(false);
const indexStore = useIndexStore();
const toggleAccordion = ref(!projectListProps.isCollapsed);
const emit = __emit;

const setProject = async (projectNumber: string) => {
  currentProject.value = projectNumber;
  isLoading.value = true;
  emit("isLoading", isLoading.value);
  const result = await indexStore.changeProject(
    projectNumber,
    messages.value[locale.value]
  );
  isLoading.value = false;

  if (result !== "success") return;

  emit("isLoading", isLoading.value);

  emit("projectIsSelected");
};

watch(
  () => projectListProps.isCollapsed,
  collapsed => {
    if (collapsed) {
      toggleAccordion.value = false;
    }
  }
);

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: "row ms-0 me-0 d-flex table-header mt-0 align-items-center",
      onClick: _cache[0] || (_cache[0] = 
        () => {
          if (!_ctx.isExpandable) return;

          toggleAccordion.value = !toggleAccordion.value;
          if (toggleAccordion.value) {
            emit('expandedField', _ctx.projectType);
          }
        }
      )
    }, [
      _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.title), 1),
      (_ctx.isExpandable)
        ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
            _createVNode(RotateArrow, {
              size: "small",
              rotate: toggleAccordion.value
            }, null, 8, ["rotate"])
          ]))
        : _createCommentVNode("", true)
    ]),
    _createVNode(AccordionContainer, {
      toggleAccordion: toggleAccordion.value,
      backgroundColor: _ctx.accordionBg
    }, {
      content: _withCtx(() => [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.projects, (project) => {
          return (_openBlock(), _createElementBlock("div", {
            class: "row table-item align-items-center",
            key: project.projektnr
          }, [
            _createElementVNode("div", _hoisted_4, [
              _createElementVNode("p", null, _toDisplayString(project.projektbenamn), 1)
            ]),
            _createElementVNode("div", _hoisted_5, [
              _createVNode(GreenifiedButton, {
                disabled: 
                _unref(indexStore).currentProjectNumber === project.projektnr ||
                (isLoading.value && project.projektnr === currentProject.value)
              ,
                btnStyle: 'green',
                onClick: ($event: any) => (setProject(project.projektnr)),
                text: 
                isLoading.value && project.projektnr === currentProject.value
                  ? _unref(t)('button.loading')
                  : _unref(indexStore).currentProjectNumber !== project.projektnr
                  ? _unref(t)('button.select')
                  : _unref(t)('button.selected')
              
              }, null, 8, ["disabled", "onClick", "text"])
            ])
          ]))
        }), 128))
      ]),
      _: 1
    }, 8, ["toggleAccordion", "backgroundColor"])
  ]))
}
}

})