import { User } from "../api/project";
import {
  GreenifiedProductHeader,
  ProductGroupSale
} from "../types/product/productGroup";
import { ProductHeaderSale } from "../types/product/productHeader";
import { GreenifiedItem, ProductItemSale } from "../types/product/productItem";
import { RoomObject } from "../types/room/rooms";
import { SortingSettings } from "../types/sorting/sorting";

export const sortedGreenifiedHeaderRows = (
  greenifiedProductHeaderColumns: GreenifiedProductHeader[],
  sortingOptions: SortingSettings
): GreenifiedProductHeader[] => {
  const { col, asc } = sortingOptions;
  if (!String(col).length) return greenifiedProductHeaderColumns;

  return greenifiedProductHeaderColumns.sort((a, b) => {
    const valueA = a[col as keyof GreenifiedProductHeader];
    const valueB = b[col as keyof GreenifiedProductHeader];

    if (valueA === null) return asc ? -1 : 1;
    if (valueB === null) return asc ? 1 : -1;

    if (typeof valueA === "string" && typeof valueB === "string") {
      return asc ? valueA.localeCompare(valueB) : valueB.localeCompare(valueA);
    }

    if (typeof valueA === "number" && typeof valueB === "number") {
      return asc ? valueA - valueB : valueB - valueA;
    }

    return asc
      ? String(valueA).localeCompare(String(valueB))
      : String(valueB).localeCompare(String(valueA));
  });
};

export const sortedRoomRows = (
  roomColumns: RoomObject[],
  sortingOptions: SortingSettings
): RoomObject[] => {
  const { col, asc } = sortingOptions;
  if (!String(col).length) return roomColumns;

  return roomColumns.sort((a, b) => {
    const valueA = a[col as keyof RoomObject];
    const valueB = b[col as keyof RoomObject];

    return asc
      ? String(valueA).localeCompare(String(valueB))
      : String(valueB).localeCompare(String(valueA));
  });
};

export const sortedUserRows = (
  userColumns: User[],
  sortingOptions: SortingSettings
): User[] => {
  const { col, asc } = sortingOptions;
  if (!String(col).length) return userColumns;

  return userColumns.sort((a, b) => {
    const valueA = a[col as keyof User];
    const valueB = b[col as keyof User];

    return asc
      ? String(valueA).localeCompare(String(valueB))
      : String(valueB).localeCompare(String(valueA));
  });
};

export const sortedProductHeaderRows = (
  mainTableColumns: ProductHeaderSale,
  sortingOptions: SortingSettings
): ProductHeaderSale => {
  const { col, asc } = sortingOptions;
  if (!String(col).length) return mainTableColumns;

  const currentTableColumn = convertTableColumns(String(col));

  const sortedEntries = Object.entries(mainTableColumns).sort(
    // eslint-disable-next-line @typescript-eslint/no-unused-vars, no-unused-vars
    ([keyA, headerA], [keyB, headerB]) => {
      let valueA;
      let valueB;
      if (!("group" in headerA) && !("group" in headerB)) {
        valueA = headerA[currentTableColumn as keyof ProductGroupSale];
        valueB = headerB[currentTableColumn as keyof ProductGroupSale];
      } else {
        valueA = headerA.group[currentTableColumn as keyof ProductGroupSale];
        valueB = headerB.group[currentTableColumn as keyof ProductGroupSale];
      }

      if (!isNaN(Number(valueA)) && !isNaN(Number(valueB))) {
        return asc
          ? Number(valueA) - Number(valueB)
          : Number(valueB) - Number(valueA);
      }

      if (typeof valueA === "string" && typeof valueB === "string") {
        return asc
          ? valueA.localeCompare(valueB)
          : valueB.localeCompare(valueA);
      }

      return 0;
    }
  );

  return Object.fromEntries(sortedEntries);
};

const convertTableColumns = (column: string) => {
  if (column === "brand") {
    return "supplierCode";
  } else if (column === "category") {
    return "categoryId";
  }
  return column;
};

export const sortedProductItemRows = (
  subTableColumns: ProductItemSale[],
  sortingOptions: SortingSettings
): ProductItemSale[] => {
  const { col, asc } = sortingOptions;
  if (!String(col).length) return subTableColumns;
  const currentSubTableColumns = [...subTableColumns];

  return currentSubTableColumns.sort((a, b) => {
    const valueA = a[col as keyof ProductItemSale];
    const valueB = b[col as keyof ProductItemSale];

    if (valueA == null) return !asc ? 1 : -1;
    if (valueB == null) return !asc ? -1 : 1;

    if (!isNaN(Number(valueA)) && !isNaN(Number(valueB))) {
      return !asc
        ? Number(valueA) - Number(valueB)
        : Number(valueB) - Number(valueA);
    }

    if (typeof valueA === "string" && typeof valueB === "string") {
      return !asc ? valueA.localeCompare(valueB) : valueB.localeCompare(valueA);
    }

    return !asc
      ? String(valueA).localeCompare(String(valueB))
      : String(valueB).localeCompare(String(valueA));
  });
};

export const sortedGreenifiedItemRows = (
  subTableColumns: GreenifiedItem[],
  sortingOptions: SortingSettings
): GreenifiedItem[] => {
  const { col, asc } = sortingOptions;
  if (!String(col).length) return subTableColumns;
  const currentSubTableColumns = [...subTableColumns];

  return currentSubTableColumns.sort((a, b) => {
    const valueA = a[col as keyof GreenifiedItem];
    const valueB = b[col as keyof GreenifiedItem];

    if (valueA == null) return !asc ? 1 : -1;
    if (valueB == null) return !asc ? -1 : 1;

    if (!isNaN(Number(valueA)) && !isNaN(Number(valueB))) {
      return !asc
        ? Number(valueA) - Number(valueB)
        : Number(valueB) - Number(valueA);
    }

    if (typeof valueA === "string" && typeof valueB === "string") {
      return !asc ? valueA.localeCompare(valueB) : valueB.localeCompare(valueA);
    }

    return !asc
      ? String(valueA).localeCompare(String(valueB))
      : String(valueB).localeCompare(String(valueA));
  });
};
