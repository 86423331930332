import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, unref as _unref, normalizeClass as _normalizeClass, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "cursor-pointer" }
const _hoisted_2 = ["src"]
const _hoisted_3 = ["onClick"]

import { ref, computed } from "vue";
import dropArrow from "@/assets/img/drop-arrow-white.svg";
import { useI18n } from "vue-i18n";
import { Domains, I18nMessage } from "@/library/types/locales/i18nMessage";
import { I18nLocale } from "@/library/types/locales/locales";
import { useIndexStore } from "@/store";
import { useRouter } from "vue-router";
import { useAuthStore } from "@/store/auth";
import { getLocaleFromLocationHost } from "@/library/helpers/getLocaleFromLocationHost";


export default /*@__PURE__*/_defineComponent({
  __name: 'SelectLanguage',
  setup(__props) {

const { messages, locale } = useI18n();
const store = useIndexStore();
const authStore = useAuthStore();
const currLanguage = ref(
  store.currentProjectLanguage ??
    getLocaleFromLocationHost(window.location.host)
);
const openMenu = ref(false);

const { currentRoute } = useRouter();

const selectLanguage = async (selectedLanguage: I18nLocale) => {
  if (selectedLanguage === store.currentProjectLanguage) {
    openMenu.value = false;
    return;
  }
  openMenu.value = false;
  currLanguage.value = selectedLanguage;
  store.changeProjectLanguage(selectedLanguage, locale);

  if (!authStore.isAuthenticated) return;

  if (currentRoute.value.params.token) {
    await store.getGuestLocaleTranslations(
      store.currentProject,
      currentRoute.value.params.token as string,
      messages.value[selectedLanguage]
    );
    return;
  }
  await store.getLocaleTranslations(
    store.currentProject,
    messages.value[selectedLanguage]
  );
  return;
};

const languageOptions = computed(() => {
  const currentHost = window.location.host;

  if (currentHost === "inventory-staging.greenified.se") {
    return (messages.value["sv"] as I18nMessage).list.domains?.[
      "inventory-staging.greenified.se"
    ]?.locales;
  }
  const currentLocale = locale.value.toLocaleLowerCase();
  return (messages.value[currentLocale] as I18nMessage)?.list?.domains?.[
    window.location.host as keyof I18nMessage["list"]["domains"]
  ]?.locales;
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    class: "custom-select",
    onBlur: _cache[1] || (_cache[1] = ($event: any) => (openMenu.value = false))
  }, [
    _createElementVNode("div", {
      class: _normalizeClass(["selected nav-link", { open: openMenu.value }]),
      onClick: _cache[0] || (_cache[0] = ($event: any) => (openMenu.value = !openMenu.value))
    }, [
      _createElementVNode("p", _hoisted_1, _toDisplayString(languageOptions.value
            ? languageOptions.value[currLanguage.value.toLocaleLowerCase() as keyof Domains]
            : ""), 1),
      _createElementVNode("img", {
        src: _unref(dropArrow),
        alt: "dropdown-arrow",
        class: "dropdown-arrow"
      }, null, 8, _hoisted_2)
    ], 2),
    _createElementVNode("div", {
      class: _normalizeClass(["items", { selectHide: !openMenu.value }])
    }, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(languageOptions.value, (option, optionKey) => {
        return (_openBlock(), _createElementBlock("div", {
          key: optionKey,
          onClick: ($event: any) => (selectLanguage(optionKey.toLocaleLowerCase() as I18nLocale))
        }, _toDisplayString(option), 9, _hoisted_3))
      }), 128))
    ], 2)
  ], 32))
}
}

})