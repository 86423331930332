import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "d-flex form-label align-items-center"
}
const _hoisted_2 = { class: "me-2" }
const _hoisted_3 = ["for"]
const _hoisted_4 = ["innerHTML"]
const _hoisted_5 = ["innerHTML"]

import {
  CheckboxFieldType,
  TrueFalseFieldType
} from "@/library/types/structure/subfieldTypes";
import { useI18n } from "vue-i18n";
import { ref } from "vue";
import { FormFields } from "@/library/types/structure/formStructure";
import GreenifiedCheckbox from "@/components/common/GreenifiedCheckbox.vue";

export type InputValue = {
  key: string;
  name: string;
  value: string | number | null;
};

type CheckboxFieldProps = {
  presetCheckboxValues: FormFields["key"];
  checkboxField: CheckboxFieldType | TrueFalseFieldType | null;
  checked?: boolean;
  trueValue?: InputValue["value"];
  falseValue?: InputValue["value"];
  option?: string;
};


export default /*@__PURE__*/_defineComponent({
  __name: 'CheckboxField',
  props: {
    presetCheckboxValues: { default: () => ({}) },
    checkboxField: { default: null },
    checked: { type: Boolean, default: false },
    trueValue: { default: null },
    falseValue: { default: null },
    option: { default: "" }
  },
  emits: ["input", "update:presetCheckboxValues", "delete"],
  setup(__props: any, { emit: __emit }) {

const checkboxFieldProps = __props;

const { locale } = useI18n();
const emit = __emit;
const localCheckboxValues = ref(
  checkboxFieldProps.presetCheckboxValues as number
);

const inputCheckbox = (
  event: Event,
  field: CheckboxFieldType | TrueFalseFieldType,
  trueValue: InputValue["value"],
  falseValue: InputValue["value"]
) => {
  const eventTarget = event.target as HTMLInputElement;
  if (eventTarget.value === "on" || eventTarget.checked) {
    localCheckboxValues.value = trueValue as number;
    emit("update:presetCheckboxValues", localCheckboxValues.value);
    emit("input", {
      key: field.key,
      name: field.name,
      value: trueValue
    });
  } else {
    emit("delete");
    emit("input", {
      key: field.key,
      name: field.name,
      value: falseValue
    });
  }
};

const localeUrl = () => {
  let url = "";
  switch (locale.value.toLocaleLowerCase()) {
    case "sv":
      url = "https://files.inputinterior.se/index.php/s/yMt5RDaR83we8SA";
      break;
    case "da":
      url = "https://files.inputinterior.se/index.php/s/XDzRNwHXWyqaRxG";
      break;
    case "fi":
      url = "https://files.inputinterior.se/index.php/s/ZaM9BRGMZK7edLs";
      break;
    case "no":
      url = "https://files.inputinterior.se/index.php/s/Z5EZ8cMA9bNpMEc";
      break;
    default:
      url = "https://files.inputinterior.se/index.php/s/yMt5RDaR83we8SA";
      break;
  }
  return url;
};

return (_ctx: any,_cache: any) => {
  return (_ctx.checkboxField && _ctx.presetCheckboxValues !== null)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createVNode(GreenifiedCheckbox, {
            size: "large",
            required: _ctx.checkboxField.required === 0 ? false : true,
            id: _ctx.checkboxField.key,
            name: _ctx.checkboxField.key,
            modelValue: localCheckboxValues.value,
            isChecked: _ctx.checked,
            onInput: _cache[0] || (_cache[0] = (event) => {
          if (_ctx.checkboxField) inputCheckbox(event, _ctx.checkboxField, _ctx.trueValue, _ctx.falseValue)
          }),
            trueValue: _ctx.trueValue,
            falseValue: _ctx.falseValue
          }, null, 8, ["required", "id", "name", "modelValue", "isChecked", "trueValue", "falseValue"])
        ]),
        (_ctx.checkboxField.type === 'checkbox')
          ? (_openBlock(), _createElementBlock("label", {
              for: _ctx.option,
              key: _ctx.option
            }, [
              _createElementVNode("div", {
                innerHTML: 
          _ctx.checkboxField.name !== 'lest_og_godkjent'
            ? _ctx.option
            : _ctx.option.replace(/<a(.*?)>/, `<a href='${localeUrl}'>`)
        
              }, null, 8, _hoisted_4)
            ], 8, _hoisted_3))
          : (_openBlock(), _createElementBlock("label", {
              key: 1,
              innerHTML: _ctx.checkboxField.message
            }, null, 8, _hoisted_5))
      ]))
    : _createCommentVNode("", true)
}
}

})