import axios, { AxiosResponse } from "axios";
import { defineStore } from "pinia";
import { computed, Ref, ref } from "vue";
import { useRouter } from "vue-router";
import { useIndexStore } from ".";

export type AuthState = {
  authenticated: boolean;
  user: {
    email: string;
    avatarIcon: string;
    name: string;
  };
};

export const useAuthStore = defineStore(
  "auth",
  () => {
    //State
    const router = useRouter();
    const authenticated: Ref<AuthState["authenticated"]> = ref(false);
    const user: Ref<AuthState["user"]> = ref({
      email: "",
      avatarIcon: "",
      name: ""
    });

    //Getters
    const isAuthenticated = computed(() => authenticated.value);
    const currentUser = computed(() => user.value);

    //Actions
    async function login() {
      const token = localStorage.getItem("token");
      if (token) {
        axios.defaults.headers.common["Authorization"] = "Bearer " + token;
      }

      let response: AxiosResponse<{
        email: string;
        oracle_user: {
          avatar_icon: string;
          namn: string;
        };
      }>;

      try {
        response = await axios.get("/api/user", {
          headers: { Authorization: `Bearer ${token}` }
        });
      } catch (error) {
        return logout();
      }
      const userData: AuthState["user"] = {
        email: response.data.email,
        avatarIcon: response.data.oracle_user.avatar_icon,
        name: response.data.oracle_user.namn
      };

      user.value = userData;
      authenticated.value = true;
    }

    async function logout() {
      const indexStore = useIndexStore();

      indexStore.setUpdatingProjectStatus(false);

      router.push({ name: "login" });

      await axios.post("/api/logout");

      authenticated.value = false;
      user.value = {
        email: "",
        avatarIcon: "",
        name: ""
      };
    }

    return {
      authenticated,
      user,
      isAuthenticated,
      currentUser,
      login,
      logout
    };
  },
  { persist: true }
);
