<template>
  <GrLayout :openSideMenu="showMenu">
    <template v-slot:top-navbar-left
      ><div class="row align-items-center h-100">
        <div
          class="col d-flex align-items-center"
          @click="showMenu = !showMenu"
        >
          <img
            :class="showMenu ? 'left-arrow' : 'right-arrow'"
            src="@/assets/img/drop-arrow-white.svg"
            alt="menu arrow"
            class="me-2"
          />
          <p class="nav-link">
            {{ showMenu ? t("menu.hideMenu") : t("menu.showMenu") }}
          </p>
        </div>
      </div></template
    >
    <template v-slot:top-navbar-right>
      <div class="row nav-link">
        <div class="col d-flex justify-content-end align-items-center">
          <div
            class="me-5 d-flex align-items-center cursor-pointer"
            @click="projectsOpen = !projectsOpen"
          >
            <p class="cursor-pointer">
              {{ `${t("title.project")}: ${projectName}` }}
            </p>
          </div>

          <SelectLanguage />
          <p @click="logout" class="ms-5 cursor-pointer">
            {{ t("menu.signOut") }}
          </p>
        </div>
      </div>
    </template>

    <template v-slot:side-bar>
      <SideBarContent :showContent="showMenu" />
    </template>
    <template v-slot:container>
      <Transition name="fade">
        <div v-if="!showMenu" @click="showMenu = !showMenu" class="g-icon">
          g
        </div>
      </Transition>
      <slot></slot>
    </template>
    <template v-slot:footer>
      <slot name="footer"></slot>
    </template>
  </GrLayout>

  <SidebarProjects v-model:openSidebar="projectsOpen" />
</template>

<script setup lang="ts">
import GrLayout from "@/layouts/GrLayout.vue";
import SidebarProjects from "@/components/project/SidebarProjects.vue";
import { computed, onMounted, ref } from "vue";
import { useIndexStore } from "@/store";
import { useAuthStore } from "@/store/auth";
import { useI18n } from "vue-i18n";
import SideBarContent from "./sidebar/SideBarContent.vue";
import { CustomSetLocaleMessage } from "@/library/types/locales/i18nUtilities";
import SelectLanguage from "@/components/common/SelectLanguage.vue";

const { t, locale, messages, setLocaleMessage } = useI18n();
const store = useIndexStore();
const authStore = useAuthStore();
const showMenu = ref(true);
const projectsOpen = ref(false);

const projectName = computed(() => {
  return store.currentProject.namn;
});

const logout = () => {
  authStore.logout();
};

onMounted(() => {
  if (!store.currentProjectNumber) return;

  store.getProjectDetails(
    store.currentProjectNumber,
    setLocaleMessage as CustomSetLocaleMessage,
    messages.value[locale.value]
  );
});
</script>

<style scoped lang="scss">
.right-arrow {
  transform: rotate(-180deg);
}
.right-arrow,
.left-arrow {
  transition: 0.2s;
  width: 5px;
  height: 9px;
  flex-shrink: 0;
}
.left-arrow {
  transform: rotate(0deg);
}

.g-icon {
  background-color: #f5f2eb;
  width: 36px;
  height: 36px;
  flex-shrink: 0;
  border-radius: 50%;
  display: flex;
  align-items: center;
  padding-bottom: 6px;
  justify-content: center;
  color: #000;
  font-family: "Avenir Next W01", sans-serif;
  font-size: 19px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.76px;
  cursor: pointer;
  position: absolute;
  left: 20px;
  top: 6px;
}
</style>
