<template>
  <GreenifiedModal
    @close="emit('close')"
    type="medium"
    :title="t('title.removeAddress')"
    :isLoading="isLoading"
  >
    <template #container>
      <div class="d-flex flex-column justify-content-between h-100">
        <div>
          <p class="form-label-paragraph mb-2">
            {{ t("paragraph.removeAddressProductInformation") }}
          </p>
          <div class="container">
            <div
              class="row table-header d-flex align-items-center cursor-default"
              :class="addressRooms.length > 3 ? 'pe-2' : ''"
            >
              <div class="col-4 ps-0">{{ t("tableTitle.roomType") }}</div>
              <div class="col-4">{{ t("tableTitle.floor") }}</div>
              <div class="col-4">{{ t("tableTitle.room") }}</div>
            </div>
          </div>
          <div
            class="container"
            style="max-height: 12rem; overflow-y: auto; overflow-x: hidden"
          >
            <div
              class="row table-item d-flex align-items-center"
              v-for="room in addressRooms"
              :key="room.hus"
            >
              <div class="col-4 ps-0">
                {{ t("label.fromRoom") }}
              </div>
              <div class="col-4">{{ room.van }}</div>
              <div class="col-4">
                {{ room.rumsnamn }}
              </div>
            </div>
          </div>
        </div>
        <div class="d-flex align-items-center justify-content-between">
          <vSelect
            :clearable="false"
            :options="filteredAddresses"
            class="room-select"
            :open-direction="'top'"
            label="building"
            :placeholder="t('placeholders.selectAddressForRooms')"
            :reduce="(address: AddressInformation) => address.id"
            @option:selecting="showMoveVerification = true"
            v-model="newAddress"
            append-to-body
            :calculate-position="withPopper"
          >
            <template
              #open-indicator="{
                attributes
              }: {
                attributes: VueSelectSlotScope
              }"
            >
              <span v-bind="attributes">
                <img src="@/assets/img/drop-arrow.svg" />
              </span>
            </template>
            <template
              #option="{
                building,
                address
              }: {
                building: string,
                address: AddressInformation['address']
              }"
            >
              <div>
                {{ building }}
                <p style="font-weight: 400">
                  {{ address.adress }}<br />{{ address.postnummer }},{{
                    address.ort
                  }},{{ address.land }}
                </p>
              </div>
            </template>
          </vSelect>
        </div>
      </div>
    </template>
  </GreenifiedModal>

  <GreenifiedModal
    type="verification"
    :closable="false"
    v-if="showMoveVerification"
    :title="t('label.moveRoomsAndRemove')"
    :text="
      t('msg.verifyRoomMove', [
        getAddressName(addressToDelete)?.address.adress,
        getAddressName(newAddress)?.address.adress,
        getAddressName(addressToDelete)?.address.adress
      ])
    "
    @close="
      showMoveVerification = false;
      newAddress = '';
    "
    @confirm="
      moveRoomsToNewAddress();
      showMoveVerification = false;
    "
  />
</template>

<script lang="ts" setup>
import {
  defineEmits,
  ref,
  withDefaults,
  defineProps,
  onMounted,
  Ref
} from "vue";
import { withPopper } from "../../modules/popperModule";

import GreenifiedModal from "./GreenifiedModal.vue";
import { useI18n } from "vue-i18n";
import { ProjectSettingsAdress } from "@/services/api/addressApi";
import { AddressInformation, RoomObject } from "@/library/types/room/rooms";
// eslint-disable-next-line @typescript-eslint/no-unused-vars, no-unused-vars
import vSelect, { VueSelectSlotScope } from "vue-select";
import { moveRoomsFromAddress } from "@/library/api/rooms";

type RemoveAddressModalProps = {
  addressRooms: RoomObject[];
  addresses: ProjectSettingsAdress[];
  addressToDelete: AddressInformation["id"];
};

const removeAddressModalProps = withDefaults(
  defineProps<RemoveAddressModalProps>(),
  {
    addressRooms: () => [],
    addresses: () => [],
    addressToDelete: ""
  }
);

const emit = defineEmits(["close", "removeAddress"]);
const { t } = useI18n();
const isLoading = ref(false);
const showMoveVerification = ref(false);
const newAddress = ref("");
const filteredAddresses: Ref<ProjectSettingsAdress[]> = ref([]);

const getAddressName = (addressId: string) => {
  return removeAddressModalProps.addresses.find(
    address => address.id === addressId
  );
};
const moveRoomsToNewAddress = async () => {
  isLoading.value = true;
  const result = await moveRoomsFromAddress(
    removeAddressModalProps.addressToDelete,
    newAddress.value
  );

  showMoveVerification.value = false;
  isLoading.value = false;

  if (!result.success) return;

  emit("removeAddress", removeAddressModalProps.addressToDelete);
};

onMounted(() => {
  filteredAddresses.value = removeAddressModalProps.addresses.filter(
    address => address.id !== removeAddressModalProps.addressToDelete
  );
});
</script>
