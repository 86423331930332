import { useIndexStore } from "@/store";
import greenifiedProductsApi, {
  GreenifiedProductHeaders
} from "@/services/api/greenifiedProductsApi";
import { AxiosResponse } from "axios";
import {
  ProductInformation,
  GreenifiedProductHeader
} from "../types/product/productGroup";
import { ProductStatuses } from "../types/product/productHeader";
import { ProjectDetails } from "../types/project/project";
import { FormFields, GroupFields } from "../types/structure/formStructure";
import {
  CustomError,
  handleApiRequest,
  Result
} from "../helpers/handleApiRequests";

export const getSaleProductGroups = async (): Promise<
  Result<GreenifiedProductHeaders, CustomError>
> => {
  const indexStore = useIndexStore();

  return handleApiRequest(
    greenifiedProductsApi.getSaleProductGroups(indexStore.currentProjectNumber),
    "greenifiedProducts - getSaleProductGroups"
  );
};

export const getGreenifiedData = async (
  product: GreenifiedProductHeader
): Promise<Result<GreenifiedProductHeader, CustomError>> => {
  const indexStore = useIndexStore();

  return handleApiRequest(
    greenifiedProductsApi.getGreenifiedData(
      indexStore.currentProjectNumber,
      product
    ),
    "greenifiedProducts - getGreenifiedData"
  );
};

export const updateProductsWithSameGreenifiedHash = async (
  structure: ProductInformation["green_struc"],
  supplierCode: ProductInformation["supplier_code"],
  category: ProductInformation["category"],
  status: ProductStatuses,
  productIds: string[]
): Promise<Result<AxiosResponse, CustomError>> => {
  const indexStore = useIndexStore();

  return handleApiRequest(
    greenifiedProductsApi.updateProductsWithSameGreenifiedHash(
      indexStore.currentProjectNumber,
      structure,
      supplierCode,
      category,
      status,
      productIds
    ),
    "greenifiedProducts - updateProductsWithSameGreenifiedHash"
  );
};

export const completeGreenifiedStructure = async (
  productIds: string[],
  status: string,
  saleInformation: FormFields
): Promise<Result<AxiosResponse, CustomError>> => {
  const indexStore = useIndexStore();

  return handleApiRequest(
    greenifiedProductsApi.completeGreenifiedStructure(
      indexStore.currentProjectNumber,
      productIds,
      status,
      saleInformation
    ),
    "greenifiedProducts - completeGreenifiedStructure"
  );
};

export const removeProductGreenified = async (
  productId: string,
  status: ProductStatuses
): Promise<Result<AxiosResponse, CustomError>> => {
  const indexStore = useIndexStore();

  return handleApiRequest(
    greenifiedProductsApi.removeProductGreenified(
      indexStore.currentProjectNumber,
      productId,
      status
    ),
    "greenifiedProducts - removeProductGreenified"
  );
};

export const getGreenifiedStructure = async (
  projectNumber: ProjectDetails["projekt"]
): Promise<Result<GroupFields[], CustomError>> => {
  return handleApiRequest(
    greenifiedProductsApi.getGreenifiedStructure(projectNumber),
    "greenifiedProducts - getGreenifiedStructure"
  );
};
