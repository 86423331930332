import { defineComponent as _defineComponent } from 'vue'
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "position-relative" }
const _hoisted_2 = {
  key: 3,
  class: "row row-gap-2"
}

import {
  ref,
  watch
} from "vue";
import { AddressInformation } from "@/library/types/room/rooms";
import { Subfields } from "@/library/types/structure/subfieldTypes";
import {
  ConditionalLogic,
  FormFields,
  GroupFields
} from "@/library/types/structure/formStructure";
import AddressField from "./fields/AddressField.vue";
import PriceField from "./fields/PriceField.vue";
// eslint-disable-next-line @typescript-eslint/no-unused-vars, no-unused-vars
import CheckboxField, { InputValue } from "./fields/CheckboxField.vue";
import MultipleCheckboxField from "./fields/MultipleCheckboxField.vue";
import RadioField from "./fields/RadioField.vue";
import MultipleRadioField from "./fields/MultipleRadioField.vue";
// eslint-disable-next-line @typescript-eslint/no-unused-vars, no-unused-vars
import { ProjectCategory } from "@/library/types/project/project";
import SupplierField from "./fields/SupplierField.vue";
import TaxonomyField from "./fields/TaxonomyField.vue";
// eslint-disable-next-line @typescript-eslint/no-unused-vars, no-unused-vars
import SelectField, { SelectOptions } from "./fields/SelectField.vue";
import InputField from "../common/InputField.vue";
import FormImages from "./fields/ImageField.vue";
import FieldLabel from "./fields/FieldLabel.vue";
import MessageField from "./fields/MessageField.vue";
import {
  FormImagesObject,
  ProductImages
} from "@/library/types/product/productImage";

type GreenifiedFormProps = {
  formValues: FormFields;
  formFields: GroupFields[] | Subfields[];
  hideTitle?: boolean;
  addressSuggestion?: AddressInformation[];
  priceSuggestion?: { estimatedvalue: string }[];
  excludedFields?: string[];
  includedFields?: string[];
  supplierCode?: string;
  formImages?: FormImagesObject;
  formIsSubmitted?: boolean;
  productImages?: ProductImages | null;
};


export default /*@__PURE__*/_defineComponent({
  __name: 'GreenifiedForm',
  props: {
    formValues: { default: () => ({}) },
    formFields: { default: () => [] },
    hideTitle: { type: Boolean, default: false },
    addressSuggestion: { default: () => [] },
    priceSuggestion: { default: () => [] },
    excludedFields: { default: () => [] },
    includedFields: { default: () => [] },
    supplierCode: { default: "" },
    formImages: { default: () => ({
    bild_1: {
      fieldKey: "",
      imageHash: ""
    },
    bild_2: {
      fieldKey: "",
      imageHash: ""
    }
  }) },
    formIsSubmitted: { type: Boolean, default: false },
    productImages: { default: null }
  },
  emits: [
  "priceChange",
  "input",
  "showAddSupplier",
  "update:formValues",
  "update:formIsSubmitted",
  "update:productImages",
  "update:supplierCode",
  "update:productCategory"
],
  setup(__props: any, { expose: __expose, emit: __emit }) {

const greenifiedFormProps = __props;

const emit = __emit;
const numericFieldTypes = [
  "langd",
  "bredd",
  "djup",
  "hojd",
  "diameter",
  "sitthojd",
  "pris"
];
const addressFieldUpdated = ref(false);
const toggleDropdown = ref(false);
const currentFormImages = ref(greenifiedFormProps.formImages);
const currentFormValues = ref(greenifiedFormProps.formValues);
const currentSupplierCode = ref(greenifiedFormProps.supplierCode);
const formRefs = ref<(HTMLDivElement | null)[]>([]);
const fieldHasLabel = (
  formFieldType: GroupFields["type"] | Subfields["type"]
) => {
  if (greenifiedFormProps.hideTitle) return false;

  if (
    formFieldType !== "group" &&
    formFieldType !== "acfe_hidden" &&
    formFieldType !== "image" &&
    formFieldType !== "message"
  )
    return true;

  return false;
};

const filteredFormFields = (formFields: GroupFields[] | Subfields[]) => {
  const validFields: (GroupFields | Subfields)[] = [];
  const invalidFields: (GroupFields | Subfields)[] = [];

  formFields.forEach(formField => {
    if (
      checkConditions(formField) &&
      (greenifiedFormProps.includedFields.length
        ? greenifiedFormProps.includedFields.includes(formField.name)
        : !greenifiedFormProps.excludedFields.includes(formField.name))
    ) {
      validFields.push(formField);
    } else {
      invalidFields.push(formField);
    }
  });
  return { validFields: validFields, invalidFields: invalidFields };
};

const updateFormImages = (
  formValues: FormFields,
  formImages: FormImagesObject
) => {
  const currFormValues = { ...formValues };
  const currFormImages = { ...formImages };
  for (const imageKey in currFormImages) {
    const formImage = currFormImages[imageKey as keyof FormImagesObject];
    if (formImage) {
      if (formImage.fieldKey in currFormValues && !formImage.imageHash) {
        delete currFormValues[formImage.fieldKey];
      }

      if (formImage.imageHash) {
        currFormValues[formImage.fieldKey] = formImage.imageHash;
      }
    }
  }

  return currFormValues;
};

const getContainerOrRowStyling = (
  index: number,
  formField: GroupFields | Subfields
) => {
  if (formField.type === "group") return "container-fluid ps-0 pe-0 m-0";

  const elementHeight = formRefs.value[index]?.clientHeight;
  if (!elementHeight) return "row";

  if (elementHeight > 35) return "row mb-3";

  return "row mb-3 align-items-center";
};

const operatorMeetsConditions = (conditions: ConditionalLogic) => {
  for (let i = 0; i < conditions.length; i++) {
    const condition = conditions[i];
    if (
      condition.operator === "==empty" &&
      currentFormValues.value[condition.field as keyof Subfields] != null
    ) {
      return false;
    } else if (
      condition.operator === "!=empty" &&
      currentFormValues.value[condition.field as keyof Subfields] == null
    ) {
      return false;
    } else if (
      !condition.operator.includes("empty") &&
      eval(
        '"' +
          String(currentFormValues.value[condition.field as keyof Subfields]) +
          '"' +
          condition.operator +
          ('"' + String(condition["value"]) + '"')
      ) === false
    ) {
      return false;
    }
  }

  return true;
};

const checkConditions = (structureField: GroupFields | Subfields) => {
  const structureConditions = structureField.conditional_logic;
  if (structureConditions === 0) return true;

  if (structureConditions.some(condition => operatorMeetsConditions(condition)))
    return true;

  delete currentFormValues.value[structureField.key as keyof Subfields];
  return false;
};

const inputText = (
  fieldValue: FormFields["key"],
  field: GroupFields | Subfields
) => {
  emit("input", {
    key: field.key,
    name: field.name,
    value: fieldValue
  });
  emit("update:formValues", currentFormValues.value);
};

const changeSelect = (field: Subfields, fieldValue: string) => {
  currentFormValues.value[field.key as keyof Subfields] = fieldValue;

  emit("input", {
    key: field.key,
    name: field.name,
    value: fieldValue
  });

  emit("update:formValues", currentFormValues.value);
  if (field.name === "kategori") {
    emit("update:productCategory", fieldValue);
  }
};

const fieldIsValid = (field: Subfields | GroupFields) => {
  if (field.required === 0) return true;

  const currentFieldValue = currentFormValues.value[field.key];
  if (field.required === 1 && !currentFieldValue) return false;

  if (typeof currentFieldValue === "number" && currentFieldValue > 0) {
    return true;
  }

  if (typeof currentFieldValue === "string" && currentFieldValue.length) {
    return true;
  }

  return false;
};

const removeUnusedFieldValues = (
  formFieldsToRemove: (GroupFields | Subfields)[]
) => {
  formFieldsToRemove.forEach(formField => {
    delete currentFormValues.value[formField.key];

    if ("sub_fields" in formField && formField.sub_fields.length > 0) {
      removeUnusedFieldValues(formField.sub_fields);
    }
  });
};

const validateGreenifiedForm = (): boolean => {
  removeUnusedFieldValues(
    filteredFormFields(greenifiedFormProps.formFields).invalidFields
  );

  const filteredFormGroupFields = filteredFormFields(
    greenifiedFormProps.formFields
  ).validFields;
  const formHasInvalidGroupFields = filteredFormGroupFields.some(
    groupField => !fieldIsValid(groupField)
  );

  if (formHasInvalidGroupFields) return false;

  let formHasInvalidSubFields = false;

  for (let i = 0; i < filteredFormGroupFields.length; i++) {
    const groupField = filteredFormGroupFields[i];
    if ("sub_fields" in groupField) {
      const filteredFormSubFields = filteredFormFields(
        groupField.sub_fields
      ).validFields;

      formHasInvalidSubFields = filteredFormSubFields.some(
        subField => !fieldIsValid(subField)
      );
      if (formHasInvalidSubFields) return false;
    }
  }

  emit("update:formIsSubmitted", false);

  return true;
};

watch(
  () => greenifiedFormProps.supplierCode,
  newSupplier => {
    currentSupplierCode.value = newSupplier;
  }
);

__expose({ validateGreenifiedForm });

return (_ctx: any,_cache: any) => {
  const _component_GreenifiedForm = _resolveComponent("GreenifiedForm", true)!

  return (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(filteredFormFields(_ctx.formFields)
      .validFields, (formField, fieldIndex) => {
    return (_openBlock(), _createElementBlock("div", {
      key: formField,
      ref_for: true,
      ref: 
        element => {
          formRefs.value[fieldIndex] = element as HTMLDivElement | null;
        }
      ,
      class: _normalizeClass(getContainerOrRowStyling(fieldIndex, formField))
    }, [
      (!_ctx.hideTitle)
        ? (_openBlock(), _createBlock(FieldLabel, {
            key: 0,
            showLabel: fieldHasLabel(formField.type),
            fieldKey: formField.key,
            fieldLabel: formField.label,
            requiredField: formField.required,
            presetFieldValue: currentFormValues.value[formField.key],
            showTooltip: formField.instructions ? true : false,
            tooltipInstructions: formField.instructions,
            formIsSubmitted: _ctx.formIsSubmitted
          }, null, 8, ["showLabel", "fieldKey", "fieldLabel", "requiredField", "presetFieldValue", "showTooltip", "tooltipInstructions", "formIsSubmitted"]))
        : _createCommentVNode("", true),
      (
          Object.keys(_ctx.formImages).length === 4 &&
          formField.name === 'bilder' &&
          _ctx.productImages
        )
        ? (_openBlock(), _createBlock(FormImages, {
            key: 1,
            formImages: currentFormImages.value,
            "onUpdate:formImages": [
              _cache[0] || (_cache[0] = ($event: any) => ((currentFormImages).value = $event)),
              _cache[1] || (_cache[1] = 
          () => {
            currentFormValues.value = updateFormImages(
              currentFormValues.value,
              currentFormImages.value
            );
            emit('update:formValues', currentFormValues.value);
          }
        )
            ],
            productImages: _ctx.productImages,
            "onUpdate:productImages": _cache[2] || (_cache[2] = 
          (updatedImages) => emit('update:productImages', updatedImages)
        )
          }, null, 8, ["formImages", "productImages"]))
        : _createCommentVNode("", true),
      (formField.type === 'group')
        ? (_openBlock(), _createBlock(_component_GreenifiedForm, {
            key: currentSupplierCode.value,
            formValues: currentFormValues.value,
            "onUpdate:formValues": [
              _cache[3] || (_cache[3] = ($event: any) => ((currentFormValues).value = $event)),
              _cache[7] || (_cache[7] = ($event: any) => (emit('update:formValues', currentFormValues.value)))
            ],
            priceSuggestion: _ctx.priceSuggestion,
            formFields: formField.sub_fields,
            hideTitle: _ctx.hideTitle,
            onInput: _cache[4] || (_cache[4] = e => emit('input', e)),
            addressSuggestion: _ctx.addressSuggestion,
            excludedFields: _ctx.excludedFields,
            onPriceChange: _cache[5] || (_cache[5] = ($event: any) => (emit('priceChange'))),
            includedFields: _ctx.includedFields,
            onShowAddSupplier: _cache[6] || (_cache[6] = ($event: any) => (emit('showAddSupplier'))),
            "onUpdate:supplierCode": _cache[8] || (_cache[8] = 
          supplierCode => emit('update:supplierCode', supplierCode)
        ),
            "onUpdate:productCategory": _cache[9] || (_cache[9] = 
          category => emit('update:productCategory', category)
        ),
            supplierCode: currentSupplierCode.value,
            formIsSubmitted: _ctx.formIsSubmitted
          }, null, 8, ["formValues", "priceSuggestion", "formFields", "hideTitle", "addressSuggestion", "excludedFields", "includedFields", "supplierCode", "formIsSubmitted"]))
        : (_openBlock(), _createElementBlock("div", {
            key: 3,
            class: _normalizeClass(!_ctx.hideTitle ? 'col-8' : 'col-12')
          }, [
            _createElementVNode("div", _hoisted_1, [
              (formField.type === 'text' && formField.name !== 'varumarke')
                ? (_openBlock(), _createBlock(InputField, {
                    required: formField.required === 0 ? false : true,
                    name: formField.key,
                    type: 
              numericFieldTypes.includes(formField.name) ? 'number' : 'text'
            ,
                    maxlength: "60",
                    id: formField.key,
                    autocomplete: 
              formField.name === 'pris' || formField.name === 'adress'
                ? 'off'
                : 'on'
            ,
                    key: String(addressFieldUpdated.value),
                    placeHolder: formField.placeholder,
                    fieldValue: currentFormValues.value[formField.key],
                    "onUpdate:fieldValue": [($event: any) => ((currentFormValues.value[formField.key]) = $event), ($event: any) => {
              inputText(currentFormValues.value[formField.key], formField);
              if (
                formField.name === 'pris' ||
                formField.name === 'saljs_styckvis_eller_i_grupp' ||
                formField.name === 'ange-antal'
              )
                emit('priceChange');
            }],
                    showUnit: 'append' in formField && formField.append ? true : false,
                    errorWithInputValue: 
              formField.required === 1 &&
              !String(currentFormValues.value[formField.key]).length &&
              _ctx.formIsSubmitted
            ,
                    onClick: _cache[10] || (_cache[10] = ($event: any) => (toggleDropdown.value = true))
                  }, null, 8, ["required", "name", "type", "id", "autocomplete", "placeHolder", "fieldValue", "onUpdate:fieldValue", "showUnit", "errorWithInputValue"]))
                : _createCommentVNode("", true),
              (
              formField.type === 'text' &&
              formField.name === 'adress' &&
              _ctx.addressSuggestion?.length > 0
            )
                ? (_openBlock(), _createBlock(AddressField, {
                    key: 1,
                    addressField: formField,
                    addressSuggestions: greenifiedFormProps.addressSuggestion,
                    addressFieldValues: currentFormValues.value,
                    "onUpdate:addressFieldValues": [
                      _cache[11] || (_cache[11] = ($event: any) => ((currentFormValues).value = $event)),
                      _cache[13] || (_cache[13] = ($event: any) => (
              addressFieldUpdated.value = !addressFieldUpdated.value
            ))
                    ],
                    toggleDropdown: toggleDropdown.value,
                    "onUpdate:toggleDropdown": _cache[12] || (_cache[12] = ($event: any) => ((toggleDropdown).value = $event))
                  }, null, 8, ["addressField", "addressSuggestions", "addressFieldValues", "toggleDropdown"]))
                : _createCommentVNode("", true)
            ]),
            (formField.type === 'message')
              ? (_openBlock(), _createBlock(MessageField, {
                  key: 0,
                  messageField: formField
                }, null, 8, ["messageField"]))
              : _createCommentVNode("", true),
            (formField.name === 'pris' && _ctx.priceSuggestion.length > 0)
              ? (_openBlock(), _createBlock(PriceField, {
                  key: 1,
                  currentPrice: currentFormValues.value[formField.key],
                  "onUpdate:currentPrice": [
                    ($event: any) => ((currentFormValues.value[formField.key]) = $event),
                    _cache[14] || (_cache[14] = ($event: any) => (emit('update:formValues', currentFormValues.value)))
                  ],
                  priceSuggestions: _ctx.priceSuggestion
                }, null, 8, ["currentPrice", "onUpdate:currentPrice", "priceSuggestions"]))
              : _createCommentVNode("", true),
            (formField.type === 'date_picker')
              ? (_openBlock(), _createBlock(InputField, {
                  key: 2,
                  required: formField.required === 0 ? false : true,
                  type: "date",
                  id: "name-id",
                  maxValue: "9999-12-31",
                  name: formField.key,
                  maxlength: "60",
                  fieldValue: currentFormValues.value[formField.key],
                  "onUpdate:fieldValue": [($event: any) => ((currentFormValues.value[formField.key]) = $event), ($event: any) => (
            inputText(currentFormValues.value[formField.key], formField)
          )],
                  errorWithInputValue: 
            formField.required === 1 &&
            !String(currentFormValues.value[formField.key]).length &&
            _ctx.formIsSubmitted
          
                }, null, 8, ["required", "name", "fieldValue", "onUpdate:fieldValue", "errorWithInputValue"]))
              : _createCommentVNode("", true),
            (formField.type === 'checkbox')
              ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(formField.choices, (choice, choiceKey) => {
                    return (_openBlock(), _createElementBlock("div", {
                      class: _normalizeClass(formField.name === 'lest_og_godkjent' ? 'col-12' : 'col-4'),
                      key: choice
                    }, [
                      (Object.keys(formField.choices).length === 1)
                        ? (_openBlock(), _createBlock(CheckboxField, {
                            key: 0,
                            option: choice,
                            presetCheckboxValues: currentFormValues.value[formField.key],
                            "onUpdate:presetCheckboxValues": [
                              ($event: any) => ((currentFormValues.value[formField.key]) = $event),
                              _cache[16] || (_cache[16] = ($event: any) => (
                emit('update:formValues', currentFormValues.value)
              ))
                            ],
                            checked: currentFormValues.value[formField.key] === choiceKey,
                            checkboxField: formField,
                            trueValue: choiceKey,
                            falseValue: null,
                            onDelete: ($event: any) => (delete currentFormValues.value[formField.key]),
                            onInput: _cache[15] || (_cache[15] = (inputValue) => emit('input', inputValue))
                          }, null, 8, ["option", "presetCheckboxValues", "onUpdate:presetCheckboxValues", "checked", "checkboxField", "trueValue", "onDelete"]))
                        : _createCommentVNode("", true),
                      (Object.keys(formField.choices).length > 1)
                        ? (_openBlock(), _createBlock(MultipleCheckboxField, {
                            key: 1,
                            option: choice,
                            presetMultipleCheckboxValues: 
                currentFormValues.value[formField.key]
              ,
                            "onUpdate:presetMultipleCheckboxValues": [
                              ($event: any) => ((
                currentFormValues.value[formField.key]
              ) = $event),
                              _cache[17] || (_cache[17] = ($event: any) => (
                emit('update:formValues', currentFormValues.value)
              ))
                            ],
                            multipleCheckboxField: formField,
                            onDelete: ($event: any) => (delete currentFormValues.value[formField.key])
                          }, null, 8, ["option", "presetMultipleCheckboxValues", "onUpdate:presetMultipleCheckboxValues", "multipleCheckboxField", "onDelete"]))
                        : _createCommentVNode("", true)
                    ], 2))
                  }), 128))
                ]))
              : _createCommentVNode("", true),
            (formField.type === 'radio')
              ? (_openBlock(), _createElementBlock("div", {
                  key: 4,
                  class: _normalizeClass(["row-gap-2", 
              formField.name === 'skick' ? 'col-12 d-flex flex-wrap' : 'row'
            ])
                }, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(formField.choices, (choice, choiceKey) => {
                    return (_openBlock(), _createElementBlock("div", {
                      key: choice,
                      class: _normalizeClass(formField.name === 'skick' ? 'me-3' : 'col-4')
                    }, [
                      (Object.keys(formField.choices).length === 1)
                        ? (_openBlock(), _createBlock(RadioField, {
                            key: 0,
                            option: choice,
                            optionKey: choiceKey,
                            presetRadioFieldValues: currentFormValues.value[formField.key],
                            radioField: formField,
                            "onUpdate:presetRadioFieldValues": _cache[18] || (_cache[18] = ($event: any) => (
                  emit('update:formValues', currentFormValues.value)
                ))
                          }, null, 8, ["option", "optionKey", "presetRadioFieldValues", "radioField"]))
                        : _createCommentVNode("", true),
                      (Object.keys(formField.choices).length > 1)
                        ? (_openBlock(), _createBlock(MultipleRadioField, {
                            key: 1,
                            thinButton: formField.name === 'skick' ? true : false,
                            presetMultipleRadioFieldValues: 
                  currentFormValues.value[formField.key]
                ,
                            "onUpdate:presetMultipleRadioFieldValues": [
                              ($event: any) => ((
                  currentFormValues.value[formField.key]
                ) = $event),
                              _cache[19] || (_cache[19] = ($event: any) => (
                  emit('update:formValues', currentFormValues.value)
                ))
                            ],
                            multipleRadioField: formField,
                            optionKey: choiceKey,
                            option: choice,
                            checked: 
                  currentFormValues.value[formField.key] === choiceKey ? true : false
                
                          }, null, 8, ["thinButton", "presetMultipleRadioFieldValues", "onUpdate:presetMultipleRadioFieldValues", "multipleRadioField", "optionKey", "option", "checked"]))
                        : _createCommentVNode("", true)
                    ], 2))
                  }), 128))
                ], 2))
              : _createCommentVNode("", true),
            (formField.type === 'true_false')
              ? (_openBlock(), _createBlock(CheckboxField, {
                  key: 5,
                  checkboxField: formField,
                  checked: currentFormValues.value[formField.key] === 1,
                  trueValue: 1,
                  falseValue: 0,
                  presetCheckboxValues: currentFormValues.value[formField.key],
                  "onUpdate:presetCheckboxValues": [
                    ($event: any) => ((currentFormValues.value[formField.key]) = $event),
                    _cache[21] || (_cache[21] = ($event: any) => (
            emit('update:formValues', currentFormValues.value)
          ))
                  ],
                  onDelete: ($event: any) => (delete currentFormValues.value[formField.key]),
                  onInput: _cache[20] || (_cache[20] = (inputValue) => emit('input', inputValue))
                }, null, 8, ["checkboxField", "checked", "presetCheckboxValues", "onUpdate:presetCheckboxValues", "onDelete"]))
              : _createCommentVNode("", true),
            (formField.name === 'varumarke' && formField.type === 'text')
              ? (_openBlock(), _createBlock(SupplierField, {
                  presetSupplierField: formField,
                  supplierFieldValues: currentFormValues.value[formField.key],
                  "onUpdate:supplierFieldValues": [
                    ($event: any) => ((currentFormValues.value[formField.key]) = $event),
                    _cache[24] || (_cache[24] = ($event: any) => (
            emit('update:formValues', currentFormValues.value)
          ))
                  ],
                  currentSupplierCode: currentSupplierCode.value,
                  "onUpdate:currentSupplierCode": [
                    _cache[22] || (_cache[22] = ($event: any) => ((currentSupplierCode).value = $event)),
                    _cache[25] || (_cache[25] = 
            supplierCode => emit('update:supplierCode', supplierCode)
          )
                  ],
                  onShowSupplierModal: _cache[23] || (_cache[23] = ($event: any) => (emit('showAddSupplier'))),
                  key: currentSupplierCode.value,
                  showError: 
            formField.required === 1 &&
            _ctx.formIsSubmitted &&
            (!currentFormValues.value[formField.key] ||
              !String(currentFormValues.value[formField.key]).length)
          
                }, null, 8, ["presetSupplierField", "supplierFieldValues", "onUpdate:supplierFieldValues", "currentSupplierCode", "showError"]))
              : _createCommentVNode("", true),
            (
            formField.type === 'acfe_taxonomy_terms' &&
            formField.field_type === 'select'
          )
              ? (_openBlock(), _createBlock(TaxonomyField, {
                  key: 7,
                  taxonomyField: formField,
                  taxonomyFieldValues: currentFormValues.value[formField.key],
                  "onUpdate:taxonomyFieldValues": [($event: any) => ((currentFormValues.value[formField.key]) = $event), (option) => { changeSelect(formField, option)}],
                  showError: 
            formField.required === 1 &&
            _ctx.formIsSubmitted &&
            (!currentFormValues.value[formField.key] ||
              !String(currentFormValues.value[formField.key]).length)
          
                }, null, 8, ["taxonomyField", "taxonomyFieldValues", "onUpdate:taxonomyFieldValues", "showError"]))
              : _createCommentVNode("", true),
            (formField.type === 'select')
              ? (_openBlock(), _createBlock(SelectField, {
                  selectField: formField,
                  key: String(addressFieldUpdated.value),
                  selectFieldValues: currentFormValues.value[formField.key],
                  "onUpdate:selectFieldValues": [($event: any) => ((currentFormValues.value[formField.key]) = $event), (option) => changeSelect(formField, option)],
                  showError: 
            formField.required === 1 &&
            _ctx.formIsSubmitted &&
            (!currentFormValues.value[formField.key] ||
              !String(currentFormValues.value[formField.key]).length)
          
                }, null, 8, ["selectField", "selectFieldValues", "onUpdate:selectFieldValues", "showError"]))
              : _createCommentVNode("", true)
          ], 2))
    ], 2))
  }), 128))
}
}

})