import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeProps as _normalizeProps, guardReactiveProps as _guardReactiveProps, createTextVNode as _createTextVNode, withCtx as _withCtx, createVNode as _createVNode, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"
import _imports_0 from '@/assets/img/drop-arrow.svg'


const _hoisted_1 = { class: "d-flex flex-column justify-content-between h-100" }
const _hoisted_2 = { class: "form-label-paragraph mb-2" }
const _hoisted_3 = { class: "container" }
const _hoisted_4 = { class: "col-4 ps-0" }
const _hoisted_5 = { class: "col-4" }
const _hoisted_6 = { class: "col-4" }
const _hoisted_7 = {
  class: "container",
  style: {"max-height":"12rem","overflow-y":"auto","overflow-x":"hidden"}
}
const _hoisted_8 = { class: "col-4 ps-0" }
const _hoisted_9 = { class: "col-4" }
const _hoisted_10 = { class: "col-4" }
const _hoisted_11 = { class: "d-flex align-items-center justify-content-between" }
const _hoisted_12 = { style: {"font-weight":"400"} }

import {
  ref,
  onMounted,
  Ref
} from "vue";
import { withPopper } from "../../modules/popperModule";

import GreenifiedModal from "./GreenifiedModal.vue";
import { useI18n } from "vue-i18n";
import { ProjectSettingsAdress } from "@/services/api/addressApi";
import { AddressInformation, RoomObject } from "@/library/types/room/rooms";
// eslint-disable-next-line @typescript-eslint/no-unused-vars, no-unused-vars
import vSelect, { VueSelectSlotScope } from "vue-select";
import { moveRoomsFromAddress } from "@/library/api/rooms";

type RemoveAddressModalProps = {
  addressRooms: RoomObject[];
  addresses: ProjectSettingsAdress[];
  addressToDelete: AddressInformation["id"];
};


export default /*@__PURE__*/_defineComponent({
  __name: 'RemoveAddressModal',
  props: {
    addressRooms: { default: () => [] },
    addresses: { default: () => [] },
    addressToDelete: { default: "" }
  },
  emits: ["close", "removeAddress"],
  setup(__props: any, { emit: __emit }) {

const removeAddressModalProps = __props;

const emit = __emit;
const { t } = useI18n();
const isLoading = ref(false);
const showMoveVerification = ref(false);
const newAddress = ref("");
const filteredAddresses: Ref<ProjectSettingsAdress[]> = ref([]);

const getAddressName = (addressId: string) => {
  return removeAddressModalProps.addresses.find(
    address => address.id === addressId
  );
};
const moveRoomsToNewAddress = async () => {
  isLoading.value = true;
  const result = await moveRoomsFromAddress(
    removeAddressModalProps.addressToDelete,
    newAddress.value
  );

  showMoveVerification.value = false;
  isLoading.value = false;

  if (!result.success) return;

  emit("removeAddress", removeAddressModalProps.addressToDelete);
};

onMounted(() => {
  filteredAddresses.value = removeAddressModalProps.addresses.filter(
    address => address.id !== removeAddressModalProps.addressToDelete
  );
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(GreenifiedModal, {
      onClose: _cache[2] || (_cache[2] = ($event: any) => (emit('close'))),
      type: "medium",
      title: _unref(t)('title.removeAddress'),
      isLoading: isLoading.value
    }, {
      container: _withCtx(() => [
        _createElementVNode("div", _hoisted_1, [
          _createElementVNode("div", null, [
            _createElementVNode("p", _hoisted_2, _toDisplayString(_unref(t)("paragraph.removeAddressProductInformation")), 1),
            _createElementVNode("div", _hoisted_3, [
              _createElementVNode("div", {
                class: _normalizeClass(["row table-header d-flex align-items-center cursor-default", _ctx.addressRooms.length > 3 ? 'pe-2' : ''])
              }, [
                _createElementVNode("div", _hoisted_4, _toDisplayString(_unref(t)("tableTitle.roomType")), 1),
                _createElementVNode("div", _hoisted_5, _toDisplayString(_unref(t)("tableTitle.floor")), 1),
                _createElementVNode("div", _hoisted_6, _toDisplayString(_unref(t)("tableTitle.room")), 1)
              ], 2)
            ]),
            _createElementVNode("div", _hoisted_7, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.addressRooms, (room) => {
                return (_openBlock(), _createElementBlock("div", {
                  class: "row table-item d-flex align-items-center",
                  key: room.hus
                }, [
                  _createElementVNode("div", _hoisted_8, _toDisplayString(_unref(t)("label.fromRoom")), 1),
                  _createElementVNode("div", _hoisted_9, _toDisplayString(room.van), 1),
                  _createElementVNode("div", _hoisted_10, _toDisplayString(room.rumsnamn), 1)
                ]))
              }), 128))
            ])
          ]),
          _createElementVNode("div", _hoisted_11, [
            _createVNode(_unref(vSelect), {
              clearable: false,
              options: filteredAddresses.value,
              class: "room-select",
              "open-direction": 'top',
              label: "building",
              placeholder: _unref(t)('placeholders.selectAddressForRooms'),
              reduce: (address) => address.id,
              "onOption:selecting": _cache[0] || (_cache[0] = ($event: any) => (showMoveVerification.value = true)),
              modelValue: newAddress.value,
              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((newAddress).value = $event)),
              "append-to-body": "",
              "calculate-position": _unref(withPopper)
            }, {
              "open-indicator": _withCtx(({
                attributes
              }: {
                attributes: VueSelectSlotScope
              }) => [
                _createElementVNode("span", _normalizeProps(_guardReactiveProps(attributes)), _cache[5] || (_cache[5] = [
                  _createElementVNode("img", { src: _imports_0 }, null, -1)
                ]), 16)
              ]),
              option: _withCtx(({
                building,
                address
              }: {
                building: string,
                address: AddressInformation['address']
              }) => [
                _createElementVNode("div", null, [
                  _createTextVNode(_toDisplayString(building) + " ", 1),
                  _createElementVNode("p", _hoisted_12, [
                    _createTextVNode(_toDisplayString(address.adress), 1),
                    _cache[6] || (_cache[6] = _createElementVNode("br", null, null, -1)),
                    _createTextVNode(_toDisplayString(address.postnummer) + "," + _toDisplayString(address.ort) + "," + _toDisplayString(address.land), 1)
                  ])
                ])
              ]),
              _: 1
            }, 8, ["options", "placeholder", "reduce", "modelValue", "calculate-position"])
          ])
        ])
      ]),
      _: 1
    }, 8, ["title", "isLoading"]),
    (showMoveVerification.value)
      ? (_openBlock(), _createBlock(GreenifiedModal, {
          key: 0,
          type: "verification",
          closable: false,
          title: _unref(t)('label.moveRoomsAndRemove'),
          text: 
      _unref(t)('msg.verifyRoomMove', [
        getAddressName(_ctx.addressToDelete)?.address.adress,
        getAddressName(newAddress.value)?.address.adress,
        getAddressName(_ctx.addressToDelete)?.address.adress
      ])
    ,
          onClose: _cache[3] || (_cache[3] = ($event: any) => {
      showMoveVerification.value = false;
      newAddress.value = '';
    }),
          onConfirm: _cache[4] || (_cache[4] = ($event: any) => {
      moveRoomsToNewAddress();
      showMoveVerification.value = false;
    })
        }, null, 8, ["title", "text"]))
      : _createCommentVNode("", true)
  ], 64))
}
}

})