<template>
  <GreenifiedModal
    :title="t('button.orderProduct')"
    @close="emit('close')"
    :type="'smaller'"
    :isLoading="isLoading"
  >
    <template #container>
      <form
        ref="purchaseOrderForm"
        id="purchaseOrderForm"
        @submit.prevent="postPurchaseOrder"
      >
        <div class="form-container" v-if="productInformation && purchaseOrder">
          <div class="row" v-if="productAmountOptions.length > 1">
            <div
              class="col-6 d-flex form-label-paragraph align-items-center fw-bold"
            >
              <label>
                {{ t("label.selectQuantity") }}
                {{
                  productInformation.group.sell_grupp === "Grupp" ||
                  productInformation.group.sale_info
                    .saljs_styckvis_eller_i_grupp === "group"
                    ? `(${t("label.inGroupOf")} ${
                        productInformation?.group.sell_antal ??
                        productInformation.group.sale_info["ange-antal"]
                      })`
                    : ""
                }}
                *</label
              >
            </div>
            <div class="col-6">
              <CustomSelect
                :options="productAmountOptions"
                label="label"
                v-model="purchaseOrder.requested_quantity"
                :placeholder="t('label.selectQuantity')"
                :reducedKey="'value'"
                :required="true"
              />
            </div>
          </div>
          <div class="row">
            <div
              class="col-6 form-label-paragraph fw-bold align-items-center d-flex"
            >
              <label> {{ t("label.whenToBeCollected") }}</label>
            </div>
            <div class="col-6">
              <InputField
                v-if="productInformation"
                v-model:field-value="purchaseOrder.pick_up_date"
                :minValue="productInformation.group?.sell_tillganglig"
                maxValue="9999-12-31"
                type="date"
              />
            </div>
          </div>

          <div class="row">
            <div
              class="col-6 form-label-paragraph fw-bold align-items-center d-flex"
            >
              <label> {{ t("label.email") }} *</label>
            </div>
            <div class="col-6">
              <InputField
                required
                v-model:field-value="purchaseOrder.email"
                :placeHolder="t('placeholders.yourEmail')"
                type="email"
              />
            </div>
          </div>

          <div class="row">
            <div class="col-6 pt-2 form-label-paragraph fw-bold d-flex">
              <label> {{ t("label.orderNumber") }}</label>
            </div>
            <div class="col-6">
              <InputField
                :placeHolder="t('label.orderNumber')"
                type="text"
                v-model:field-value="purchaseOrder.order_number"
              />
            </div>
          </div>
          <div class="row">
            <div class="col-6 pt-2 form-label-paragraph fw-bold d-flex">
              <label> {{ t("label.comment") }}</label>
            </div>
            <div class="col-6">
              <InputTextField
                :placeHolder="t('placeholders.internalBuyComment')"
                type="text"
                v-model="purchaseOrder.order_comment"
                :rows="2"
              />
            </div>
          </div>
        </div>
      </form>
    </template>
    <template #footer>
      <div class="col-12">
        <div class="col-4">
          <GreenifiedButton
            :disabled="!requiredFieldsAreValid"
            :text="t('button.orderProduct')"
            :btn-style="'green'"
            :form="'purchaseOrderForm'"
            :type="'submit'"
          />
        </div>
      </div>
    </template>
  </GreenifiedModal>
  <GreenifiedModal
    type="response"
    :closable="false"
    @close="
      emit('refresh');
      emit('close');
    "
    :title="t('title.thanksForTheOrder')"
    :text="t('paragraph.thanksForTheOrder')"
    v-if="showPurchaseVerification"
  />
</template>

<script setup lang="ts">
import {
  ref,
  defineProps,
  withDefaults,
  defineEmits,
  computed,
  Ref,
  onMounted
} from "vue";
import GreenifiedModal from "../modal/GreenifiedModal.vue";
import GreenifiedButton from "../common/GreenifiedButton.vue";
import { AdvertisementProductHeader } from "@/library/types/product/productHeader";
import { useI18n } from "vue-i18n";
import InputField from "../common/InputField.vue";
import InputTextField from "../common/InputTextField.vue";
import CustomSelect from "../common/CustomSelect.vue";
import { useRoute } from "vue-router";
import { CustomError, Result } from "@/library/helpers/handleApiRequests";
import { AxiosResponse } from "axios";
import { postGuestOrder, postInternalOrder } from "@/library/api/sales";
import { PurchaseOrder } from "@/services/api/salesApi";

type InternalBuyPropTypes = {
  productToPurchase: AdvertisementProductHeader["key"] | null;
};

type QuantityOption = {
  label: string;
  value: number;
};

const internalBuyProps = withDefaults(defineProps<InternalBuyPropTypes>(), {
  productToPurchase: null
});

const productAmountOptions = computed(() => {
  let amountOptions: QuantityOption[] = [];
  if (!internalBuyProps.productToPurchase) return amountOptions;

  const soldInGroupOf = Number(
    internalBuyProps.productToPurchase.group.sale_info["ange-antal"]
  );

  const isSoldInGroup =
    internalBuyProps.productToPurchase.group.sale_info
      .saljs_styckvis_eller_i_grupp === "group";

  const productAmount = Number(
    internalBuyProps.productToPurchase.group.quantity
  );

  if (isSoldInGroup && soldInGroupOf && soldInGroupOf > 1) {
    amountOptions = Array.from(
      { length: productAmount },
      // eslint-disable-next-line no-unused-vars
      (_, number) => number + 1
    )
      .filter(value => value % soldInGroupOf === 0)
      .map(value => ({
        label: value === productAmount ? t("label.all") : String(value),
        value: value
      }));
  } else {
    // eslint-disable-next-line no-unused-vars
    amountOptions = Array.from({ length: productAmount }, (_, number) => ({
      label: number === productAmount - 1 ? t("label.all") : String(number + 1),
      value: number + 1
    }));
  }

  return amountOptions;
});
const route = useRoute();
const emit = defineEmits(["refresh", "close"]);

const { t } = useI18n();
const showPurchaseVerification = ref(false);
const isLoading = ref(false);
const productInformation = ref(internalBuyProps.productToPurchase);
const purchaseOrder: Ref<PurchaseOrder | null> = ref(null);

const purchaseOrderForm = ref<HTMLFormElement | null>(null);

const requiredFieldsAreValid = computed((): boolean => {
  if (!purchaseOrder.value || !purchaseOrder.value.requested_quantity)
    return false;

  return (
    Number(purchaseOrder.value.requested_quantity) > 0 &&
    purchaseOrder.value.email.length > 0
  );
});

const postPurchaseOrder = async () => {
  if (!internalBuyProps.productToPurchase || !purchaseOrder.value) return;

  if (purchaseOrderForm.value && !purchaseOrderForm.value.reportValidity()) {
    return;
  }
  isLoading.value = true;

  const guestToken = route.params.token as string;
  let result: Result<AxiosResponse, CustomError>;

  if (guestToken) {
    result = await postGuestOrder(guestToken, purchaseOrder.value);
  } else {
    result = await postInternalOrder(purchaseOrder.value);
  }

  isLoading.value = false;
  if (!result.success) return;

  showPurchaseVerification.value = true;
};

onMounted(() => {
  if (!internalBuyProps.productToPurchase) return;
  purchaseOrder.value = {
    requested_quantity: null,
    email: "",
    order_comment: null,
    pick_up_date: "",
    order_number: null,
    advertisement_id: internalBuyProps.productToPurchase.group.advertisement_id,
    productId: internalBuyProps.productToPurchase?.items[0].id
  };

  if (productAmountOptions.value.length === 1) {
    purchaseOrder.value.requested_quantity = String(
      internalBuyProps.productToPurchase?.group.quantity
    );
  }
});
</script>

<style scoped>
.form-container {
  > div {
    margin-top: 1rem;
  }
}
</style>
