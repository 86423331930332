import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, createTextVNode as _createTextVNode, withCtx as _withCtx, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "form-container" }
const _hoisted_2 = { class: "row" }
const _hoisted_3 = { class: "col-6 d-flex form-label-paragraph align-items-center fw-bold" }
const _hoisted_4 = { class: "col-6" }
const _hoisted_5 = {
  key: 0,
  class: "row"
}
const _hoisted_6 = { class: "col-6 d-flex form-label-paragraph align-items-center fw-bold" }
const _hoisted_7 = { class: "col-6" }
const _hoisted_8 = { class: "row" }
const _hoisted_9 = { class: "col-6 form-label-paragraph fw-bold align-items-center d-flex" }
const _hoisted_10 = { class: "col-6" }
const _hoisted_11 = { class: "row" }
const _hoisted_12 = { class: "col-6 form-label-paragraph fw-bold align-items-center d-flex" }
const _hoisted_13 = { class: "col-6" }
const _hoisted_14 = { class: "row" }
const _hoisted_15 = { class: "col-6 form-label-paragraph fw-bold align-items-center d-flex" }
const _hoisted_16 = { class: "col-6" }
const _hoisted_17 = { class: "row mt-5" }
const _hoisted_18 = { class: "col-6 form-label-paragraph fw-bold align-items-center d-flex" }
const _hoisted_19 = { class: "col-6" }
const _hoisted_20 = { class: "subheading-tiny" }
const _hoisted_21 = { class: "row" }
const _hoisted_22 = { class: "col-6 pt-2 form-label-paragraph fw-bold d-flex" }
const _hoisted_23 = { class: "col-6" }
const _hoisted_24 = { class: "subheading-tiny" }
const _hoisted_25 = { class: "col-12" }
const _hoisted_26 = { class: "row" }
const _hoisted_27 = { class: "col-8" }
const _hoisted_28 = {
  key: 0,
  class: "pt-4 position-relative"
}
const _hoisted_29 = { key: 1 }
const _hoisted_30 = { class: "col-4" }

import {
  ref,
  computed,
  Ref,
  onMounted
} from "vue";

import {
  denyInternalOrders,
  getInternalOrdersByAdvertisementId,
  updateInternalOrder
} from "@/library/api/sales";
import GreenifiedModal from "../modal/GreenifiedModal.vue";
import GreenifiedButton from "../common/GreenifiedButton.vue";
import { OrderProductHeader } from "@/library/types/product/productHeader";
import { useI18n } from "vue-i18n";
import InputField from "../common/InputField.vue";
import CustomSelect, { LocalVueSelectOption } from "../common/CustomSelect.vue";
import SelectRoom from "../SelectRoom.vue";
import { ProjectSettingsAdress } from "@/services/api/addressApi";
import { RoomObject } from "@/library/types/room/rooms";
import { postNewProductRoom } from "@/library/api/moveProducts";
import { OrderDataWithProducts, OrderForUpdate } from "@/services/api/salesApi";
import { CustomError, Result } from "@/library/helpers/handleApiRequests";
import { AxiosResponse } from "axios";
import ProductsSelect from "../common/ProductsSelect.vue";
import { ProductItemSale } from "@/library/types/product/productItem";

type InternalBuyPropTypes = {
  productToPurchase: OrderProductHeader["key"] | null;
};

type QuantityOption = {
  label?: string | number;
  value: number;
};


export default /*@__PURE__*/_defineComponent({
  __name: 'InternalPurchaseOrderConfirmation',
  props: {
    productToPurchase: { default: null }
  },
  emits: ["refresh", "close"],
  setup(__props: any, { emit: __emit }) {

const internalPurchaseOrderConfirmationProps = __props;
const emit = __emit;

const { t } = useI18n();
const currentDeliveryAddress: Ref<ProjectSettingsAdress | null> = ref(null);
const showPurchaseVerification = ref(false);
const isLoading = ref(false);
const reservedProductIds: Ref<string[]> = ref([]);
const soldProductIds: Ref<string[]> = ref([]);
const productItems: Ref<ProductItemSale[]> = ref([]);
const productInformation = ref(
  internalPurchaseOrderConfirmationProps.productToPurchase
);
const addressRooms: Ref<RoomObject[]> = ref([]);
const productIds: Ref<string[]> = ref([]);
const currentSaleOrder: Ref<OrderDataWithProducts> = ref({
  products: [],
  email: "",
  status: "O",
  pick_up_date: "",
  order_number: "",
  order_id: 0,
  requested_quantity: "0",
  order_comment: "",
  delivery_address: null,
  delivery_room: null,
  greenifiedHash: null,
  advertisement_id: null
});

const saleStatuses: LocalVueSelectOption[] = [
  {
    label: `1/3 ${t("label.awaitingApproval")}`,
    value: "O"
  },
  {
    label: `2/3 ${t("label.transportBooked")}`,
    value: "A"
  },
  {
    label: `3/3 ${t("label.delivered")}`,
    value: "C"
  },
  {
    label: t("label.moveBackToAvailable"),
    value: "D"
  }
];

const purchaseOrderForm = ref<HTMLFormElement | null>(null);

onMounted(() => {
  const orderProductHeader =
    internalPurchaseOrderConfirmationProps.productToPurchase;
  if (!orderProductHeader) {
    emit("close");
    return;
  }

  getCurrentOrder();
});

const getCurrentOrder = async () => {
  const currentProductGroup =
    internalPurchaseOrderConfirmationProps.productToPurchase?.group;

  if (!currentProductGroup) return;
  isLoading.value = true;

  const result = await getInternalOrdersByAdvertisementId(
    currentProductGroup.advertisement_id
  );

  if (!result.success) {
    isLoading.value = false;
    emit("close");
    return;
  }

  const saleOrders = result.result;

  const currSaleOrder = saleOrders.find(
    order => order.order_id === currentProductGroup.order_id
  );

  if (!currSaleOrder) {
    isLoading.value = false;
    emit("close");
    return;
  }

  saleOrders.forEach(saleOrder => {
    if (saleOrder.order_id !== currSaleOrder.order_id) {
      if (saleOrder.status !== "C" && saleOrder.products.length > 0) {
        reservedProductIds.value.push(
          ...saleOrder.products.map(product => product.article_id)
        );
      } else {
        soldProductIds.value.push(
          ...saleOrder.products.map(product => product.article_id)
        );
      }
    }
  });

  productIds.value = currSaleOrder.products.map(product => product.article_id);

  productItems.value = saleOrders[0].advertisement_products.flatMap(
    adProduct => adProduct.product_data
  );

  currentSaleOrder.value = {
    products: currSaleOrder.products,
    email: currSaleOrder.email,
    status: currSaleOrder.status,
    pick_up_date: currSaleOrder?.pick_up_date?.substring(0, 10) ?? null,
    order_number: currSaleOrder.order_number,
    order_id: currSaleOrder.order_id,
    requested_quantity: currSaleOrder.requested_quantity,
    order_comment: currSaleOrder.order_comment,
    delivery_address: currSaleOrder.delivery_address,
    delivery_room: currSaleOrder.delivery_room,
    advertisement_id: currSaleOrder.advertisement_id
  };

  isLoading.value = false;
};

const productAmountOptions = computed(() => {
  let amountOptions: QuantityOption[] = [];
  if (
    !internalPurchaseOrderConfirmationProps.productToPurchase ||
    !productInformation.value
  )
    return amountOptions;

  const soldInGroupOf = Number(
    internalPurchaseOrderConfirmationProps.productToPurchase.group.sale_info[
      "ange-antal"
    ]
  );

  let availableProductItems = productInformation.value.items;
  const unavailableProductIds = [
    reservedProductIds.value,
    soldProductIds.value
  ].flat();

  if (unavailableProductIds) {
    availableProductItems = productInformation.value.items.filter(
      productItem => !unavailableProductIds.includes(productItem.id)
    );
  }

  if (soldInGroupOf && soldInGroupOf > 1) {
    amountOptions = availableProductItems
      // eslint-disable-next-line no-unused-vars
      .map((item, index) => index + 1)
      .filter(value => value % soldInGroupOf === 0)
      .map(value => ({
        label:
          value === availableProductItems.length
            ? t("label.all")
            : String(value),
        value: value
      }));
  } else {
    // eslint-disable-next-line no-unused-vars
    amountOptions = availableProductItems.map((item, index) => ({
      label:
        index === availableProductItems.length - 1 && index !== 0
          ? t("label.all")
          : String(index + 1),
      value: index + 1
    }));
  }

  return amountOptions;
});

const handleSelectAddress = (address: ProjectSettingsAdress) => {
  addressRooms.value = [];
  currentDeliveryAddress.value = address;
  currentSaleOrder.value.delivery_address = address.id;

  addressRooms.value = currentDeliveryAddress.value.rooms.filter(
    room => room.aktiv === "1"
  );
};

const postDenyPurchaseOrder = async () => {
  if (
    !internalPurchaseOrderConfirmationProps.productToPurchase?.group.green_hash
  )
    return;

  isLoading.value = true;

  const deleteOrderData: OrderForUpdate = {
    orderId: currentSaleOrder.value.order_id,
    productId:
      internalPurchaseOrderConfirmationProps.productToPurchase.items[0].id
  };

  const result = await denyInternalOrders([deleteOrderData]);

  if (!result.success) {
    isLoading.value = false;
    return;
  }

  showPurchaseVerification.value = true;
  isLoading.value = false;
};

const postPurchaseOrder = async () => {
  isLoading.value = true;

  if (purchaseOrderForm.value && !purchaseOrderForm.value.reportValidity()) {
    isLoading.value = false;
    return;
  }

  if (
    currentSaleOrder.value.delivery_room &&
    currentSaleOrder.value.delivery_room !==
      internalPurchaseOrderConfirmationProps.productToPurchase?.items[0].rum
  ) {
    const moveResult = await moveProducts(
      currentSaleOrder.value.delivery_room,
      productIds.value
    );
    if (moveResult === "error") {
      isLoading.value = false;
      return;
    }
  }

  let result: Result<AxiosResponse, CustomError>;

  result = await updateInternalOrder(currentSaleOrder.value, productIds.value);

  if (!result.success) {
    isLoading.value = false;
    return;
  }

  showPurchaseVerification.value = true;
  isLoading.value = false;
};

const moveProducts = async (newRoom: string, productIds: string[]) => {
  if (!newRoom) return;

  const result = await postNewProductRoom(productIds, newRoom);

  if (!result.success) return "error";

  return "ok";
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (currentSaleOrder.value)
      ? (_openBlock(), _createBlock(GreenifiedModal, {
          key: 0,
          title: `${_unref(t)('title.handleOrder')}${
      currentSaleOrder.value.order_number && currentSaleOrder.value.order_number.length
        ? `: ${currentSaleOrder.value.order_number}`
        : ''
    }`,
          onClose: _cache[8] || (_cache[8] = ($event: any) => (emit('close'))),
          type: 'smaller',
          isLoading: isLoading.value
        }, {
          container: _withCtx(() => [
            (!isLoading.value)
              ? (_openBlock(), _createElementBlock("form", {
                  key: 0,
                  ref_key: "purchaseOrderForm",
                  ref: purchaseOrderForm,
                  id: "purchaseOrderForm"
                }, [
                  _createElementVNode("div", _hoisted_1, [
                    _createElementVNode("div", _hoisted_2, [
                      _createElementVNode("div", _hoisted_3, [
                        _createElementVNode("label", null, _toDisplayString(_unref(t)("label.changeAmount")) + " " + _toDisplayString(productInformation.value?.group.sale_info["ange-antal"]
                    ? `(${_unref(t)("label.inGroupOf")} ${
                        productInformation.value?.group.sale_info["ange-antal"]
                      })`
                    : "") + "*", 1)
                      ]),
                      _createElementVNode("div", _hoisted_4, [
                        _createVNode(CustomSelect, {
                          options: productAmountOptions.value,
                          label: "label",
                          modelValue: currentSaleOrder.value.requested_quantity,
                          "onUpdate:modelValue": [
                            _cache[0] || (_cache[0] = ($event: any) => ((currentSaleOrder.value.requested_quantity) = $event)),
                            _cache[1] || (_cache[1] = 
                  () => {
                    if (
                      Number(currentSaleOrder.value.requested_quantity) <
                      productIds.value.length
                    ) {
                      productIds.value = [];
                    }
                  }
                )
                          ],
                          placeholder: _unref(t)('label.selectQuantity'),
                          reducedKey: 'value',
                          required: true
                        }, null, 8, ["options", "modelValue", "placeholder"])
                      ])
                    ]),
                    (productInformation.value)
                      ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                          _createElementVNode("div", _hoisted_6, [
                            _createElementVNode("label", null, _toDisplayString(_unref(t)("label.selectProducts")) + " *", 1)
                          ]),
                          _createElementVNode("div", _hoisted_7, [
                            _createVNode(ProductsSelect, {
                              maxQuantity: Number(currentSaleOrder.value.requested_quantity),
                              productItems: productItems.value,
                              reservedProductIds: reservedProductIds.value,
                              soldProductIds: soldProductIds.value,
                              selectedProductIds: productIds.value,
                              "onUpdate:selectedProductIds": _cache[2] || (_cache[2] = ($event: any) => ((productIds).value = $event)),
                              requiredQuantity: true
                            }, null, 8, ["maxQuantity", "productItems", "reservedProductIds", "soldProductIds", "selectedProductIds"])
                          ])
                        ]))
                      : _createCommentVNode("", true),
                    _createElementVNode("div", _hoisted_8, [
                      _createElementVNode("div", _hoisted_9, [
                        _createElementVNode("label", null, _toDisplayString(_unref(t)("label.whenToBeCollected")), 1)
                      ]),
                      _createElementVNode("div", _hoisted_10, [
                        (productInformation.value)
                          ? (_openBlock(), _createBlock(InputField, {
                              key: 0,
                              "field-value": currentSaleOrder.value.pick_up_date,
                              "onUpdate:fieldValue": _cache[3] || (_cache[3] = ($event: any) => ((currentSaleOrder.value.pick_up_date) = $event)),
                              minValue: productInformation.value.group?.sell_tillganglig,
                              maxValue: "9999-12-31",
                              type: "date"
                            }, null, 8, ["field-value", "minValue"]))
                          : _createCommentVNode("", true)
                      ])
                    ]),
                    _createElementVNode("div", _hoisted_11, [
                      _createElementVNode("div", _hoisted_12, [
                        _createElementVNode("label", null, _toDisplayString(_unref(t)("paragraph.deliveryAddress")), 1)
                      ]),
                      _createElementVNode("div", _hoisted_13, [
                        _createVNode(SelectRoom, {
                          currentAddress: currentSaleOrder.value.delivery_address,
                          withAddress: "",
                          "is-reduced": "",
                          currentAddressRoom: currentSaleOrder.value.delivery_address,
                          "onUpdate:currentAddress": _cache[4] || (_cache[4] = 
                  address => {
                    handleSelectAddress(address);
                    currentSaleOrder.value.delivery_room = null;
                  }
                )
                        }, null, 8, ["currentAddress", "currentAddressRoom"])
                      ])
                    ]),
                    _createElementVNode("div", _hoisted_14, [
                      _createElementVNode("div", _hoisted_15, [
                        _createElementVNode("label", null, _toDisplayString(_unref(t)("paragraph.deliveryRoom")), 1)
                      ]),
                      _createElementVNode("div", _hoisted_16, [
                        (_openBlock(), _createBlock(SelectRoom, {
                          modelValue: currentSaleOrder.value.delivery_room,
                          "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((currentSaleOrder.value.delivery_room) = $event)),
                          key: currentDeliveryAddress.value?.id,
                          defaultRooms: addressRooms.value
                        }, null, 8, ["modelValue", "defaultRooms"]))
                      ])
                    ]),
                    _createElementVNode("div", _hoisted_17, [
                      _createElementVNode("div", _hoisted_18, [
                        _createElementVNode("label", null, _toDisplayString(_unref(t)("label.email")), 1)
                      ]),
                      _createElementVNode("div", _hoisted_19, [
                        _createElementVNode("p", _hoisted_20, _toDisplayString(currentSaleOrder.value.email), 1)
                      ])
                    ]),
                    _createElementVNode("div", _hoisted_21, [
                      _createElementVNode("div", _hoisted_22, [
                        _createElementVNode("label", null, _toDisplayString(_unref(t)("label.comment")), 1)
                      ]),
                      _createElementVNode("div", _hoisted_23, [
                        _createElementVNode("p", _hoisted_24, _toDisplayString(currentSaleOrder.value.order_comment &&
                  currentSaleOrder.value.order_comment.length
                    ? currentSaleOrder.value.order_comment
                    : "-"), 1)
                      ])
                    ])
                  ])
                ], 512))
              : _createCommentVNode("", true)
          ]),
          footer: _withCtx(() => [
            _createElementVNode("div", _hoisted_25, [
              _createElementVNode("div", _hoisted_26, [
                _createElementVNode("div", _hoisted_27, [
                  (_openBlock(), _createBlock(CustomSelect, {
                    key: String(isLoading.value),
                    options: saleStatuses,
                    label: "label",
                    reducedKey: "value",
                    modelValue: currentSaleOrder.value.status,
                    "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((currentSaleOrder.value.status) = $event))
                  }, {
                    option: _withCtx((option) => [
                      (option.option.value === 'D')
                        ? (_openBlock(), _createElementBlock("div", _hoisted_28, [
                            _cache[10] || (_cache[10] = _createElementVNode("div", { class: "empty-option" }, null, -1)),
                            _createTextVNode(" " + _toDisplayString(option.option.label), 1)
                          ]))
                        : (_openBlock(), _createElementBlock("div", _hoisted_29, _toDisplayString(option.option.label), 1))
                    ]),
                    _: 1
                  }, 8, ["modelValue"]))
                ]),
                _createElementVNode("div", _hoisted_30, [
                  _createVNode(GreenifiedButton, {
                    text: _unref(t)('button.save'),
                    btnStyle: "green",
                    disabled: 
                (currentSaleOrder.value.status === 'A' ||
                  currentSaleOrder.value.status === 'C') &&
                productIds.value.length !==
                  Number(currentSaleOrder.value.requested_quantity)
              ,
                    onClick: _cache[7] || (_cache[7] = ($event: any) => (
                currentSaleOrder.value.status === 'D'
                  ? postDenyPurchaseOrder()
                  : postPurchaseOrder()
              ))
                  }, null, 8, ["text", "disabled"])
                ])
              ])
            ])
          ]),
          _: 1
        }, 8, ["title", "isLoading"]))
      : _createCommentVNode("", true),
    (showPurchaseVerification.value)
      ? (_openBlock(), _createBlock(GreenifiedModal, {
          key: 1,
          type: "response",
          closable: false,
          onClose: _cache[9] || (_cache[9] = ($event: any) => (emit('refresh'))),
          title: _unref(t)('title.orderHasBeenUpdated'),
          text: _unref(t)('paragraph.mailHasBeenSentToBuyer')
        }, null, 8, ["title", "text"]))
      : _createCommentVNode("", true)
  ], 64))
}
}

})