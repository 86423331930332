import { defineComponent as _defineComponent } from 'vue'
import { openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, unref as _unref, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, createVNode as _createVNode, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = {
  key: 1,
  class: "pb-4 position-relative"
}
const _hoisted_2 = {
  key: 1,
  class: "border-bottom"
}
const _hoisted_3 = {
  key: 0,
  class: "gr-color subtable position-relative mb-4"
}

import {
  OrderGroupSale,
  ProductGroupSale
} from "@/library/types/product/productGroup";
import {
  AdvertisementProductHeader,
  OrderProductHeader,
  ProductHeaderSale,
  ProductStatuses
} from "@/library/types/product/productHeader";
import {
  ref,
  Ref,
  onMounted,
  watch,
  computed
} from "vue";
import TableFooter from "./tableFooter/TableFooter.vue";
import { ProductItemSale } from "@/library/types/product/productItem";
import SubTableRow from "./tableRows/SubTableRow.vue";
import TableHeaderRow from "./tableHeader/TableHeaderRow.vue";
import MainTableRow from "./tableRows/MainTableRow.vue";
import {
  getMainTableColumnsByTableType,
  getSubTableColumnsByTableType
} from "@/library/helpers/structureTableColumns";
import { ColumnActions, TableTypes } from "@/library/types/tables/columns";
import {
  sortedProductHeaderRows,
  sortedProductItemRows
} from "@/library/helpers/sortTableRows";
import HistoryModal from "../modal/HistoryModal.vue";
import EditProductModal from "../modal/EditProductModal.vue";
import { useI18n } from "vue-i18n";
import { Actions, ActionStatus } from "@/library/types/actions/actions";
// eslint-disable-next-line @typescript-eslint/no-unused-vars, no-unused-vars
import { TableFooterButton } from "@/library/types/tables/footer";
import MoveProductsModal from "../modal/MoveProductsModal.vue";
import { getProductHeadersApi, updateStatus } from "@/library/api/products";
import { bolderFont } from "@/library/helpers/typography";
import GreenifiedModal from "../modal/GreenifiedModal.vue";
import LoadingOverlay from "../common/LoadingOverlay.vue";
import { useIndexStore } from "@/store";
import { FilterOptions, PresetFilter } from "@/library/types/filter/filter";
import { SortingSettings } from "@/library/types/sorting/sorting";
import ProductGroupInformation from "../ProductGroupInformation.vue";
import ProductRenovationForm from "../form/ProductRenovationForm.vue";
import ChangeActionModal from "../modal/ChangeActionModal.vue";
import InternalSaleForm from "../form/InternalSaleForm.vue";
import GreenifiedSaleForm from "../form/GreenifiedSaleForm.vue";
import InternalPurchaseOrder from "../form/InternalPurchaseOrder.vue";
import InternalPurchaseOrderConfirmation from "../form/InternalPurchaseOrderConfirmation.vue";
import {
  denyInternalOrders,
  postOldOrderAsAdvertisement
} from "@/library/api/sales";
import CombineProductsModal from "../modal/CombineProductsModal.vue";
import {
  OldOrdersAsAdvertisement,
  OrderForUpdate
} from "@/services/api/salesApi";
import { InternalProductHeaders } from "@/services/api/productsApi";

type ResponseModalObject = {
  type: "success" | "fail" | null;
  title: string;
  text: string;
};

type ProductTableProps = {
  tableType?: TableTypes;
  actionStatuses?: ActionStatus[];
  presetFilter: PresetFilter;
  filter: FilterOptions;
  sortingSettings: SortingSettings;
  translateHeaders: string[];
  noRowsText?: string;
  rowAction?: ColumnActions[];
  externalProducts?: ProductHeaderSale | InternalProductHeaders | null;
  hideCheckboxes?: boolean;
  footerIsViewable?: boolean;
};


export default /*@__PURE__*/_defineComponent({
  __name: 'ProductTable',
  props: {
    tableType: { default: "standard" },
    actionStatuses: { default: () => [] },
    presetFilter: { default: () => ({
    filter: [],
    group: ["benamn", "levkod", "besk", "kategori_id", "green_hash"],
    status: ["J"]
  }) },
    filter: { default: () => ({
    categories: {},
    intervals: {}
  }) },
    sortingSettings: { default: () => ({
    col: "updated_at",
    asc: false
  }) },
    translateHeaders: { default: () => [] },
    noRowsText: { default: "" },
    rowAction: { default: () => ["handleVariant"] },
    externalProducts: { default: null },
    hideCheckboxes: { type: Boolean, default: false },
    footerIsViewable: { type: Boolean, default: true }
  },
  emits: ["fetchProducts", "footerIsOpen", "update:filter"],
  setup(__props: any, { emit: __emit }) {

const productTableProps = __props;

const emit = __emit;
const { t, messages, locale } = useI18n();

const indexStore = useIndexStore();
const selectedProductForShowingInformation: Ref<
  ProductHeaderSale["key"] | null
> = ref(null);
const mainTableSortingOptions = ref(productTableProps.sortingSettings);
const subTableSortingOptions = ref(productTableProps.sortingSettings);

const responseModal: Ref<ResponseModalObject> = ref({
  type: null,
  title: "",
  text: ""
});
const showResponseModal = ref(false);
const productToPurchase: Ref<ProductHeaderSale["key"] | null> = ref(null);
const productHeaders: Ref<ProductHeaderSale> = ref({});
const editProductId = ref("");
const currentSelectedProductHeader: Ref<ProductHeaderSale["key"] | null> =
  ref(null);
const editModalTitle = ref("");
const editProductType: Ref<"item" | "group"> = ref("group");
const showSubTable = ref(false);
const productHistoryId = ref("");
const hideHeaderCheckbox = ref(productTableProps.hideCheckboxes);
const toggleMainHeaderCheckbox = ref(false);
const toggleSubHeaderCheckbox = ref(false);
const selectedProducts = ref(0);
const currentRoom: Ref<string | null> = ref(null);
const modalType: Ref<Actions | null> = ref(null);
const isLoading = ref(false);

const selectedProductGroupForShowingItems: Ref<ProductGroupSale | null> =
  ref(null);
const selectedProductGroupsForUpdates: Ref<ProductGroupSale["green_hash"][]> =
  ref([]);
const selectedOrderIds: Ref<OrderGroupSale["order_id"][]> = ref([]);
const ordersForUpdate: Ref<OrderForUpdate[]> = ref([]);
const selectedProductItemsForUpdates: Ref<ProductItemSale["id"][]> = ref([]);

const filteredProductHeaders = computed(() => {
  const currFilteredProductHeaders: ProductHeaderSale = {};

  for (const key in productHeaders.value) {
    const currentProductHeader = productHeaders.value[key];

    const isGroupMatching = checkFilter(null, currentProductHeader.group);
    const matchingItems = filteredProductItems(currentProductHeader);
    const hasMatchingItems = matchingItems.length > 0;

    if (isGroupMatching || hasMatchingItems) {
      currFilteredProductHeaders[key] = {
        ...currentProductHeader,
        items: matchingItems
      };
    }
  }

  if (Object.keys(productTableProps.filter.categories).length) {
    indexStore.calculateMaxQuantity(currFilteredProductHeaders);
  } else {
    indexStore.calculateMaxQuantity(productHeaders.value);
  }

  return currFilteredProductHeaders;
});

const filteredProductItems = (productHeader: ProductHeaderSale["key"]) => {
  return productHeader.items.filter(productItem =>
    checkFilter(productItem, productHeader.group)
  );
};
const checkFilter = (
  productItem: ProductItemSale | null,
  productGroup: ProductGroupSale
) => {
  const filterCategories = { ...productTableProps.filter.categories };

  if (
    !Object.keys(filterCategories).length &&
    !Object.keys(productTableProps.filter.intervals).length
  ) {
    return true;
  }

  if (!productGroupMatchesIntervalAmount(productGroup)) {
    return false;
  }

  for (const key in filterCategories) {
    const categories = filterCategories[key];
    const filterType = key;

    if (
      filterType === "search" &&
      !productMatchesSearchValue(categories, productGroup, productItem)
    ) {
      return false;
    }

    if (filterType !== "search") {
      if (
        productItem &&
        !productItemMatchesFilterType(filterType, categories, productItem)
      ) {
        return false;
      }

      if (
        !productGroupMatchesFilterType(filterType, categories, productGroup)
      ) {
        return false;
      }
    }
  }

  return true;
};

const postOldOrderToNewAdvertisement = async () => {
  const oldOrders: OldOrdersAsAdvertisement[] = [];
  for (const key in filteredProductHeaders.value) {
    const currProductHeader = filteredProductHeaders.value[key];
    if (
      selectedProductGroupsForUpdates.value.includes(
        currProductHeader.group.green_hash
      )
    ) {
      oldOrders.push({
        saleInfo: {
          "ange-antal": Number(currProductHeader.group.sell_antal) ?? null,
          saljs_styckvis_eller_i_grupp:
            currProductHeader.group?.sell_grupp === "Styckvis"
              ? "single"
              : "group",
          nar_ar_produkten_tillganglig:
            currProductHeader.group.nar_ar_produkten_tillganglig ??
            new Date().toISOString().split("T")[0],
          pris: Number(currProductHeader.group.sell_pris) ?? 0
        },
        productIds: currProductHeader.items.map(item => item.id)
      });
    }
  }

  const result = await postOldOrderAsAdvertisement(oldOrders);

  if (!result.success) return;

  responseModal.value.title = getResponseTextAndTitleByProductStatus("I").title;
  responseModal.value.text = getResponseTextAndTitleByProductStatus("I").text;

  showResponseModal.value = true;
  getProducts();
};

const productGroupMatchesFilterType = (
  filterType: string,
  categories: FilterOptions["categories"]["key"],
  productGroup: ProductGroupSale
) => {
  if (filterType in productGroup) {
    return categories.some(category => {
      if (filterType === "levkod" && category.value === "ex")
        return productGroup[filterType].substring(0, 2) === "ex";
      return (
        productGroup[filterType as keyof ProductGroupSale] === category.value
      );
    });
  }

  return true;
};

const productItemMatchesFilterType = (
  filterType: string,
  categories: FilterOptions["categories"]["key"],
  productItem: ProductItemSale
) => {
  if (filterType in productItem) {
    return categories.some(category => {
      if (filterType === "levkod" && category.value === "ex")
        return productItem[filterType].substring(0, 2) === "ex";
      return (
        String(productItem[filterType as keyof ProductItemSale]) ===
        String(category.value)
      );
    });
  }

  return true;
};

const productMatchesSearchValue = (
  categories: FilterOptions["categories"]["key"],
  productGroup: ProductGroupSale,
  productItem: ProductItemSale | null
) => {
  for (const category of categories) {
    const searchValue = String(category.value).toLowerCase();

    const combined = { ...productGroup, ...(productItem || {}) };

    for (const key in combined) {
      const value = combined[key as keyof typeof combined];
      if (typeof value === "string") {
        if (value.toLowerCase().includes(searchValue)) {
          return true;
        }
      }

      if (productTableProps.translateHeaders.includes(key)) {
        const translatedValue = t(`${key}.${value}`);
        if (
          translatedValue &&
          translatedValue.toLowerCase().includes(searchValue)
        ) {
          return true;
        }
      }
    }
  }

  return false;
};

const productGroupMatchesIntervalAmount = (productGroup: ProductGroupSale) => {
  for (const key in productTableProps.filter.intervals) {
    const interval = productTableProps.filter.intervals[key];

    if (key in productGroup) {
      if (
        "from" in interval &&
        !(interval.from <= Number(productGroup[key as keyof ProductGroupSale]))
      ) {
        return false;
      }
      if (
        "to" in interval &&
        !(interval.to >= Number(productGroup[key as keyof ProductGroupSale]))
      ) {
        return false;
      }
    }
  }
  return true;
};

const toggleSubTable = (productGroup: ProductGroupSale) => {
  selectedProductGroupsForUpdates.value = [];
  selectedProductItemsForUpdates.value = [];
  selectedOrderIds.value = [];
  toggleMainHeaderCheckbox.value = false;
  selectedProducts.value = 0;

  if (selectedProductGroupForShowingItems.value === productGroup) {
    showSubTable.value = !showSubTable.value;
  } else {
    showSubTable.value = true;
    selectedProductGroupForShowingItems.value = productGroup;
  }
  if (!productTableProps.hideCheckboxes) {
    hideHeaderCheckbox.value = showSubTable.value;
  }
};

const handleSelectOrderRow = (
  selectedOrder: OrderProductHeader["key"]
): OrderForUpdate[] => {
  const currentOrdersForUpdate = [...ordersForUpdate.value];

  const currentOrderItems = selectedOrder.group.products.map(
    product => product.article_id
  );
  const productGroupIndexToRemove = currentOrdersForUpdate.findIndex(
    order => order.orderId === Number(selectedOrder.group.order_id)
  );

  currentRoom.value = selectedOrder.items[0].rum;

  if (productGroupIndexToRemove !== -1) {
    currentOrdersForUpdate.splice(productGroupIndexToRemove, 1);

    selectedProductItemsForUpdates.value =
      selectedProductItemsForUpdates.value.filter(
        productId => !currentOrderItems.includes(productId)
      );
  } else {
    currentOrdersForUpdate.push({
      orderId: Number(selectedOrder.group.order_id),
      productId: selectedOrder.items[0].id
    });
    currentOrderItems.forEach(itemId =>
      selectedProductItemsForUpdates.value.push(itemId)
    );
  }

  toggleMainHeaderCheckbox.value =
    currentOrdersForUpdate.length === Object.keys(productHeaders.value).length;
  selectedProducts.value = currentOrdersForUpdate.length;

  return currentOrdersForUpdate;
};

const handleSelectMainRow = (
  selectedProductHeader: ProductHeaderSale["key"]
): ProductGroupSale["green_hash"][] => {
  currentSelectedProductHeader.value = selectedProductHeader;

  const currentSelectedProductGroups = [
    ...selectedProductGroupsForUpdates.value
  ];

  const currentProductItems = selectedProductHeader.items.map(
    productItem => productItem.id
  );

  const productGroupIndexToRemove = currentSelectedProductGroups.findIndex(
    productHashId => productHashId === selectedProductHeader.group.green_hash
  );

  currentRoom.value = selectedProductHeader.items[0].rum;

  if (
    productGroupIndexToRemove !== -1 &&
    selectedProductItemsForUpdates.value.some(productId =>
      selectedProductHeader.items.some(item => item.id === productId)
    )
  ) {
    currentSelectedProductGroups.splice(productGroupIndexToRemove, 1);

    selectedProductItemsForUpdates.value =
      selectedProductItemsForUpdates.value.filter(
        productId => !currentProductItems.includes(productId)
      );
  } else {
    currentSelectedProductGroups.push(selectedProductHeader.group.green_hash);
    selectedProductHeader.items.forEach(productItem =>
      selectedProductItemsForUpdates.value.push(productItem.id)
    );
  }

  if (
    productTableProps.tableType !== "internalSold" ||
    !("order_id" in selectedProductHeader.group)
  ) {
    toggleMainHeaderCheckbox.value =
      currentSelectedProductGroups.length ===
      Object.keys(productHeaders.value).length;
    selectedProducts.value = currentSelectedProductGroups.length;
  }

  return currentSelectedProductGroups;
};

const handleSelectSubRow = (
  selectedProductHeader: ProductHeaderSale["key"],
  selectedProductItem: ProductItemSale,
  productItemsAmount: number
): ProductItemSale["id"][] => {
  currentSelectedProductHeader.value = selectedProductHeader;

  const currentSelectedProductItems = [...selectedProductItemsForUpdates.value];

  const productItemIndexToRemove = currentSelectedProductItems.findIndex(
    productId => productId === selectedProductItem.id
  );

  if (productItemIndexToRemove !== -1) {
    currentSelectedProductItems.splice(productItemIndexToRemove, 1);
  } else {
    currentSelectedProductItems.push(selectedProductItem.id);
  }

  toggleSubHeaderCheckbox.value =
    currentSelectedProductItems.length === productItemsAmount;

  selectedProducts.value = currentSelectedProductItems.length;

  return currentSelectedProductItems;
};

const handleSelectAllOrderRows = (
  allProductHeaders: OrderProductHeader,
  isChecked: boolean
): OrderForUpdate[] | [] => {
  if (isChecked) {
    toggleMainHeaderCheckbox.value = true;
    selectedProducts.value = Object.keys(allProductHeaders).length;

    return Object.keys(allProductHeaders).map(productHeaderKey => {
      const productOrder = allProductHeaders[productHeaderKey].group;

      productOrder.products.forEach(product =>
        selectedProductItemsForUpdates.value.push(product.article_id)
      );

      return {
        orderId: productOrder.order_id,
        productId: productOrder.products[0].article_id
      };
    });
  }
  selectedProductItemsForUpdates.value = [];
  toggleMainHeaderCheckbox.value = false;
  selectedProducts.value = 0;
  return [];
};

const handleSelectAllMainRows = (
  allProductHeaders: ProductHeaderSale | AdvertisementProductHeader,
  isChecked: boolean
): ProductGroupSale["green_hash"][] | [] => {
  if (isChecked) {
    toggleMainHeaderCheckbox.value = true;
    selectedProducts.value = 0;

    return Object.keys(allProductHeaders)
      .map(productHeaderKey => allProductHeaders[productHeaderKey])
      .filter(
        currProductHeader =>
          !("orders" in currProductHeader.group) ||
          currProductHeader.group?.orders.length === 0
      )
      .flatMap(currProductHeader => {
        filteredProductItems(currProductHeader).forEach(productItem => {
          selectedProductItemsForUpdates.value.push(productItem.id);
        });
        selectedProducts.value += 1;

        return currProductHeader.group.green_hash;
      });
  }
  selectedProductItemsForUpdates.value = [];
  toggleMainHeaderCheckbox.value = false;
  selectedProducts.value = 0;
  return [];
};

const handleSelectAllSubRows = (
  productHeader: ProductHeaderSale["key"],
  isChecked: boolean
): ProductItemSale["id"][] | [] => {
  currentSelectedProductHeader.value = productHeader;
  const productItems = filteredProductItems(productHeader);
  if (isChecked) {
    toggleSubHeaderCheckbox.value = true;
    selectedProducts.value = productItems.length;

    return productItems.map(productItem => productItem.id);
  }
  toggleSubHeaderCheckbox.value = false;
  selectedProducts.value = 0;
  return [];
};

const changeStatus = async (productStatus: ProductStatuses) => {
  isLoading.value = true;
  const result = await updateStatus(
    selectedProductItemsForUpdates.value,
    productStatus
  );
  isLoading.value = false;

  if (!result.success) return;

  responseModal.value.title =
    getResponseTextAndTitleByProductStatus(productStatus).title;
  responseModal.value.text =
    getResponseTextAndTitleByProductStatus(productStatus).text;

  showResponseModal.value = true;
  getProducts();
};

const getResponseTextAndTitleByProductStatus = (
  productStatus: ProductStatuses
) => {
  let responseText = t("msg.productIsMoved", [
    translatedActions(productStatus)
  ]);
  let responseTitle = t("title.actionChanged");

  switch (productStatus) {
    case "J":
      responseText = `${t("msg.productHasBeenReset2")} ${bolderFont(
        t("title.handleProducts")
      )}`;
      break;
    case "M":
      responseTitle = t("title.actionCompleted");
      responseText = `${t(`list.completedActions.${productStatus}`)}
        ${bolderFont(t("title.repurposeOverview"))}`;

      break;
    case "C":
      responseTitle = t("title.actionCompleted");
      responseText = `${t(
        `list.completedActions.${productStatus}`
      )} ${bolderFont(t("title.repurposeOverview"))}`;
  }
  return { text: responseText, title: responseTitle };
};

const getProducts = async () => {
  isLoading.value = true;

  selectedProductForShowingInformation.value = null;
  selectedProductGroupForShowingItems.value = null;
  ordersForUpdate.value = [];
  selectedProductItemsForUpdates.value = [];
  selectedProductGroupsForUpdates.value = [];
  selectedProducts.value = 0;
  hideHeaderCheckbox.value = false;
  toggleMainHeaderCheckbox.value = false;
  showSubTable.value = false;

  modalType.value = null;

  if (productTableProps.externalProducts) {
    emit("fetchProducts");
    isLoading.value = false;
    return;
  }

  const quantitiesResult = await indexStore.updateQuantities();

  if (quantitiesResult !== "success") {
    isLoading.value = false;
    return;
  }

  getProductHeaders();
};

const getProductHeaders = async () => {
  isLoading.value = true;

  const result = await getProductHeadersApi(productTableProps.presetFilter);

  if (!result.success) {
    isLoading.value = false;
    return;
  }

  productHeaders.value = result.result;
  if (
    !("levkod" in messages.value[locale.value.toLocaleLowerCase()]) ||
    !("kategori_id" in messages.value[locale.value.toLocaleLowerCase()])
  ) {
    const translationResult = await indexStore.getLocaleTranslations(
      indexStore.projectDetails,
      messages.value[locale.value]
    );

    if (translationResult !== "success") {
      isLoading.value = false;
      return;
    }
  }

  selectedProductGroupForShowingItems.value = null;

  indexStore.calculateMaxQuantity(filteredProductHeaders.value);

  isLoading.value = false;
};

const resetToAvailableStatus = async () => {
  modalType.value = null;

  isLoading.value = true;

  const result = await denyInternalOrders(ordersForUpdate.value);

  if (!result.success) {
    isLoading.value = false;
    return;
  }

  responseModal.value.title = t("title.orderHasBeenReset");
  responseModal.value.text = t("paragraph.productsHaveBeenMovedToAvailable");
  responseModal.value.type = "success";
  showResponseModal.value = true;
  getProducts();
  isLoading.value = false;
};

const translatedActions = (productStatus: ProductStatuses | null) => {
  let translatedAction = null;
  if (productStatus === "S") {
    translatedAction = t("title.sell");
  } else if (productStatus === "Å") {
    translatedAction = t("title.repurpose");
  } else if (productStatus === "G") {
    translatedAction = t("button.onGreenifiedWeb");
  } else if (productStatus === "I") {
    translatedAction = t("button.otherSales");
  } else {
    translatedAction = t("list.actionStatusLocation." + productStatus);
  }
  return `<b>${translatedAction}</b>`;
};

onMounted(async () => {
  if (productTableProps.externalProducts)
    return (productHeaders.value =
      productTableProps.externalProducts as ProductHeaderSale);
  getProductHeaders();
});

watch(
  () => productTableProps.sortingSettings,
  newSortingSettings => {
    mainTableSortingOptions.value = newSortingSettings;
    subTableSortingOptions.value = newSortingSettings;
  },
  { deep: true }
);

watch(
  () => selectedProducts.value,
  currSelectedProducts => {
    if (currSelectedProducts > 0) {
      emit("footerIsOpen", true);
      return;
    }

    emit("footerIsOpen", false);
    return;
  }
);

watch(
  () => productTableProps.footerIsViewable,
  isViewable => {
    if (!isViewable) {
      selectedProductGroupsForUpdates.value = [];
      selectedProductItemsForUpdates.value = [];
      toggleMainHeaderCheckbox.value = false;
      selectedProducts.value = 0;
    }
  }
);

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (isLoading.value)
      ? (_openBlock(), _createBlock(LoadingOverlay, { key: 0 }))
      : (_openBlock(), _createElementBlock("div", _hoisted_1, [
          (!Object.keys(filteredProductHeaders.value).length)
            ? (_openBlock(), _createBlock(TableHeaderRow, {
                key: 0,
                tableHeaders: null,
                noRowsText: _ctx.noRowsText
              }, null, 8, ["noRowsText"]))
            : _createCommentVNode("", true),
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(sortedProductHeaderRows)(
        filteredProductHeaders.value,
        mainTableSortingOptions.value
      ), (productHeader, value, index) => {
            return (_openBlock(), _createElementBlock(_Fragment, { key: index }, [
              ('group' in productHeader)
                ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                    (index === 0)
                      ? (_openBlock(), _createBlock(TableHeaderRow, {
                          key: 0,
                          "is-sticky": true,
                          tableHeaders: 
            _unref(getMainTableColumnsByTableType)(productHeader.group, _ctx.tableType)
              .columns
          ,
                          sortingOptions: mainTableSortingOptions.value,
                          "onUpdate:sortingOptions": _cache[0] || (_cache[0] = ($event: any) => ((mainTableSortingOptions).value = $event)),
                          type: "main",
                          hideHeaderCheckbox: hideHeaderCheckbox.value,
                          "onUpdate:toggleCheckbox": [
                            (isChecked) => {
            if ( _ctx.tableType === 'internalSold' && 'order_id' in productHeader.group) {
              ordersForUpdate.value = handleSelectAllOrderRows(filteredProductHeaders.value as OrderProductHeader, isChecked)
            }
            selectedProductGroupsForUpdates.value = handleSelectAllMainRows(filteredProductHeaders.value, isChecked)
            },
                            _cache[1] || (_cache[1] = ($event: any) => ((toggleMainHeaderCheckbox).value = $event))
                          ],
                          toggleCheckbox: toggleMainHeaderCheckbox.value
                        }, null, 8, ["tableHeaders", "sortingOptions", "hideHeaderCheckbox", "onUpdate:toggleCheckbox", "toggleCheckbox"]))
                      : _createCommentVNode("", true),
                    (productHeader.items.length)
                      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                          _createVNode(MainTableRow, {
                            tableColumns: 
              _unref(getMainTableColumnsByTableType)(productHeader.group, _ctx.tableType)
                .columns
            ,
                            rowImage: productHeader.items[0].images?.bild_1?.url ?? '',
                            toggleSubTable: 
              showSubTable.value &&
              selectedProductGroupForShowingItems.value === productHeader.group
            ,
                            showCheckbox: !_ctx.hideCheckboxes && !showSubTable.value ? true : false,
                            onSelectRow: () =>  {
              if ( _ctx.tableType === 'internalSold' && 'order_id' in productHeader.group) {
                ordersForUpdate.value = handleSelectOrderRow(
                  productHeader as OrderProductHeader['key']
                )
              }

              selectedProductGroupsForUpdates.value = handleSelectMainRow(productHeader)
            }
            
            ,
                            onShowSubTable: ($event: any) => (toggleSubTable(productHeader.group)),
                            rowIsChecked: 
              _ctx.tableType === 'internalSold' && 'order_id' in productHeader.group
                ? ordersForUpdate.value.some(
                    order =>
                      order.orderId === Number((productHeader as OrderProductHeader['key']).group.order_id)
                  )
                : selectedProductGroupsForUpdates.value.includes(
                    productHeader.group.green_hash
                  ) &&
                  selectedProductItemsForUpdates.value.some(productId =>
                    productHeader.items.some(item => item.id === productId)
                  )
            ,
                            type: _ctx.tableType,
                            onShowProductInformation: ($event: any) => (
              selectedProductForShowingInformation.value = { ...productHeader }
            ),
                            rowAction: _ctx.rowAction,
                            onPurchaseProduct: ($event: any) => {
              modalType.value = 'internalPurchase';
              productToPurchase.value = { ...productHeader };
            },
                            buttonText: 
              _ctx.presetFilter.status.includes('B') ? 'button.seeProducts' : ''
            ,
                            filteredAmount: 
              productHeader.group.antal !== productHeader.items.length
                ? productHeader.items.length
                : null
            ,
                            onSelectAction: ($event: any) => {
              modalType.value = 'handleInternalOrder';
              productToPurchase.value = { ...productHeader };
            },
                            itemLength: Number(productHeader.group.antal)
                          }, null, 8, ["tableColumns", "rowImage", "toggleSubTable", "showCheckbox", "onSelectRow", "onShowSubTable", "rowIsChecked", "type", "onShowProductInformation", "rowAction", "onPurchaseProduct", "buttonText", "filteredAmount", "onSelectAction", "itemLength"]),
                          (
              selectedProductGroupForShowingItems.value === productHeader.group &&
              showSubTable.value
            )
                            ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                                _cache[29] || (_cache[29] = _createElementVNode("div", { class: "left-frame gr-color" }, null, -1)),
                                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(sortedProductItemRows)(
                productHeader.items,
                subTableSortingOptions.value
              ), (productItem, index) => {
                                  return (_openBlock(), _createElementBlock(_Fragment, { key: index }, [
                                    (index === 0)
                                      ? (_openBlock(), _createBlock(TableHeaderRow, {
                                          key: 0,
                                          tableHeaders: 
                  _unref(getSubTableColumnsByTableType)(
                    productHeader.group,
                    productItem,
                    _ctx.tableType
                  ).columns
                ,
                                          sortingOptions: subTableSortingOptions.value,
                                          "onUpdate:sortingOptions": _cache[2] || (_cache[2] = ($event: any) => ((subTableSortingOptions).value = $event)),
                                          type: "sub",
                                          hideHeaderCheckbox: !_ctx.actionStatuses.length || _ctx.hideCheckboxes,
                                          "onUpdate:toggleCheckbox": [
                                            (isChecked) => 
            selectedProductItemsForUpdates.value = handleSelectAllSubRows(productHeader, isChecked),
                                            _cache[3] || (_cache[3] = ($event: any) => ((toggleSubHeaderCheckbox).value = $event))
                                          ],
                                          toggleCheckbox: toggleSubHeaderCheckbox.value,
                                          onEditAll: ($event: any) => {
                  editProductType.value = 'group';
                  editModalTitle.value = _unref(t)('title.editProducts', [
                    productHeader.group.benamn,
                    productHeader.items.length
                  ]);
                  editProductId.value = productHeader.items[0].id;
                  selectedProductItemsForUpdates.value = productHeader.items.map(
                    productItem => productItem.id
                  );
                  modalType.value = 'edit';
                }
                                        }, null, 8, ["tableHeaders", "sortingOptions", "hideHeaderCheckbox", "onUpdate:toggleCheckbox", "toggleCheckbox", "onEditAll"]))
                                      : _createCommentVNode("", true),
                                    _createVNode(SubTableRow, {
                                      subTableColumns: 
                  _unref(getSubTableColumnsByTableType)(
                    productHeader.group,
                    productItem,
                    _ctx.tableType
                  )
                ,
                                      onSelectSubRow: ($event: any) => (
                  selectedProductItemsForUpdates.value = handleSelectSubRow(
                    productHeader,
                    productItem,
                    productHeader.items.length
                  )
                ),
                                      hideCheckbox: !_ctx.actionStatuses.length || _ctx.hideCheckboxes,
                                      rowIsChecked: 
                  selectedProductItemsForUpdates.value.includes(productItem.id)
                ,
                                      tableType: _ctx.tableType,
                                      onShowHistory: ($event: any) => {
                  modalType.value = 'history';
                  productHistoryId.value = productItem.id;
                },
                                      onShowEdit: ($event: any) => {
                  editProductType.value = 'item';
                  editModalTitle.value = `${_unref(t)('title.editProduct')} - ID: ${
                    productItem.comp_id
                  }`;
                  editProductId.value = productItem.id;
                  modalType.value = 'edit';
                }
                                    }, null, 8, ["subTableColumns", "onSelectSubRow", "hideCheckbox", "rowIsChecked", "tableType", "onShowHistory", "onShowEdit"])
                                  ], 64))
                                }), 128)),
                                _cache[30] || (_cache[30] = _createElementVNode("div", { class: "right-frame gr-color" }, null, -1))
                              ]))
                            : _createCommentVNode("", true)
                        ]))
                      : _createCommentVNode("", true)
                  ], 64))
                : _createCommentVNode("", true)
            ], 64))
          }), 128))
        ])),
    (selectedProducts.value > 0 && !isLoading.value && _ctx.footerIsViewable)
      ? (_openBlock(), _createBlock(TableFooter, {
          key: 2,
          footerText: 
      _unref(t)('label.productsSelected', {
        count: selectedProducts.value
      })
    ,
          actionStatuses: _ctx.actionStatuses,
          onAction: _cache[4] || (_cache[4] = (selectedAction) => {
      if (selectedAction.action === 'changeStatus') {
        changeStatus(selectedAction.status as ProductStatuses)
        return
      }
      modalType.value = selectedAction.action
    }),
          toggleDisabledButtons: selectedProductGroupsForUpdates.value.length > 1,
          toggleDisableCombineProductsButton: 
      selectedProductGroupsForUpdates.value.length < 2
    
        }, null, 8, ["footerText", "actionStatuses", "toggleDisabledButtons", "toggleDisableCombineProductsButton"]))
      : _createCommentVNode("", true),
    (modalType.value === 'combineProducts')
      ? (_openBlock(), _createBlock(CombineProductsModal, {
          key: 3,
          selectedProductGroups: selectedProductGroupsForUpdates.value,
          productHeaders: filteredProductHeaders.value,
          productItems: selectedProductItemsForUpdates.value,
          onClose: _cache[5] || (_cache[5] = ($event: any) => (modalType.value = null)),
          onRefresh: _cache[6] || (_cache[6] = ($event: any) => {
      getProducts();
      modalType.value = null;
    })
        }, null, 8, ["selectedProductGroups", "productHeaders", "productItems"]))
      : _createCommentVNode("", true),
    (modalType.value === 'history')
      ? (_openBlock(), _createBlock(HistoryModal, {
          key: 4,
          productId: productHistoryId.value,
          onClose: _cache[7] || (_cache[7] = ($event: any) => (modalType.value = null))
        }, null, 8, ["productId"]))
      : _createCommentVNode("", true),
    (modalType.value === 'edit')
      ? (_openBlock(), _createBlock(EditProductModal, {
          key: 5,
          onClose: _cache[8] || (_cache[8] = ($event: any) => (modalType.value = null)),
          onRefresh: _cache[9] || (_cache[9] = ($event: any) => (getProducts())),
          productType: editProductType.value,
          modalTitle: editModalTitle.value,
          productId: editProductId.value,
          productStatus: _ctx.presetFilter.status[0],
          productIds: selectedProductItemsForUpdates.value
        }, null, 8, ["productType", "modalTitle", "productId", "productStatus", "productIds"]))
      : _createCommentVNode("", true),
    (modalType.value === 'move' || modalType.value === 'toRoom')
      ? (_openBlock(), _createBlock(MoveProductsModal, {
          key: 6,
          productItemIds: selectedProductItemsForUpdates.value,
          statuses: _ctx.presetFilter.status,
          changeLocation: _ctx.tableType === 'recondition' ? false : true,
          onClose: _cache[10] || (_cache[10] = ($event: any) => (modalType.value = null)),
          onMoved: _cache[11] || (_cache[11] = ($event: any) => (getProducts()))
        }, null, 8, ["productItemIds", "statuses", "changeLocation"]))
      : _createCommentVNode("", true),
    (showResponseModal.value)
      ? (_openBlock(), _createBlock(GreenifiedModal, {
          key: 7,
          title: responseModal.value.title,
          text: responseModal.value.text,
          type: 'response',
          closable: false,
          onClose: _cache[12] || (_cache[12] = ($event: any) => (showResponseModal.value = false))
        }, null, 8, ["title", "text"]))
      : _createCommentVNode("", true),
    (selectedProductForShowingInformation.value)
      ? (_openBlock(), _createBlock(ProductGroupInformation, {
          key: 8,
          onUpdate: _cache[13] || (_cache[13] = ($event: any) => {
      selectedProductForShowingInformation.value = null;
      getProducts();
    }),
          onClose: _cache[14] || (_cache[14] = ($event: any) => (selectedProductForShowingInformation.value = null)),
          productId: selectedProductForShowingInformation.value.items[0].id,
          productName: selectedProductForShowingInformation.value.group.benamn,
          supplierCode: selectedProductForShowingInformation.value.group.levkod
        }, null, 8, ["productId", "productName", "supplierCode"]))
      : _createCommentVNode("", true),
    (modalType.value === 'renovation' || modalType.value === 'keepCondition')
      ? (_openBlock(), _createBlock(ProductRenovationForm, {
          key: 9,
          onCloseModal: _cache[15] || (_cache[15] = ($event: any) => (modalType.value = null)),
          onRefresh: _cache[16] || (_cache[16] = ($event: any) => (getProducts())),
          moveTitle: 
      modalType.value === 'renovation'
        ? _unref(t)('label.moveProductRenovation')
        : _unref(t)('label.moveKeepCondition')
    ,
          moveText: 
      modalType.value === 'renovation'
        ? _unref(t)('paragraph.moveRecondtionForm')
        : _unref(t)('paragraph.moveKeepConditionForm')
    ,
          noRecondition: modalType.value === 'keepCondition',
          isValidated: true,
          productIds: selectedProductItemsForUpdates.value,
          title: 
      modalType.value === 'renovation'
        ? _unref(t)('title.selectRecondition')
        : _unref(t)('label.moveKeepCondition')
    
        }, null, 8, ["moveTitle", "moveText", "noRecondition", "productIds", "title"]))
      : _createCommentVNode("", true),
    (modalType.value === 'changeAction')
      ? (_openBlock(), _createBlock(ChangeActionModal, {
          key: 10,
          actions: _ctx.presetFilter.status,
          onOpenAction: _cache[17] || (_cache[17] = 
          (productStatus) => {
            changeStatus(productStatus);
          }
        ),
          onClose: _cache[18] || (_cache[18] = ($event: any) => (modalType.value = null))
        }, null, 8, ["actions"]))
      : _createCommentVNode("", true),
    (modalType.value === 'internalSale' || modalType.value === 'editInternalSale')
      ? (_openBlock(), _createBlock(InternalSaleForm, {
          key: 11,
          onClose: _cache[19] || (_cache[19] = ($event: any) => (modalType.value = null)),
          onRefresh: _cache[20] || (_cache[20] = ($event: any) => (getProducts())),
          type: modalType.value,
          selectedProduct: currentSelectedProductHeader.value,
          selectedProductIds: selectedProductItemsForUpdates.value
        }, null, 8, ["type", "selectedProduct", "selectedProductIds"]))
      : _createCommentVNode("", true),
    (modalType.value === 'internalPurchase' && productToPurchase.value)
      ? (_openBlock(), _createBlock(InternalPurchaseOrder, {
          key: 12,
          onClose: _cache[21] || (_cache[21] = ($event: any) => (modalType.value = null)),
          onRefresh: _cache[22] || (_cache[22] = ($event: any) => (getProducts())),
          productToPurchase: productToPurchase.value as AdvertisementProductHeader['key']
        }, null, 8, ["productToPurchase"]))
      : _createCommentVNode("", true),
    (modalType.value === 'handleInternalOrder' && productToPurchase.value)
      ? (_openBlock(), _createBlock(InternalPurchaseOrderConfirmation, {
          key: 13,
          onClose: _cache[23] || (_cache[23] = ($event: any) => {
      modalType.value = null;
      productToPurchase.value = null;
    }),
          onRefresh: _cache[24] || (_cache[24] = ($event: any) => {
      emit('fetchProducts');
      modalType.value = null;
      productToPurchase.value = null;
    }),
          productToPurchase: productToPurchase.value as OrderProductHeader['key']
        }, null, 8, ["productToPurchase"]))
      : _createCommentVNode("", true),
    (modalType.value === 'availableInternal' || modalType.value === 'soldInternal')
      ? (_openBlock(), _createBlock(GreenifiedModal, {
          key: 14,
          type: "verification",
          closable: false,
          title: _unref(t)('label.moveBackToAvailable').replace(/^.*?:\s*/, ''),
          text: _unref(t)('paragraph.verifyProductMoveToAvailable'),
          onClose: _cache[25] || (_cache[25] = ($event: any) => (modalType.value = null)),
          onConfirm: _cache[26] || (_cache[26] = ($event: any) => (
      modalType.value === 'availableInternal'
        ? resetToAvailableStatus()
        : postOldOrderToNewAdvertisement()
    ))
        }, null, 8, ["title", "text"]))
      : _createCommentVNode("", true),
    (modalType.value === 'greenifiedSale')
      ? (_openBlock(), _createBlock(GreenifiedSaleForm, {
          key: 15,
          onClose: _cache[27] || (_cache[27] = ($event: any) => (modalType.value = null)),
          onRefresh: _cache[28] || (_cache[28] = ($event: any) => (getProducts())),
          productIds: selectedProductItemsForUpdates.value
        }, null, 8, ["productIds"]))
      : _createCommentVNode("", true)
  ], 64))
}
}

})