import { defineComponent as _defineComponent } from 'vue'
import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, normalizeProps as _normalizeProps, guardReactiveProps as _guardReactiveProps, unref as _unref, normalizeClass as _normalizeClass, withCtx as _withCtx, createVNode as _createVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"
import _imports_0 from '@/assets/img/drop-arrow.svg'


const _hoisted_1 = {
  key: 0,
  class: "position-relative"
}
const _hoisted_2 = {
  key: 0,
  class: "error-wrapper"
}
const _hoisted_3 = { class: "text-tiny emphasis" }
const _hoisted_4 = {
  key: 1,
  class: "loader-spinner-wrapper"
}
const _hoisted_5 = {
  key: 1,
  class: "position-relative"
}
const _hoisted_6 = {
  key: 0,
  class: "error-wrapper"
}
const _hoisted_7 = { class: "text-tiny emphasis" }
const _hoisted_8 = {
  key: 1,
  class: "loader-spinner-wrapper"
}

import { SelectableAction } from "@/library/helpers/structureTableColumns";
import { RoomObject } from "@/library/types/room/rooms";
import { ref, h, watch } from "vue";
// eslint-disable-next-line @typescript-eslint/no-unused-vars, no-unused-vars
import vSelect, { VueSelectSlotScope } from "vue-select";
import LoaderSpinner from "./LoaderSpinner.vue";
export type LocalVueSelectOption = string | object;

export type FilterByOption = LocalVueSelectOption | RoomObject;

type LocalFilterBy = (
  // eslint-disable-next-line no-unused-vars
  option: FilterByOption,
  // eslint-disable-next-line no-unused-vars
  label: string,
  // eslint-disable-next-line no-unused-vars
  search: string
) => boolean;

type CustomSelectProps = {
  customClass?: string;
  options: LocalVueSelectOption[];
  modelValue?: LocalVueSelectOption | number | null;
  label?: string;
  reducedKey?: string | null;
  filterBy?: LocalFilterBy;
  value?: LocalVueSelectOption | null;
  placeholder?: string;
  appendToBody?: boolean;
  clearable?: boolean;
  required?: boolean;
  id?: string;
  name?: string;
  closeOnSelect?: boolean;
  keepPlaceholder?: boolean;
  showError?: boolean;
  errorText?: string;
  hasSelectableOptions?: boolean;
  isLoading?: boolean;
};


export default /*@__PURE__*/_defineComponent({
  __name: 'CustomSelect',
  props: {
    customClass: { default: "" },
    options: { default: () => [] },
    modelValue: { default: null },
    label: { default: "" },
    reducedKey: { default: null },
    filterBy: { type: Function, default: () => false },
    value: { default: null },
    placeholder: { default: "" },
    appendToBody: { type: Boolean, default: true },
    clearable: { type: Boolean, default: false },
    required: { type: Boolean, default: false },
    id: { default: "" },
    name: { default: "" },
    closeOnSelect: { type: Boolean, default: true },
    keepPlaceholder: { type: Boolean, default: false },
    showError: { type: Boolean, default: false },
    errorText: { default: "" },
    hasSelectableOptions: { type: Boolean, default: false },
    isLoading: { type: Boolean, default: false }
  },
  emits: ["update:modelValue"],
  setup(__props: any, { emit: __emit }) {

const customSelectProps = __props;
const emit = __emit;

const Deselect = {
  render: () =>
    h("img", {
      src: require("@/assets/img/remove.svg")
    })
};

const localModelValue = ref(
  customSelectProps.keepPlaceholder
    ? customSelectProps.placeholder
    : customSelectProps.modelValue ?? customSelectProps.value
);

watch(
  () => localModelValue.value,
  newValue => {
    if ((typeof newValue === "string" && !newValue.length) || !newValue) {
      emit("update:modelValue", null);
    }
  }
);

return (_ctx: any,_cache: any) => {
  return (!_ctx.hasSelectableOptions)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createVNode(_unref(vSelect), {
          reduce: (option) => _ctx.reducedKey && typeof option !== 'string'  ? option[_ctx.reducedKey] : option,
          class: _normalizeClass([_ctx.customClass, _ctx.showError && 'warning']),
          filterBy: _ctx.filterBy,
          options: _ctx.options,
          components: { Deselect: Deselect },
          label: _ctx.label,
          modelValue: localModelValue.value,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((localModelValue).value = $event)),
          value: _ctx.value,
          "onOption:selected": _cache[1] || (_cache[1] = (option) => {
      if (_ctx.keepPlaceholder) localModelValue.value = _ctx.placeholder
      emit('update:modelValue',  _ctx.reducedKey && typeof option !== 'string' ? option[_ctx.reducedKey] : option)
      }),
          clearable: _ctx.clearable,
          placeholder: _ctx.isLoading ? '' : _ctx.placeholder,
          "append-to-body": _ctx.appendToBody,
          required: _ctx.required,
          id: _ctx.id,
          name: _ctx.name,
          closeOnSelect: _ctx.closeOnSelect
        }, {
          "selected-option": _withCtx(() => [
            _renderSlot(_ctx.$slots, "selected-option")
          ]),
          "open-indicator": _withCtx(({ attributes }: { attributes: VueSelectSlotScope }) => [
            _createElementVNode("span", _normalizeProps(_guardReactiveProps(attributes)), _cache[4] || (_cache[4] = [
              _createElementVNode("img", { src: _imports_0 }, null, -1)
            ]), 16)
          ]),
          "list-header": _withCtx(() => [
            _renderSlot(_ctx.$slots, "list-header")
          ]),
          option: _withCtx((option) => [
            _renderSlot(_ctx.$slots, "option", { option: option })
          ]),
          "list-footer": _withCtx(() => [
            _renderSlot(_ctx.$slots, "list-footer")
          ]),
          _: 3
        }, 8, ["reduce", "class", "filterBy", "options", "components", "label", "modelValue", "value", "clearable", "placeholder", "append-to-body", "required", "id", "name", "closeOnSelect"]),
        (_ctx.showError)
          ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
              _createElementVNode("p", _hoisted_3, _toDisplayString(_ctx.errorText), 1)
            ]))
          : _createCommentVNode("", true),
        (_ctx.isLoading)
          ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
              _createVNode(LoaderSpinner)
            ]))
          : _createCommentVNode("", true)
      ]))
    : (_openBlock(), _createElementBlock("div", _hoisted_5, [
        _createVNode(_unref(vSelect), {
          reduce: (option) => _ctx.reducedKey && typeof option !== 'string'  ? option[_ctx.reducedKey as keyof SelectableAction] : option,
          class: _normalizeClass([_ctx.customClass, _ctx.showError && 'warning']),
          filterBy: _ctx.filterBy,
          options: _ctx.options,
          components: { Deselect: Deselect },
          label: _ctx.label,
          modelValue: localModelValue.value,
          "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((localModelValue).value = $event)),
          value: _ctx.value,
          selectable: (option) => option.selectable,
          "onOption:selected": _cache[3] || (_cache[3] = (option) => {
      if (_ctx.keepPlaceholder) localModelValue.value = _ctx.placeholder
      emit('update:modelValue',  _ctx.reducedKey && typeof option !== 'string' ? option[_ctx.reducedKey  as keyof SelectableAction] : option)
      }),
          clearable: _ctx.clearable,
          placeholder: _ctx.isLoading ? '' : _ctx.placeholder,
          "append-to-body": _ctx.appendToBody,
          required: _ctx.required,
          id: _ctx.id,
          name: _ctx.name,
          closeOnSelect: _ctx.closeOnSelect
        }, {
          "selected-option": _withCtx(() => [
            _renderSlot(_ctx.$slots, "selected-option")
          ]),
          "open-indicator": _withCtx(({ attributes }: { attributes: VueSelectSlotScope }) => [
            _createElementVNode("span", _normalizeProps(_guardReactiveProps(attributes)), _cache[5] || (_cache[5] = [
              _createElementVNode("img", { src: _imports_0 }, null, -1)
            ]), 16)
          ]),
          "list-header": _withCtx(() => [
            _renderSlot(_ctx.$slots, "list-header")
          ]),
          option: _withCtx((option) => [
            _renderSlot(_ctx.$slots, "option", { option: option })
          ]),
          "list-footer": _withCtx(() => [
            _renderSlot(_ctx.$slots, "list-footer")
          ]),
          _: 3
        }, 8, ["reduce", "class", "filterBy", "options", "components", "label", "modelValue", "value", "selectable", "clearable", "placeholder", "append-to-body", "required", "id", "name", "closeOnSelect"]),
        (_ctx.showError)
          ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
              _createElementVNode("p", _hoisted_7, _toDisplayString(_ctx.errorText), 1)
            ]))
          : _createCommentVNode("", true),
        (_ctx.isLoading)
          ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
              _createVNode(LoaderSpinner)
            ]))
          : _createCommentVNode("", true)
      ]))
}
}

})