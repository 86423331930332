<template>
  <LoadingOverlay v-if="isLoading" />

  <div class="pb-4 position-relative" v-else>
    <TableHeaderRow
      v-if="!Object.keys(filteredProductHeaders).length"
      :tableHeaders="null"
      :noRowsText="noRowsText"
    />

    <template
      v-for="(productHeader, value, index) in sortedProductHeaderRows(
        filteredProductHeaders,
        mainTableSortingOptions
      )"
      :key="index"
    >
      <template v-if="'group' in productHeader">
        <TableHeaderRow
          v-if="index === 0"
          :is-sticky="true"
          :tableHeaders="
            getMainTableColumnsByTableType(productHeader.group, tableType)
              .columns
          "
          v-model:sortingOptions="mainTableSortingOptions"
          type="main"
          :hideHeaderCheckbox="hideHeaderCheckbox"
          @update:toggleCheckbox="(isChecked: boolean) => {
            if ( tableType === 'internalSold' && 'order_id' in productHeader.group) {
              ordersForUpdate = handleSelectAllOrderRows(filteredProductHeaders as OrderProductHeader, isChecked)
            }
            selectedProductGroupsForUpdates = handleSelectAllMainRows(filteredProductHeaders, isChecked)
            }"
          v-model:toggleCheckbox="toggleMainHeaderCheckbox"
        />

        <div class="border-bottom" v-if="productHeader.items.length">
          <MainTableRow
            :tableColumns="
              getMainTableColumnsByTableType(productHeader.group, tableType)
                .columns
            "
            :rowImage="productHeader.items[0].images?.bild_1?.url ?? ''"
            :toggleSubTable="
              showSubTable &&
              selectedProductGroupForShowingItems === productHeader.group
            "
            :showCheckbox="!hideCheckboxes && !showSubTable ? true : false"
            @selectRow="() =>  {
              if ( tableType === 'internalSold' && 'order_id' in productHeader.group) {
                ordersForUpdate = handleSelectOrderRow(
                  productHeader as OrderProductHeader['key']
                )
              }

              selectedProductGroupsForUpdates = handleSelectMainRow(productHeader)
            }
            
            "
            @showSubTable="toggleSubTable(productHeader.group)"
            :rowIsChecked="
              tableType === 'internalSold' && 'order_id' in productHeader.group
                ? ordersForUpdate.some(
                    order =>
                      order.orderId === Number((productHeader as OrderProductHeader['key']).group.order_id)
                  )
                : selectedProductGroupsForUpdates.includes(
                    productHeader.group.green_hash
                  ) &&
                  selectedProductItemsForUpdates.some(productId =>
                    productHeader.items.some(item => item.id === productId)
                  )
            "
            :type="tableType"
            @showProductInformation="
              selectedProductForShowingInformation = { ...productHeader }
            "
            :rowAction="rowAction"
            @purchaseProduct="
              modalType = 'internalPurchase';
              productToPurchase = { ...productHeader };
            "
            :buttonText="
              presetFilter.status.includes('B') ? 'button.seeProducts' : ''
            "
            :filteredAmount="
              productHeader.group.antal !== productHeader.items.length
                ? productHeader.items.length
                : null
            "
            @selectAction="
              modalType = 'handleInternalOrder';
              productToPurchase = { ...productHeader };
            "
            :itemLength="Number(productHeader.group.antal)"
          />

          <div
            class="gr-color subtable position-relative mb-4"
            v-if="
              selectedProductGroupForShowingItems === productHeader.group &&
              showSubTable
            "
          >
            <div class="left-frame gr-color"></div>
            <template
              v-for="(productItem, index) in sortedProductItemRows(
                productHeader.items,
                subTableSortingOptions
              )"
              :key="index"
            >
              <TableHeaderRow
                v-if="index === 0"
                :tableHeaders="
                  getSubTableColumnsByTableType(
                    productHeader.group,
                    productItem,
                    tableType
                  ).columns
                "
                v-model:sortingOptions="subTableSortingOptions"
                type="sub"
                :hideHeaderCheckbox="!actionStatuses.length || hideCheckboxes"
                @update:toggleCheckbox="(isChecked: boolean) => 
            selectedProductItemsForUpdates = handleSelectAllSubRows(productHeader, isChecked)"
                v-model:toggleCheckbox="toggleSubHeaderCheckbox"
                @editAll="
                  editProductType = 'group';
                  editModalTitle = t('title.editProducts', [
                    productHeader.group.benamn,
                    productHeader.items.length
                  ]);
                  editProductId = productHeader.items[0].id;
                  selectedProductItemsForUpdates = productHeader.items.map(
                    productItem => productItem.id
                  );
                  modalType = 'edit';
                "
              />
              <SubTableRow
                :subTableColumns="
                  getSubTableColumnsByTableType(
                    productHeader.group,
                    productItem,
                    tableType
                  )
                "
                @selectSubRow="
                  selectedProductItemsForUpdates = handleSelectSubRow(
                    productHeader,
                    productItem,
                    productHeader.items.length
                  )
                "
                :hideCheckbox="!actionStatuses.length || hideCheckboxes"
                :rowIsChecked="
                  selectedProductItemsForUpdates.includes(productItem.id)
                "
                :tableType="tableType"
                @showHistory="
                  modalType = 'history';
                  productHistoryId = productItem.id;
                "
                @showEdit="
                  editProductType = 'item';
                  editModalTitle = `${t('title.editProduct')} - ID: ${
                    productItem.comp_id
                  }`;
                  editProductId = productItem.id;
                  modalType = 'edit';
                "
              />
            </template>
            <div class="right-frame gr-color"></div>
          </div>
        </div>
      </template>
    </template>
  </div>
  <TableFooter
    v-if="selectedProducts > 0 && !isLoading && footerIsViewable"
    :footerText="
      t('label.productsSelected', {
        count: selectedProducts
      })
    "
    :actionStatuses="actionStatuses"
    @action="(selectedAction: TableFooterButton) => {
      if (selectedAction.action === 'changeStatus') {
        changeStatus(selectedAction.status as ProductStatuses)
        return
      }
      modalType = selectedAction.action
    }"
    :toggleDisabledButtons="selectedProductGroupsForUpdates.length > 1"
    :toggleDisableCombineProductsButton="
      selectedProductGroupsForUpdates.length < 2
    "
  />

  <CombineProductsModal
    v-if="modalType === 'combineProducts'"
    :selectedProductGroups="selectedProductGroupsForUpdates"
    :productHeaders="filteredProductHeaders"
    :productItems="selectedProductItemsForUpdates"
    @close="modalType = null"
    @refresh="
      getProducts();
      modalType = null;
    "
  />

  <HistoryModal
    v-if="modalType === 'history'"
    :productId="productHistoryId"
    @close="modalType = null"
  />

  <EditProductModal
    v-if="modalType === 'edit'"
    @close="modalType = null"
    @refresh="getProducts()"
    :productType="editProductType"
    :modalTitle="editModalTitle"
    :productId="editProductId"
    :productStatus="presetFilter.status[0]"
    :productIds="selectedProductItemsForUpdates"
  />

  <MoveProductsModal
    v-if="modalType === 'move' || modalType === 'toRoom'"
    :productItemIds="selectedProductItemsForUpdates"
    :statuses="presetFilter.status"
    :changeLocation="tableType === 'recondition' ? false : true"
    @close="modalType = null"
    @moved="getProducts()"
  />
  <GreenifiedModal
    v-if="showResponseModal"
    :title="responseModal.title"
    :text="responseModal.text"
    :type="'response'"
    :closable="false"
    @close="showResponseModal = false"
  />

  <ProductGroupInformation
    v-if="selectedProductForShowingInformation"
    @update="
      selectedProductForShowingInformation = null;
      getProducts();
    "
    @close="selectedProductForShowingInformation = null"
    :productId="selectedProductForShowingInformation.items[0].id"
    :productName="selectedProductForShowingInformation.group.benamn"
    :supplierCode="selectedProductForShowingInformation.group.levkod"
  />

  <ProductRenovationForm
    v-if="modalType === 'renovation' || modalType === 'keepCondition'"
    @closeModal="modalType = null"
    @refresh="getProducts()"
    :moveTitle="
      modalType === 'renovation'
        ? t('label.moveProductRenovation')
        : t('label.moveKeepCondition')
    "
    :moveText="
      modalType === 'renovation'
        ? t('paragraph.moveRecondtionForm')
        : t('paragraph.moveKeepConditionForm')
    "
    :noRecondition="modalType === 'keepCondition'"
    :isValidated="true"
    :productIds="selectedProductItemsForUpdates"
    :title="
      modalType === 'renovation'
        ? t('title.selectRecondition')
        : t('label.moveKeepCondition')
    "
  />

  <ChangeActionModal
    v-if="modalType === 'changeAction'"
    :actions="presetFilter.status"
    @openAction="
          (productStatus: ProductStatuses) => {
            changeStatus(productStatus);
          }
        "
    @close="modalType = null"
  />

  <InternalSaleForm
    v-if="modalType === 'internalSale' || modalType === 'editInternalSale'"
    @close="modalType = null"
    @refresh="getProducts()"
    :type="modalType"
    :selectedProduct="currentSelectedProductHeader"
    :selectedProductIds="selectedProductItemsForUpdates"
  />

  <InternalPurchaseOrder
    v-if="modalType === 'internalPurchase' && productToPurchase"
    @close="modalType = null"
    @refresh="getProducts()"
    :productToPurchase="productToPurchase as AdvertisementProductHeader['key']"
  />

  <InternalPurchaseOrderConfirmation
    v-if="modalType === 'handleInternalOrder' && productToPurchase"
    @close="
      modalType = null;
      productToPurchase = null;
    "
    @refresh="
      emit('fetchProducts');
      modalType = null;
      productToPurchase = null;
    "
    :productToPurchase="productToPurchase as OrderProductHeader['key']"
  />

  <GreenifiedModal
    v-if="modalType === 'availableInternal' || modalType === 'soldInternal'"
    type="verification"
    :closable="false"
    :title="t('label.moveBackToAvailable').replace(/^.*?:\s*/, '')"
    :text="t('paragraph.verifyProductMoveToAvailable')"
    @close="modalType = null"
    @confirm="
      modalType === 'availableInternal'
        ? resetToAvailableStatus()
        : postOldOrderToNewAdvertisement()
    "
  />

  <GreenifiedSaleForm
    v-if="modalType === 'greenifiedSale'"
    @close="modalType = null"
    @refresh="getProducts()"
    :productIds="selectedProductItemsForUpdates"
  />
</template>

<script lang="ts" setup>
import {
  OrderGroupSale,
  ProductGroupSale
} from "@/library/types/product/productGroup";
import {
  AdvertisementProductHeader,
  OrderProductHeader,
  ProductHeaderSale,
  ProductStatuses
} from "@/library/types/product/productHeader";
import {
  withDefaults,
  defineProps,
  ref,
  Ref,
  onMounted,
  watch,
  defineEmits,
  computed
} from "vue";
import TableFooter from "./tableFooter/TableFooter.vue";
import { ProductItemSale } from "@/library/types/product/productItem";
import SubTableRow from "./tableRows/SubTableRow.vue";
import TableHeaderRow from "./tableHeader/TableHeaderRow.vue";
import MainTableRow from "./tableRows/MainTableRow.vue";
import {
  getMainTableColumnsByTableType,
  getSubTableColumnsByTableType
} from "@/library/helpers/structureTableColumns";
import { ColumnActions, TableTypes } from "@/library/types/tables/columns";
import {
  sortedProductHeaderRows,
  sortedProductItemRows
} from "@/library/helpers/sortTableRows";
import HistoryModal from "../modal/HistoryModal.vue";
import EditProductModal from "../modal/EditProductModal.vue";
import { useI18n } from "vue-i18n";
import { Actions, ActionStatus } from "@/library/types/actions/actions";
// eslint-disable-next-line @typescript-eslint/no-unused-vars, no-unused-vars
import { TableFooterButton } from "@/library/types/tables/footer";
import MoveProductsModal from "../modal/MoveProductsModal.vue";
import { getProductHeadersApi, updateStatus } from "@/library/api/products";
import { bolderFont } from "@/library/helpers/typography";
import GreenifiedModal from "../modal/GreenifiedModal.vue";
import LoadingOverlay from "../common/LoadingOverlay.vue";
import { useIndexStore } from "@/store";
import { FilterOptions, PresetFilter } from "@/library/types/filter/filter";
import { SortingSettings } from "@/library/types/sorting/sorting";
import ProductGroupInformation from "../ProductGroupInformation.vue";
import ProductRenovationForm from "../form/ProductRenovationForm.vue";
import ChangeActionModal from "../modal/ChangeActionModal.vue";
import InternalSaleForm from "../form/InternalSaleForm.vue";
import GreenifiedSaleForm from "../form/GreenifiedSaleForm.vue";
import InternalPurchaseOrder from "../form/InternalPurchaseOrder.vue";
import InternalPurchaseOrderConfirmation from "../form/InternalPurchaseOrderConfirmation.vue";
import {
  denyInternalOrders,
  postOldOrderAsAdvertisement
} from "@/library/api/sales";
import CombineProductsModal from "../modal/CombineProductsModal.vue";
import {
  OldOrdersAsAdvertisement,
  OrderForUpdate
} from "@/services/api/salesApi";
import { InternalProductHeaders } from "@/services/api/productsApi";

type ResponseModalObject = {
  type: "success" | "fail" | null;
  title: string;
  text: string;
};

type ProductTableProps = {
  tableType?: TableTypes;
  actionStatuses?: ActionStatus[];
  presetFilter: PresetFilter;
  filter: FilterOptions;
  sortingSettings: SortingSettings;
  translateHeaders: string[];
  noRowsText?: string;
  rowAction?: ColumnActions[];
  externalProducts?: ProductHeaderSale | InternalProductHeaders | null;
  hideCheckboxes?: boolean;
  footerIsViewable?: boolean;
};

const productTableProps = withDefaults(defineProps<ProductTableProps>(), {
  tableType: "standard",
  actionStatuses: () => [],
  presetFilter: () => ({
    filter: [],
    group: ["benamn", "levkod", "besk", "kategori_id", "green_hash"],
    status: ["J"]
  }),
  filter: () => ({
    categories: {},
    intervals: {}
  }),
  sortingSettings: () => ({
    col: "updated_at",
    asc: false
  }),
  translateHeaders: () => [],
  noRowsText: "",
  rowAction: () => ["handleVariant"],
  externalProducts: null,
  hideCheckboxes: false,
  footerIsViewable: true
});

const emit = defineEmits(["fetchProducts", "footerIsOpen", "update:filter"]);
const { t, messages, locale } = useI18n();

const indexStore = useIndexStore();
const selectedProductForShowingInformation: Ref<
  ProductHeaderSale["key"] | null
> = ref(null);
const mainTableSortingOptions = ref(productTableProps.sortingSettings);
const subTableSortingOptions = ref(productTableProps.sortingSettings);

const responseModal: Ref<ResponseModalObject> = ref({
  type: null,
  title: "",
  text: ""
});
const showResponseModal = ref(false);
const productToPurchase: Ref<ProductHeaderSale["key"] | null> = ref(null);
const productHeaders: Ref<ProductHeaderSale> = ref({});
const editProductId = ref("");
const currentSelectedProductHeader: Ref<ProductHeaderSale["key"] | null> =
  ref(null);
const editModalTitle = ref("");
const editProductType: Ref<"item" | "group"> = ref("group");
const showSubTable = ref(false);
const productHistoryId = ref("");
const hideHeaderCheckbox = ref(productTableProps.hideCheckboxes);
const toggleMainHeaderCheckbox = ref(false);
const toggleSubHeaderCheckbox = ref(false);
const selectedProducts = ref(0);
const currentRoom: Ref<string | null> = ref(null);
const modalType: Ref<Actions | null> = ref(null);
const isLoading = ref(false);

const selectedProductGroupForShowingItems: Ref<ProductGroupSale | null> =
  ref(null);
const selectedProductGroupsForUpdates: Ref<ProductGroupSale["green_hash"][]> =
  ref([]);
const selectedOrderIds: Ref<OrderGroupSale["order_id"][]> = ref([]);
const ordersForUpdate: Ref<OrderForUpdate[]> = ref([]);
const selectedProductItemsForUpdates: Ref<ProductItemSale["id"][]> = ref([]);

const filteredProductHeaders = computed(() => {
  const currFilteredProductHeaders: ProductHeaderSale = {};

  for (const key in productHeaders.value) {
    const currentProductHeader = productHeaders.value[key];

    const isGroupMatching = checkFilter(null, currentProductHeader.group);
    const matchingItems = filteredProductItems(currentProductHeader);
    const hasMatchingItems = matchingItems.length > 0;

    if (isGroupMatching || hasMatchingItems) {
      currFilteredProductHeaders[key] = {
        ...currentProductHeader,
        items: matchingItems
      };
    }
  }

  if (Object.keys(productTableProps.filter.categories).length) {
    indexStore.calculateMaxQuantity(currFilteredProductHeaders);
  } else {
    indexStore.calculateMaxQuantity(productHeaders.value);
  }

  return currFilteredProductHeaders;
});

const filteredProductItems = (productHeader: ProductHeaderSale["key"]) => {
  return productHeader.items.filter(productItem =>
    checkFilter(productItem, productHeader.group)
  );
};
const checkFilter = (
  productItem: ProductItemSale | null,
  productGroup: ProductGroupSale
) => {
  const filterCategories = { ...productTableProps.filter.categories };

  if (
    !Object.keys(filterCategories).length &&
    !Object.keys(productTableProps.filter.intervals).length
  ) {
    return true;
  }

  if (!productGroupMatchesIntervalAmount(productGroup)) {
    return false;
  }

  for (const key in filterCategories) {
    const categories = filterCategories[key];
    const filterType = key;

    if (
      filterType === "search" &&
      !productMatchesSearchValue(categories, productGroup, productItem)
    ) {
      return false;
    }

    if (filterType !== "search") {
      if (
        productItem &&
        !productItemMatchesFilterType(filterType, categories, productItem)
      ) {
        return false;
      }

      if (
        !productGroupMatchesFilterType(filterType, categories, productGroup)
      ) {
        return false;
      }
    }
  }

  return true;
};

const postOldOrderToNewAdvertisement = async () => {
  const oldOrders: OldOrdersAsAdvertisement[] = [];
  for (const key in filteredProductHeaders.value) {
    const currProductHeader = filteredProductHeaders.value[key];
    if (
      selectedProductGroupsForUpdates.value.includes(
        currProductHeader.group.green_hash
      )
    ) {
      oldOrders.push({
        saleInfo: {
          "ange-antal": Number(currProductHeader.group.sell_antal) ?? null,
          saljs_styckvis_eller_i_grupp:
            currProductHeader.group?.sell_grupp === "Styckvis"
              ? "single"
              : "group",
          nar_ar_produkten_tillganglig:
            currProductHeader.group.nar_ar_produkten_tillganglig ??
            new Date().toISOString().split("T")[0],
          pris: Number(currProductHeader.group.sell_pris) ?? 0
        },
        productIds: currProductHeader.items.map(item => item.id)
      });
    }
  }

  const result = await postOldOrderAsAdvertisement(oldOrders);

  if (!result.success) return;

  responseModal.value.title = getResponseTextAndTitleByProductStatus("I").title;
  responseModal.value.text = getResponseTextAndTitleByProductStatus("I").text;

  showResponseModal.value = true;
  getProducts();
};

const productGroupMatchesFilterType = (
  filterType: string,
  categories: FilterOptions["categories"]["key"],
  productGroup: ProductGroupSale
) => {
  if (filterType in productGroup) {
    return categories.some(category => {
      if (filterType === "levkod" && category.value === "ex")
        return productGroup[filterType].substring(0, 2) === "ex";
      return (
        productGroup[filterType as keyof ProductGroupSale] === category.value
      );
    });
  }

  return true;
};

const productItemMatchesFilterType = (
  filterType: string,
  categories: FilterOptions["categories"]["key"],
  productItem: ProductItemSale
) => {
  if (filterType in productItem) {
    return categories.some(category => {
      if (filterType === "levkod" && category.value === "ex")
        return productItem[filterType].substring(0, 2) === "ex";
      return (
        String(productItem[filterType as keyof ProductItemSale]) ===
        String(category.value)
      );
    });
  }

  return true;
};

const productMatchesSearchValue = (
  categories: FilterOptions["categories"]["key"],
  productGroup: ProductGroupSale,
  productItem: ProductItemSale | null
) => {
  for (const category of categories) {
    const searchValue = String(category.value).toLowerCase();

    const combined = { ...productGroup, ...(productItem || {}) };

    for (const key in combined) {
      const value = combined[key as keyof typeof combined];
      if (typeof value === "string") {
        if (value.toLowerCase().includes(searchValue)) {
          return true;
        }
      }

      if (productTableProps.translateHeaders.includes(key)) {
        const translatedValue = t(`${key}.${value}`);
        if (
          translatedValue &&
          translatedValue.toLowerCase().includes(searchValue)
        ) {
          return true;
        }
      }
    }
  }

  return false;
};

const productGroupMatchesIntervalAmount = (productGroup: ProductGroupSale) => {
  for (const key in productTableProps.filter.intervals) {
    const interval = productTableProps.filter.intervals[key];

    if (key in productGroup) {
      if (
        "from" in interval &&
        !(interval.from <= Number(productGroup[key as keyof ProductGroupSale]))
      ) {
        return false;
      }
      if (
        "to" in interval &&
        !(interval.to >= Number(productGroup[key as keyof ProductGroupSale]))
      ) {
        return false;
      }
    }
  }
  return true;
};

const toggleSubTable = (productGroup: ProductGroupSale) => {
  selectedProductGroupsForUpdates.value = [];
  selectedProductItemsForUpdates.value = [];
  selectedOrderIds.value = [];
  toggleMainHeaderCheckbox.value = false;
  selectedProducts.value = 0;

  if (selectedProductGroupForShowingItems.value === productGroup) {
    showSubTable.value = !showSubTable.value;
  } else {
    showSubTable.value = true;
    selectedProductGroupForShowingItems.value = productGroup;
  }
  if (!productTableProps.hideCheckboxes) {
    hideHeaderCheckbox.value = showSubTable.value;
  }
};

const handleSelectOrderRow = (
  selectedOrder: OrderProductHeader["key"]
): OrderForUpdate[] => {
  const currentOrdersForUpdate = [...ordersForUpdate.value];

  const currentOrderItems = selectedOrder.group.products.map(
    product => product.article_id
  );
  const productGroupIndexToRemove = currentOrdersForUpdate.findIndex(
    order => order.orderId === Number(selectedOrder.group.order_id)
  );

  currentRoom.value = selectedOrder.items[0].rum;

  if (productGroupIndexToRemove !== -1) {
    currentOrdersForUpdate.splice(productGroupIndexToRemove, 1);

    selectedProductItemsForUpdates.value =
      selectedProductItemsForUpdates.value.filter(
        productId => !currentOrderItems.includes(productId)
      );
  } else {
    currentOrdersForUpdate.push({
      orderId: Number(selectedOrder.group.order_id),
      productId: selectedOrder.items[0].id
    });
    currentOrderItems.forEach(itemId =>
      selectedProductItemsForUpdates.value.push(itemId)
    );
  }

  toggleMainHeaderCheckbox.value =
    currentOrdersForUpdate.length === Object.keys(productHeaders.value).length;
  selectedProducts.value = currentOrdersForUpdate.length;

  return currentOrdersForUpdate;
};

const handleSelectMainRow = (
  selectedProductHeader: ProductHeaderSale["key"]
): ProductGroupSale["green_hash"][] => {
  currentSelectedProductHeader.value = selectedProductHeader;

  const currentSelectedProductGroups = [
    ...selectedProductGroupsForUpdates.value
  ];

  const currentProductItems = selectedProductHeader.items.map(
    productItem => productItem.id
  );

  const productGroupIndexToRemove = currentSelectedProductGroups.findIndex(
    productHashId => productHashId === selectedProductHeader.group.green_hash
  );

  currentRoom.value = selectedProductHeader.items[0].rum;

  if (
    productGroupIndexToRemove !== -1 &&
    selectedProductItemsForUpdates.value.some(productId =>
      selectedProductHeader.items.some(item => item.id === productId)
    )
  ) {
    currentSelectedProductGroups.splice(productGroupIndexToRemove, 1);

    selectedProductItemsForUpdates.value =
      selectedProductItemsForUpdates.value.filter(
        productId => !currentProductItems.includes(productId)
      );
  } else {
    currentSelectedProductGroups.push(selectedProductHeader.group.green_hash);
    selectedProductHeader.items.forEach(productItem =>
      selectedProductItemsForUpdates.value.push(productItem.id)
    );
  }

  if (
    productTableProps.tableType !== "internalSold" ||
    !("order_id" in selectedProductHeader.group)
  ) {
    toggleMainHeaderCheckbox.value =
      currentSelectedProductGroups.length ===
      Object.keys(productHeaders.value).length;
    selectedProducts.value = currentSelectedProductGroups.length;
  }

  return currentSelectedProductGroups;
};

const handleSelectSubRow = (
  selectedProductHeader: ProductHeaderSale["key"],
  selectedProductItem: ProductItemSale,
  productItemsAmount: number
): ProductItemSale["id"][] => {
  currentSelectedProductHeader.value = selectedProductHeader;

  const currentSelectedProductItems = [...selectedProductItemsForUpdates.value];

  const productItemIndexToRemove = currentSelectedProductItems.findIndex(
    productId => productId === selectedProductItem.id
  );

  if (productItemIndexToRemove !== -1) {
    currentSelectedProductItems.splice(productItemIndexToRemove, 1);
  } else {
    currentSelectedProductItems.push(selectedProductItem.id);
  }

  toggleSubHeaderCheckbox.value =
    currentSelectedProductItems.length === productItemsAmount;

  selectedProducts.value = currentSelectedProductItems.length;

  return currentSelectedProductItems;
};

const handleSelectAllOrderRows = (
  allProductHeaders: OrderProductHeader,
  isChecked: boolean
): OrderForUpdate[] | [] => {
  if (isChecked) {
    toggleMainHeaderCheckbox.value = true;
    selectedProducts.value = Object.keys(allProductHeaders).length;

    return Object.keys(allProductHeaders).map(productHeaderKey => {
      const productOrder = allProductHeaders[productHeaderKey].group;

      productOrder.products.forEach(product =>
        selectedProductItemsForUpdates.value.push(product.article_id)
      );

      return {
        orderId: productOrder.order_id,
        productId: productOrder.products[0].article_id
      };
    });
  }
  selectedProductItemsForUpdates.value = [];
  toggleMainHeaderCheckbox.value = false;
  selectedProducts.value = 0;
  return [];
};

const handleSelectAllMainRows = (
  allProductHeaders: ProductHeaderSale | AdvertisementProductHeader,
  isChecked: boolean
): ProductGroupSale["green_hash"][] | [] => {
  if (isChecked) {
    toggleMainHeaderCheckbox.value = true;
    selectedProducts.value = 0;

    return Object.keys(allProductHeaders)
      .map(productHeaderKey => allProductHeaders[productHeaderKey])
      .filter(
        currProductHeader =>
          !("orders" in currProductHeader.group) ||
          currProductHeader.group?.orders.length === 0
      )
      .flatMap(currProductHeader => {
        filteredProductItems(currProductHeader).forEach(productItem => {
          selectedProductItemsForUpdates.value.push(productItem.id);
        });
        selectedProducts.value += 1;

        return currProductHeader.group.green_hash;
      });
  }
  selectedProductItemsForUpdates.value = [];
  toggleMainHeaderCheckbox.value = false;
  selectedProducts.value = 0;
  return [];
};

const handleSelectAllSubRows = (
  productHeader: ProductHeaderSale["key"],
  isChecked: boolean
): ProductItemSale["id"][] | [] => {
  currentSelectedProductHeader.value = productHeader;
  const productItems = filteredProductItems(productHeader);
  if (isChecked) {
    toggleSubHeaderCheckbox.value = true;
    selectedProducts.value = productItems.length;

    return productItems.map(productItem => productItem.id);
  }
  toggleSubHeaderCheckbox.value = false;
  selectedProducts.value = 0;
  return [];
};

const changeStatus = async (productStatus: ProductStatuses) => {
  isLoading.value = true;
  const result = await updateStatus(
    selectedProductItemsForUpdates.value,
    productStatus
  );
  isLoading.value = false;

  if (!result.success) return;

  responseModal.value.title =
    getResponseTextAndTitleByProductStatus(productStatus).title;
  responseModal.value.text =
    getResponseTextAndTitleByProductStatus(productStatus).text;

  showResponseModal.value = true;
  getProducts();
};

const getResponseTextAndTitleByProductStatus = (
  productStatus: ProductStatuses
) => {
  let responseText = t("msg.productIsMoved", [
    translatedActions(productStatus)
  ]);
  let responseTitle = t("title.actionChanged");

  switch (productStatus) {
    case "J":
      responseText = `${t("msg.productHasBeenReset2")} ${bolderFont(
        t("title.handleProducts")
      )}`;
      break;
    case "M":
      responseTitle = t("title.actionCompleted");
      responseText = `${t(`list.completedActions.${productStatus}`)}
        ${bolderFont(t("title.repurposeOverview"))}`;

      break;
    case "C":
      responseTitle = t("title.actionCompleted");
      responseText = `${t(
        `list.completedActions.${productStatus}`
      )} ${bolderFont(t("title.repurposeOverview"))}`;
  }
  return { text: responseText, title: responseTitle };
};

const getProducts = async () => {
  isLoading.value = true;

  selectedProductForShowingInformation.value = null;
  selectedProductGroupForShowingItems.value = null;
  ordersForUpdate.value = [];
  selectedProductItemsForUpdates.value = [];
  selectedProductGroupsForUpdates.value = [];
  selectedProducts.value = 0;
  hideHeaderCheckbox.value = false;
  toggleMainHeaderCheckbox.value = false;
  showSubTable.value = false;

  modalType.value = null;

  if (productTableProps.externalProducts) {
    emit("fetchProducts");
    isLoading.value = false;
    return;
  }

  const quantitiesResult = await indexStore.updateQuantities();

  if (quantitiesResult !== "success") {
    isLoading.value = false;
    return;
  }

  getProductHeaders();
};

const getProductHeaders = async () => {
  isLoading.value = true;

  const result = await getProductHeadersApi(productTableProps.presetFilter);

  if (!result.success) {
    isLoading.value = false;
    return;
  }

  productHeaders.value = result.result;
  if (
    !("levkod" in messages.value[locale.value.toLocaleLowerCase()]) ||
    !("kategori_id" in messages.value[locale.value.toLocaleLowerCase()])
  ) {
    const translationResult = await indexStore.getLocaleTranslations(
      indexStore.projectDetails,
      messages.value[locale.value]
    );

    if (translationResult !== "success") {
      isLoading.value = false;
      return;
    }
  }

  selectedProductGroupForShowingItems.value = null;

  indexStore.calculateMaxQuantity(filteredProductHeaders.value);

  isLoading.value = false;
};

const resetToAvailableStatus = async () => {
  modalType.value = null;

  isLoading.value = true;

  const result = await denyInternalOrders(ordersForUpdate.value);

  if (!result.success) {
    isLoading.value = false;
    return;
  }

  responseModal.value.title = t("title.orderHasBeenReset");
  responseModal.value.text = t("paragraph.productsHaveBeenMovedToAvailable");
  responseModal.value.type = "success";
  showResponseModal.value = true;
  getProducts();
  isLoading.value = false;
};

const translatedActions = (productStatus: ProductStatuses | null) => {
  let translatedAction = null;
  if (productStatus === "S") {
    translatedAction = t("title.sell");
  } else if (productStatus === "Å") {
    translatedAction = t("title.repurpose");
  } else if (productStatus === "G") {
    translatedAction = t("button.onGreenifiedWeb");
  } else if (productStatus === "I") {
    translatedAction = t("button.otherSales");
  } else {
    translatedAction = t("list.actionStatusLocation." + productStatus);
  }
  return `<b>${translatedAction}</b>`;
};

onMounted(async () => {
  if (productTableProps.externalProducts)
    return (productHeaders.value =
      productTableProps.externalProducts as ProductHeaderSale);
  getProductHeaders();
});

watch(
  () => productTableProps.sortingSettings,
  newSortingSettings => {
    mainTableSortingOptions.value = newSortingSettings;
    subTableSortingOptions.value = newSortingSettings;
  },
  { deep: true }
);

watch(
  () => selectedProducts.value,
  currSelectedProducts => {
    if (currSelectedProducts > 0) {
      emit("footerIsOpen", true);
      return;
    }

    emit("footerIsOpen", false);
    return;
  }
);

watch(
  () => productTableProps.footerIsViewable,
  isViewable => {
    if (!isViewable) {
      selectedProductGroupsForUpdates.value = [];
      selectedProductItemsForUpdates.value = [];
      toggleMainHeaderCheckbox.value = false;
      selectedProducts.value = 0;
    }
  }
);
</script>

<style scoped>
.subtable {
  padding-top: 16px;
  padding-bottom: 28px;
}

.left-frame {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 28px;
}

.right-frame {
  position: absolute;
  right: 0;
  top: 0;
  height: 100%;
  width: 28px;
  background-color: black;
}
</style>
