import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, Fragment as _Fragment, createElementVNode as _createElementVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "d-flex"
}
const _hoisted_2 = { key: 0 }
const _hoisted_3 = { class: "row justify-content-end" }
const _hoisted_4 = { class: "col-2" }

import { ProductInformation } from "../../library/types/product/productGroup";
import {
  ProductItemInformation,
  ProductItemSale
} from "../../library/types/product/productItem";
import {
  ref,
  Ref,
  onMounted
} from "vue";
import GreenifiedButton from "../common/GreenifiedButton.vue";
import GeneralProductInformation from "../form/editProduct/GeneralProductInformation.vue";
import { unexpectedError } from "@/modules/errorToastModule";
import {
  getProductInformationApi,
  updateProductItemSpecifics,
  updateProductItemStructure
} from "@/library/api/products";
import GreenifiedModal from "./GreenifiedModal.vue";
import SpecificProductInformation from "../form/editProduct/SpecificProductInformation.vue";
import { ProductStatuses } from "@/library/types/product/productHeader";
import { updateProductsWithSameGreenifiedHash } from "@/library/api/greenifiedProducts";
import { useI18n } from "vue-i18n";

type EditProductsModalProps = {
  productType: "item" | "group";
  productId: ProductItemSale["id"] | null;
  modalTitle: string;
  productStatus: ProductStatuses;
  productIds: string[];
};


export default /*@__PURE__*/_defineComponent({
  __name: 'EditProductModal',
  props: {
    productType: { default: "group" },
    productId: { default: null },
    modalTitle: { default: "" },
    productStatus: { default: "J" },
    productIds: { default: () => [] }
  },
  emits: ["close", "refresh"],
  setup(__props: any, { emit: __emit }) {

const { t } = useI18n();
const editProductsModalProps = __props;

const emit = __emit;
const isLoading = ref(false);
const editType = ref(0);
const productInformation: Ref<ProductInformation | null> = ref(null);
const updateStatus: Ref<"success" | "fail" | null> = ref(null);
const productItemSpecifics: Ref<ProductItemInformation | null> = ref(null);
const generalProductInformation =
  ref<InstanceType<typeof GeneralProductInformation>>();

const getProductInformation = async () => {
  isLoading.value = true;
  if (!editProductsModalProps.productId) {
    unexpectedError();
    return emit("close");
  }

  const result = await getProductInformationApi(
    editProductsModalProps.productId
  );
  isLoading.value = false;

  if (!result.success) return;

  productInformation.value = result.result;
  const currentProductSpecifics = productInformation.value.same_products.find(
    product => product.id === editProductsModalProps.productId
  );

  if (currentProductSpecifics) {
    productItemSpecifics.value = currentProductSpecifics;
    if (!Object.keys(productItemSpecifics.value.productSpecifics).length) {
      productItemSpecifics.value.productSpecifics = {};
    }
  }
};

const handleFormSubmit = () => {
  if (editProductsModalProps.productType === "item") {
    return editType.value === 0 ? updateItemSpecifics() : updateItemStructure();
  }

  return updateProductsWithSameHash();
};

const updateItemStructure = async () => {
  if (!editProductsModalProps.productId || !productInformation.value) return;
  if (!generalProductInformation.value?.validateForm()) return;

  isLoading.value = true;
  const currentProductInformation = { ...productInformation.value };
  currentProductInformation.same_products = currentProductInformation[
    "same_products"
  ].filter(elem => elem.id === currentProductInformation.id);

  const result = await updateProductItemStructure(
    editProductsModalProps.productId,
    currentProductInformation
  );
  isLoading.value = false;

  if (!result.success) {
    updateStatus.value = "fail";
    return;
  }

  updateStatus.value = "success";
  getProductInformation();
};

const updateItemSpecifics = async () => {
  if (!productItemSpecifics.value) return;
  isLoading.value = true;
  const result = await updateProductItemSpecifics(productItemSpecifics.value);
  isLoading.value = false;

  if (!result.success) {
    updateStatus.value = "fail";
    return;
  }
  updateStatus.value = "success";
};

const updateProductsWithSameHash = async () => {
  const currentProductInformation = productInformation.value;
  if (!currentProductInformation || !editProductsModalProps.productIds.length)
    return;
  if (!generalProductInformation.value?.validateForm()) return;

  isLoading.value = true;
  const result = await updateProductsWithSameGreenifiedHash(
    currentProductInformation.green_struc,
    currentProductInformation.supplier_code,
    currentProductInformation.category,
    editProductsModalProps.productStatus,
    editProductsModalProps.productIds
  );
  isLoading.value = false;

  if (!result.success) {
    updateStatus.value = "fail";
    return;
  }

  updateStatus.value = "success";

  getProductInformation();
};

onMounted(() => {
  getProductInformation();
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.productId)
      ? (_openBlock(), _createBlock(GreenifiedModal, {
          onClose: _cache[4] || (_cache[4] = ($event: any) => (emit('close'))),
          type: 'medium',
          maxHeight: true,
          title: _ctx.modalTitle,
          isLoading: isLoading.value,
          key: editType.value
        }, {
          modalHeaderContent: _withCtx(() => [
            (_ctx.productType === 'item')
              ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
                  _createVNode(GreenifiedButton, {
                    btnStyle: editType.value === 0 ? 'black' : 'normal',
                    onClick: _cache[0] || (_cache[0] = ($event: any) => (editType.value = 0)),
                    text: _unref(t)('button.specificInformation'),
                    size: "thin",
                    class: "me-4"
                  }, null, 8, ["btnStyle", "text"]),
                  _createVNode(GreenifiedButton, {
                    btnStyle: editType.value === 1 ? 'black' : 'normal',
                    onClick: _cache[1] || (_cache[1] = ($event: any) => (editType.value = 1)),
                    text: _unref(t)('button.extendedInformation'),
                    size: "thin"
                  }, null, 8, ["btnStyle", "text"])
                ]))
              : _createCommentVNode("", true)
          ]),
          container: _withCtx(() => [
            (productInformation.value)
              ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                  (_ctx.productType === 'item')
                    ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                        (editType.value === 0 && productItemSpecifics.value)
                          ? (_openBlock(), _createBlock(SpecificProductInformation, {
                              key: editType.value,
                              productSpecifics: productItemSpecifics.value,
                              "onUpdate:productSpecifics": _cache[2] || (_cache[2] = ($event: any) => ((productItemSpecifics).value = $event))
                            }, null, 8, ["productSpecifics"]))
                          : _createCommentVNode("", true),
                        (editType.value === 1)
                          ? (_openBlock(), _createBlock(GeneralProductInformation, {
                              ref_key: "generalProductInformation",
                              ref: generalProductInformation,
                              key: editType.value,
                              productInformation: productInformation.value
                            }, null, 8, ["productInformation"]))
                          : _createCommentVNode("", true)
                      ], 64))
                    : (_openBlock(), _createBlock(GeneralProductInformation, {
                        key: editType.value,
                        ref_key: "generalProductInformation",
                        ref: generalProductInformation,
                        productInformation: productInformation.value
                      }, null, 8, ["productInformation"]))
                ]))
              : _createCommentVNode("", true)
          ]),
          footer: _withCtx(() => [
            _createElementVNode("div", _hoisted_3, [
              _createElementVNode("div", _hoisted_4, [
                _createVNode(GreenifiedButton, {
                  text: _unref(t)('button.save'),
                  onClick: _cache[3] || (_cache[3] = ($event: any) => (handleFormSubmit())),
                  disabled: isLoading.value
                }, null, 8, ["text", "disabled"])
              ])
            ])
          ]),
          _: 1
        }, 8, ["title", "isLoading"]))
      : _createCommentVNode("", true),
    (updateStatus.value === 'success')
      ? (_openBlock(), _createBlock(GreenifiedModal, {
          key: 1,
          type: 'response',
          closable: false,
          title: _unref(t)('responseTitle.successfullyUpdated'),
          text: _unref(t)('paragraph.productHasBeenUpdated'),
          onClose: _cache[5] || (_cache[5] = ($event: any) => {
      updateStatus.value = null;
      emit('refresh');
    })
        }, null, 8, ["title", "text"]))
      : _createCommentVNode("", true)
  ], 64))
}
}

})