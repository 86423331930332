import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "row mt-4" }
const _hoisted_2 = { class: "col" }
const _hoisted_3 = { class: "subheading-small" }
const _hoisted_4 = { class: "row mt-2" }
const _hoisted_5 = { class: "col-7" }
const _hoisted_6 = { class: "text-small" }
const _hoisted_7 = {
  key: 0,
  class: "row row-gap-3 mt-3"
}
const _hoisted_8 = {
  key: 1,
  class: "row mt-3"
}
const _hoisted_9 = { class: "col-12 d-flex row-gap-5 gap-5 flex-wrap" }
const _hoisted_10 = ["onClick"]
const _hoisted_11 = ["src"]
const _hoisted_12 = { class: "checkbox-wrapper" }
const _hoisted_13 = { class: "row p-0 justify-content-end align-items-end h-100" }
const _hoisted_14 = { class: "col-6 d-flex justify-content-end" }

import {
  ref,
  onMounted,
  Ref
} from "vue";
import GreenifiedModal from "./GreenifiedModal.vue";
import {
  ProductHeaderSale,
  ProductStatuses
} from "@/library/types/product/productHeader";
import ProductCard from "../common/ProductCard.vue";
import GreenifiedButton from "../common/GreenifiedButton.vue";
import {
  ProductImage,
  ProductImages
} from "@/library/types/product/productImage";
import GreenifiedCheckbox from "../common/GreenifiedCheckbox.vue";
import { useI18n } from "vue-i18n";
import { getProductInformationApi } from "@/library/api/products";
import { ProductInformation } from "@/library/types/product/productGroup";
import { updateProductsWithSameGreenifiedHash } from "@/library/api/greenifiedProducts";

type CombineProductsModalProps = {
  productHeaders: ProductHeaderSale;
  selectedProductGroups: string[];
  productItems: string[];
};


export default /*@__PURE__*/_defineComponent({
  __name: 'CombineProductsModal',
  props: {
    productHeaders: { default: () => ({}) },
    selectedProductGroups: { default: () => [] },
    productItems: { default: () => [] }
  },
  emits: ["close", "refresh"],
  setup(__props: any, { emit: __emit }) {

const combineProductsModalProps = __props;

const { t } = useI18n();
const emit = __emit;
const currentView = ref(0);
const isLoading = ref(true);
const selectedProductHeaders: Ref<ProductHeaderSale> = ref({});
const mainProduct: Ref<ProductHeaderSale["key"] | null> = ref(null);
const selectedImages: Ref<ProductImage[]> = ref([]);
const productHeaderImages: Ref<ProductImage[]> = ref([]);
const allProductIds: Ref<string[]> = ref([]);
const allProductHeaderHash: Ref<string[]> = ref([]);
const combineSuccess = ref(false);
const productStatus: Ref<ProductStatuses | null> = ref(null);
const productInformation: Ref<ProductInformation | null> = ref(null);

const getProductInfo = async () => {
  if (!mainProduct.value) return;
  isLoading.value = true;

  const result = await getProductInformationApi(mainProduct.value.items[0].id);

  if (!result.success) {
    isLoading.value = false;
    return;
  }

  productInformation.value = result.result;
  currentView.value = 1;
  isLoading.value = false;
};

const handleSelectImage = (selectedImage: ProductImage) => {
  const existingImageIndex = selectedImages.value.findIndex(
    image => image.url === selectedImage.url
  );

  if (existingImageIndex !== -1) {
    selectedImages.value.splice(existingImageIndex, 1);
    return;
  }
  if (selectedImages.value.length === 4) return;

  selectedImages.value.push(selectedImage);
  return;
};

const combineProducts = async () => {
  if (!productStatus.value) return;

  const currImageKeys = Object.keys(selectedImages.value);
  if (currImageKeys.length < 2 || currImageKeys.length > 4) return;

  if (!productInformation.value || !allProductIds.value.length) return;
  isLoading.value = true;

  const currentProductInformation = { ...productInformation.value };

  currentProductInformation.images = {
    bild_1: { url: "", hash: "" },
    bild_2: { url: "", hash: "" }
  };

  selectedImages.value.forEach((image, index) => {
    currentProductInformation.images[
      `bild_${index + 1}` as keyof ProductImages
    ] = image;
  });

  const combineResult = await updateProductsWithSameGreenifiedHash(
    currentProductInformation.green_struc,
    currentProductInformation.supplier_code,
    currentProductInformation.category,
    productStatus.value,
    allProductIds.value
  );

  if (!combineResult.success) {
    isLoading.value = false;
    return;
  }

  combineSuccess.value = true;

  isLoading.value = false;
};

onMounted(() => {
  for (const productHeaderKey in combineProductsModalProps.productHeaders) {
    const currProductHeader =
      combineProductsModalProps.productHeaders[productHeaderKey];

    if (
      combineProductsModalProps.selectedProductGroups.includes(
        currProductHeader.group.green_hash
      ) &&
      combineProductsModalProps.productItems.some(productId =>
        currProductHeader.items.some(item => item.id === productId)
      )
    ) {
      selectedProductHeaders.value[productHeaderKey] = currProductHeader;
      allProductHeaderHash.value.push(currProductHeader.group.green_hash);
      productStatus.value = currProductHeader.items[0].status;
      const currentProductIds = currProductHeader.items.map(item => item.id);
      allProductIds.value.push(...currentProductIds);
      for (const imageKey in currProductHeader.items[0].images) {
        const currentImage =
          currProductHeader.items[0].images[imageKey as keyof ProductImages];
        if (
          currentImage &&
          !productHeaderImages.value.some(
            productImage => productImage.hash === currentImage.hash
          )
        )
          productHeaderImages.value.push(currentImage);
      }
    }
  }
  isLoading.value = false;
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(GreenifiedModal, {
      onClose: _cache[2] || (_cache[2] = ($event: any) => (emit('close'))),
      size: 'medium',
      title: _unref(t)('title.combineProducts'),
      isLoading: isLoading.value
    }, {
      modalHeaderContent: _withCtx(() => [
        _createElementVNode("div", _hoisted_1, [
          _createElementVNode("div", _hoisted_2, [
            _createElementVNode("p", _hoisted_3, _toDisplayString(currentView.value === 0
                ? `1/2 ${_unref(t)("paragraph.selectMainProduct")}`
                : `2/2 ${_unref(t)("paragraph.selectImagesForMainProduct")}`), 1)
          ])
        ]),
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("div", _hoisted_5, [
            _createElementVNode("p", _hoisted_6, _toDisplayString(currentView.value === 0
                ? _unref(t)("paragraph.combineProductInformation")
                : _unref(t)("paragraph.selectImageInformation")), 1)
          ])
        ])
      ]),
      container: _withCtx(() => [
        (currentView.value === 0)
          ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(selectedProductHeaders.value, (productHeader, index) => {
                return (_openBlock(), _createBlock(ProductCard, {
                  key: index,
                  onClick: ($event: any) => (mainProduct.value = productHeader),
                  productImage: productHeader.items[0].images?.bild_1.url,
                  productName: productHeader.group.benamn,
                  quantity: productHeader.items.length,
                  description: productHeader.group.besk,
                  supplier: _unref(t)(`levkod.${productHeader.group.levkod}`),
                  category: 
              productHeader.group.kategori_id.substring(0, 2) === 'ex'
                ? _unref(t)('label.unkownSupplier')
                : _unref(t)(`kategori_id.${productHeader.group.kategori_id}`)
            ,
                  active: mainProduct.value === productHeader
                }, null, 8, ["onClick", "productImage", "productName", "quantity", "description", "supplier", "category", "active"]))
              }), 128))
            ]))
          : _createCommentVNode("", true),
        (currentView.value === 1)
          ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
              _createElementVNode("div", _hoisted_9, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(productHeaderImages.value, (image, imageIndex) => {
                  return (_openBlock(), _createElementBlock("div", {
                    key: imageIndex,
                    class: "product-image-wrapper",
                    onClick: ($event: any) => (handleSelectImage(image))
                  }, [
                    _createElementVNode("img", {
                      src: image?.url,
                      alt: "product image",
                      class: "combine-product-image"
                    }, null, 8, _hoisted_11),
                    _createElementVNode("div", _hoisted_12, [
                      _createVNode(GreenifiedCheckbox, {
                        size: "large",
                        isChecked: selectedImages.value.includes(image),
                        disabled: selectedImages.value.length === 4
                      }, null, 8, ["isChecked", "disabled"])
                    ])
                  ], 8, _hoisted_10))
                }), 128))
              ])
            ]))
          : _createCommentVNode("", true)
      ]),
      footer: _withCtx(() => [
        _createElementVNode("div", _hoisted_13, [
          _createElementVNode("div", _hoisted_14, [
            (currentView.value === 0)
              ? (_openBlock(), _createBlock(GreenifiedButton, {
                  key: 0,
                  text: _unref(t)('button.selectMainProduct'),
                  btnStyle: "green",
                  size: "thin",
                  onClick: _cache[0] || (_cache[0] = ($event: any) => (getProductInfo())),
                  disabled: !mainProduct.value
                }, null, 8, ["text", "disabled"]))
              : (_openBlock(), _createBlock(GreenifiedButton, {
                  key: 1,
                  text: _unref(t)('button.finishCombining'),
                  btnStyle: "green",
                  size: "thin",
                  onClick: _cache[1] || (_cache[1] = ($event: any) => (combineProducts())),
                  disabled: selectedImages.value.length < 2
                }, null, 8, ["text", "disabled"]))
          ])
        ])
      ]),
      _: 1
    }, 8, ["title", "isLoading"]),
    (combineSuccess.value)
      ? (_openBlock(), _createBlock(GreenifiedModal, {
          key: 0,
          type: "response",
          title: _unref(t)('title.productsHaveBeenCombined'),
          text: _unref(t)('paragraph.combineProductsText'),
          onClose: _cache[3] || (_cache[3] = ($event: any) => (emit('refresh')))
        }, null, 8, ["title", "text"]))
      : _createCommentVNode("", true)
  ], 64))
}
}

})