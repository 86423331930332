<template>
  <GreenifiedModal
    v-if="currentSaleOrder"
    :title="`${t('title.handleOrder')}${
      currentSaleOrder.order_number && currentSaleOrder.order_number.length
        ? `: ${currentSaleOrder.order_number}`
        : ''
    }`"
    @close="emit('close')"
    :type="'smaller'"
    :isLoading="isLoading"
  >
    <template #container>
      <form ref="purchaseOrderForm" id="purchaseOrderForm" v-if="!isLoading">
        <div class="form-container">
          <div class="row">
            <div
              class="col-6 d-flex form-label-paragraph align-items-center fw-bold"
            >
              <label
                >{{ t("label.changeAmount") }}
                {{
                  productInformation?.group.sale_info["ange-antal"]
                    ? `(${t("label.inGroupOf")} ${
                        productInformation?.group.sale_info["ange-antal"]
                      })`
                    : ""
                }}*</label
              >
            </div>
            <div class="col-6">
              <CustomSelect
                :options="productAmountOptions"
                label="label"
                v-model="currentSaleOrder.requested_quantity"
                @update:model-value="
                  () => {
                    if (
                      Number(currentSaleOrder.requested_quantity) <
                      productIds.length
                    ) {
                      productIds = [];
                    }
                  }
                "
                :placeholder="t('label.selectQuantity')"
                :reducedKey="'value'"
                :required="true"
              />
            </div>
          </div>

          <div v-if="productInformation" class="row">
            <div
              class="col-6 d-flex form-label-paragraph align-items-center fw-bold"
            >
              <label> {{ t("label.selectProducts") }} *</label>
            </div>
            <div class="col-6">
              <ProductsSelect
                :maxQuantity="Number(currentSaleOrder.requested_quantity)"
                :productItems="productItems"
                :reservedProductIds="reservedProductIds"
                :soldProductIds="soldProductIds"
                v-model:selectedProductIds="productIds"
                :requiredQuantity="true"
              />
            </div>
          </div>
          <div class="row">
            <div
              class="col-6 form-label-paragraph fw-bold align-items-center d-flex"
            >
              <label> {{ t("label.whenToBeCollected") }}</label>
            </div>
            <div class="col-6">
              <InputField
                v-if="productInformation"
                v-model:field-value="currentSaleOrder.pick_up_date"
                :minValue="productInformation.group?.sell_tillganglig"
                maxValue="9999-12-31"
                type="date"
              />
            </div>
          </div>
          <div class="row">
            <div
              class="col-6 form-label-paragraph fw-bold align-items-center d-flex"
            >
              <label>{{ t("paragraph.deliveryAddress") }}</label>
            </div>
            <div class="col-6">
              <SelectRoom
                :currentAddress="currentSaleOrder.delivery_address"
                withAddress
                is-reduced
                :currentAddressRoom="currentSaleOrder.delivery_address"
                @update:currentAddress="
                  address => {
                    handleSelectAddress(address);
                    currentSaleOrder.delivery_room = null;
                  }
                "
              />
            </div>
          </div>
          <div class="row">
            <div
              class="col-6 form-label-paragraph fw-bold align-items-center d-flex"
            >
              <label>{{ t("paragraph.deliveryRoom") }}</label>
            </div>
            <div class="col-6">
              <SelectRoom
                v-model="currentSaleOrder.delivery_room"
                :key="currentDeliveryAddress?.id"
                :defaultRooms="addressRooms"
              />
            </div>
          </div>
          <div class="row mt-5">
            <div
              class="col-6 form-label-paragraph fw-bold align-items-center d-flex"
            >
              <label> {{ t("label.email") }}</label>
            </div>
            <div class="col-6">
              <p class="subheading-tiny">{{ currentSaleOrder.email }}</p>
            </div>
          </div>

          <div class="row">
            <div class="col-6 pt-2 form-label-paragraph fw-bold d-flex">
              <label> {{ t("label.comment") }}</label>
            </div>
            <div class="col-6">
              <p class="subheading-tiny">
                {{
                  currentSaleOrder.order_comment &&
                  currentSaleOrder.order_comment.length
                    ? currentSaleOrder.order_comment
                    : "-"
                }}
              </p>
            </div>
          </div>
        </div>
      </form>
    </template>
    <template #footer>
      <div class="col-12">
        <div class="row">
          <div class="col-8">
            <CustomSelect
              :key="String(isLoading)"
              :options="saleStatuses"
              label="label"
              reducedKey="value"
              v-model="currentSaleOrder.status"
              ><template
                #option="option: Record<
                  'option',
                  { label: string, value: string }
                >"
              >
                <div
                  v-if="option.option.value === 'D'"
                  class="pt-4 position-relative"
                >
                  <div class="empty-option"></div>

                  {{ option.option.label }}
                </div>

                <div v-else>
                  {{ option.option.label }}
                </div>
              </template></CustomSelect
            >
          </div>
          <div class="col-4">
            <GreenifiedButton
              :text="t('button.save')"
              btnStyle="green"
              :disabled="
                (currentSaleOrder.status === 'A' ||
                  currentSaleOrder.status === 'C') &&
                productIds.length !==
                  Number(currentSaleOrder.requested_quantity)
              "
              @click="
                currentSaleOrder.status === 'D'
                  ? postDenyPurchaseOrder()
                  : postPurchaseOrder()
              "
            />
          </div>
        </div>
      </div>
    </template>
  </GreenifiedModal>
  <GreenifiedModal
    type="response"
    :closable="false"
    @close="emit('refresh')"
    :title="t('title.orderHasBeenUpdated')"
    :text="t('paragraph.mailHasBeenSentToBuyer')"
    v-if="showPurchaseVerification"
  />
</template>

<script setup lang="ts">
import {
  ref,
  defineProps,
  withDefaults,
  computed,
  defineEmits,
  Ref,
  onMounted
} from "vue";

import {
  denyInternalOrders,
  getInternalOrdersByAdvertisementId,
  updateInternalOrder
} from "@/library/api/sales";
import GreenifiedModal from "../modal/GreenifiedModal.vue";
import GreenifiedButton from "../common/GreenifiedButton.vue";
import { OrderProductHeader } from "@/library/types/product/productHeader";
import { useI18n } from "vue-i18n";
import InputField from "../common/InputField.vue";
import CustomSelect, { LocalVueSelectOption } from "../common/CustomSelect.vue";
import SelectRoom from "../SelectRoom.vue";
import { ProjectSettingsAdress } from "@/services/api/addressApi";
import { RoomObject } from "@/library/types/room/rooms";
import { postNewProductRoom } from "@/library/api/moveProducts";
import { OrderDataWithProducts, OrderForUpdate } from "@/services/api/salesApi";
import { CustomError, Result } from "@/library/helpers/handleApiRequests";
import { AxiosResponse } from "axios";
import ProductsSelect from "../common/ProductsSelect.vue";
import { ProductItemSale } from "@/library/types/product/productItem";

type InternalBuyPropTypes = {
  productToPurchase: OrderProductHeader["key"] | null;
};

type QuantityOption = {
  label?: string | number;
  value: number;
};

const internalPurchaseOrderConfirmationProps = withDefaults(
  defineProps<InternalBuyPropTypes>(),
  {
    productToPurchase: null,
    saleOrder: null
  }
);
const emit = defineEmits(["refresh", "close"]);

const { t } = useI18n();
const currentDeliveryAddress: Ref<ProjectSettingsAdress | null> = ref(null);
const showPurchaseVerification = ref(false);
const isLoading = ref(false);
const reservedProductIds: Ref<string[]> = ref([]);
const soldProductIds: Ref<string[]> = ref([]);
const productItems: Ref<ProductItemSale[]> = ref([]);
const productInformation = ref(
  internalPurchaseOrderConfirmationProps.productToPurchase
);
const addressRooms: Ref<RoomObject[]> = ref([]);
const productIds: Ref<string[]> = ref([]);
const currentSaleOrder: Ref<OrderDataWithProducts> = ref({
  products: [],
  email: "",
  status: "O",
  pick_up_date: "",
  order_number: "",
  order_id: 0,
  requested_quantity: "0",
  order_comment: "",
  delivery_address: null,
  delivery_room: null,
  greenifiedHash: null,
  advertisement_id: null
});

const saleStatuses: LocalVueSelectOption[] = [
  {
    label: `1/3 ${t("label.awaitingApproval")}`,
    value: "O"
  },
  {
    label: `2/3 ${t("label.transportBooked")}`,
    value: "A"
  },
  {
    label: `3/3 ${t("label.delivered")}`,
    value: "C"
  },
  {
    label: t("label.moveBackToAvailable"),
    value: "D"
  }
];

const purchaseOrderForm = ref<HTMLFormElement | null>(null);

onMounted(() => {
  const orderProductHeader =
    internalPurchaseOrderConfirmationProps.productToPurchase;
  if (!orderProductHeader) {
    emit("close");
    return;
  }

  getCurrentOrder();
});

const getCurrentOrder = async () => {
  const currentProductGroup =
    internalPurchaseOrderConfirmationProps.productToPurchase?.group;

  if (!currentProductGroup) return;
  isLoading.value = true;

  const result = await getInternalOrdersByAdvertisementId(
    currentProductGroup.advertisement_id
  );

  if (!result.success) {
    isLoading.value = false;
    emit("close");
    return;
  }

  const saleOrders = result.result;

  const currSaleOrder = saleOrders.find(
    order => order.order_id === currentProductGroup.order_id
  );

  if (!currSaleOrder) {
    isLoading.value = false;
    emit("close");
    return;
  }

  saleOrders.forEach(saleOrder => {
    if (saleOrder.order_id !== currSaleOrder.order_id) {
      if (saleOrder.status !== "C" && saleOrder.products.length > 0) {
        reservedProductIds.value.push(
          ...saleOrder.products.map(product => product.article_id)
        );
      } else {
        soldProductIds.value.push(
          ...saleOrder.products.map(product => product.article_id)
        );
      }
    }
  });

  productIds.value = currSaleOrder.products.map(product => product.article_id);

  productItems.value = saleOrders[0].advertisement_products.flatMap(
    adProduct => adProduct.product_data
  );

  currentSaleOrder.value = {
    products: currSaleOrder.products,
    email: currSaleOrder.email,
    status: currSaleOrder.status,
    pick_up_date: currSaleOrder?.pick_up_date?.substring(0, 10) ?? null,
    order_number: currSaleOrder.order_number,
    order_id: currSaleOrder.order_id,
    requested_quantity: currSaleOrder.requested_quantity,
    order_comment: currSaleOrder.order_comment,
    delivery_address: currSaleOrder.delivery_address,
    delivery_room: currSaleOrder.delivery_room,
    advertisement_id: currSaleOrder.advertisement_id
  };

  isLoading.value = false;
};

const productAmountOptions = computed(() => {
  let amountOptions: QuantityOption[] = [];
  if (
    !internalPurchaseOrderConfirmationProps.productToPurchase ||
    !productInformation.value
  )
    return amountOptions;

  const soldInGroupOf = Number(
    internalPurchaseOrderConfirmationProps.productToPurchase.group.sale_info[
      "ange-antal"
    ]
  );

  let availableProductItems = productInformation.value.items;
  const unavailableProductIds = [
    reservedProductIds.value,
    soldProductIds.value
  ].flat();

  if (unavailableProductIds) {
    availableProductItems = productInformation.value.items.filter(
      productItem => !unavailableProductIds.includes(productItem.id)
    );
  }

  if (soldInGroupOf && soldInGroupOf > 1) {
    amountOptions = availableProductItems
      // eslint-disable-next-line no-unused-vars
      .map((item, index) => index + 1)
      .filter(value => value % soldInGroupOf === 0)
      .map(value => ({
        label:
          value === availableProductItems.length
            ? t("label.all")
            : String(value),
        value: value
      }));
  } else {
    // eslint-disable-next-line no-unused-vars
    amountOptions = availableProductItems.map((item, index) => ({
      label:
        index === availableProductItems.length - 1 && index !== 0
          ? t("label.all")
          : String(index + 1),
      value: index + 1
    }));
  }

  return amountOptions;
});

const handleSelectAddress = (address: ProjectSettingsAdress) => {
  addressRooms.value = [];
  currentDeliveryAddress.value = address;
  currentSaleOrder.value.delivery_address = address.id;

  addressRooms.value = currentDeliveryAddress.value.rooms.filter(
    room => room.aktiv === "1"
  );
};

const postDenyPurchaseOrder = async () => {
  if (
    !internalPurchaseOrderConfirmationProps.productToPurchase?.group.green_hash
  )
    return;

  isLoading.value = true;

  const deleteOrderData: OrderForUpdate = {
    orderId: currentSaleOrder.value.order_id,
    productId:
      internalPurchaseOrderConfirmationProps.productToPurchase.items[0].id
  };

  const result = await denyInternalOrders([deleteOrderData]);

  if (!result.success) {
    isLoading.value = false;
    return;
  }

  showPurchaseVerification.value = true;
  isLoading.value = false;
};

const postPurchaseOrder = async () => {
  isLoading.value = true;

  if (purchaseOrderForm.value && !purchaseOrderForm.value.reportValidity()) {
    isLoading.value = false;
    return;
  }

  if (
    currentSaleOrder.value.delivery_room &&
    currentSaleOrder.value.delivery_room !==
      internalPurchaseOrderConfirmationProps.productToPurchase?.items[0].rum
  ) {
    const moveResult = await moveProducts(
      currentSaleOrder.value.delivery_room,
      productIds.value
    );
    if (moveResult === "error") {
      isLoading.value = false;
      return;
    }
  }

  let result: Result<AxiosResponse, CustomError>;

  result = await updateInternalOrder(currentSaleOrder.value, productIds.value);

  if (!result.success) {
    isLoading.value = false;
    return;
  }

  showPurchaseVerification.value = true;
  isLoading.value = false;
};

const moveProducts = async (newRoom: string, productIds: string[]) => {
  if (!newRoom) return;

  const result = await postNewProductRoom(productIds, newRoom);

  if (!result.success) return "error";

  return "ok";
};
</script>

<style scoped>
.form-container {
  > div {
    margin-top: 1rem;
  }
}

.empty-option {
  height: 22px;
  width: 200%;
  background-color: white;
  z-index: 20;
  position: absolute;
  left: -20px;
  top: -3px;
}
</style>
