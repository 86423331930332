import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createBlock as _createBlock, Fragment as _Fragment, normalizeProps as _normalizeProps, guardReactiveProps as _guardReactiveProps } from "vue"
import _imports_0 from '@/assets/img/square-small.svg'
import _imports_1 from '@/assets/img/check-small.svg'
import _imports_2 from '@/assets/img/drop-arrow.svg'


const _hoisted_1 = { class: "vs__search vs__hidden_selected_options" }
const _hoisted_2 = {
  key: 0,
  class: "form-label-paragraph fw-bold mt-2 mb-2 header-label",
  style: {"color":"black"}
}
const _hoisted_3 = ["onClick"]
const _hoisted_4 = { class: "d-flex w-100 align-items-center" }
const _hoisted_5 = { class: "ms-2 fw-normal text-muted" }
const _hoisted_6 = {
  key: 0,
  src: _imports_1,
  class: "small-checkbox"
}
const _hoisted_7 = {
  key: 1,
  src: _imports_0,
  class: "small-checkbox"
}
const _hoisted_8 = { class: "ms-2 fw-normal" }
const _hoisted_9 = {
  key: 1,
  class: "d-flex w-100 align-items-center justify-content-between ps-1 fw-normal text-muted"
}
const _hoisted_10 = { class: "d-flex" }

import { ProductItemSale } from "@/library/types/product/productItem";
// eslint-disable-next-line @typescript-eslint/no-unused-vars, no-unused-vars
import vSelect, { VueSelectSlotScope } from "vue-select";
import { withPopper } from "@/modules/popperModule";
import { ref, Ref, watch } from "vue";
import { useI18n } from "vue-i18n";
import InformationTooltip from "./InformationTooltip.vue";

type RoomWithProductItems = [{ rum: string }, ...ProductItemSale[]];

type ProductsSelectProps = {
  productItems: ProductItemSale[];
  reservedProductIds: string[];
  soldProductIds: string[];
  maxQuantity: number;
  selectedProductIds: string[];
  selectableIds?: string[];
  requiredQuantity: boolean;
};

export default /*@__PURE__*/_defineComponent({
  __name: 'ProductsSelect',
  props: {
    productItems: { default: () => [] },
    reservedProductIds: { default: () => [] },
    soldProductIds: { default: () => [] },
    maxQuantity: { default: 1 },
    selectedProductIds: { default: () => [] },
    selectableIds: { default: () => [] },
    requiredQuantity: { type: Boolean, default: true }
  },
  emits: ["update:selectedProductIds"],
  setup(__props: any, { emit: __emit }) {

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const productsSelectProps = __props;

const { t } = useI18n();
const emit = __emit;
const productIds: Ref<string[]> = ref(productsSelectProps.selectedProductIds);

const getPlaceholder = () => {
  if (productsSelectProps.requiredQuantity) {
    return productIds.value.length !== productsSelectProps.maxQuantity
      ? t("placeholders.selectProducts", {
          count: productsSelectProps.maxQuantity
        })
      : t("placeholders.selectedProducts", {
          count: productIds.value.length,
          of: productsSelectProps.maxQuantity
        });
  }
  return t("label.selectProducts");
};

const getItemRooms = () => {
  let currProductItems = productsSelectProps.productItems;

  let availableProductItems = [...currProductItems];

  if (productsSelectProps.soldProductIds.length) {
    availableProductItems = currProductItems.filter(
      productItem =>
        !productsSelectProps.soldProductIds.includes(productItem.id)
    );
  }

  if (productsSelectProps.selectableIds.length) {
    availableProductItems = availableProductItems.filter(productItem =>
      productsSelectProps.selectableIds.includes(productItem.id)
    );
  }

  const distinctRooms = new Set(availableProductItems.map(item => item.rum));

  const distinctRoomsToArray = Array.from(distinctRooms);
  const rooms = distinctRoomsToArray.map(item => {
    return { rum: item };
  });

  availableProductItems.sort((a, b) => {
    const aIsReserved = productsSelectProps.reservedProductIds.includes(a.id);
    const bIsReserved = productsSelectProps.reservedProductIds.includes(b.id);

    if (aIsReserved === bIsReserved) return 0;

    return aIsReserved ? 1 : -1;
  });

  const concatinatedRooms = [
    ...rooms,
    ...availableProductItems
  ] as RoomWithProductItems;

  const sortedRooms = concatinatedRooms.sort((a, b) => {
    return Number(a.rum) - Number(b.rum);
  }) as RoomWithProductItems;

  return sortedRooms;
};

const handleSelectProductId = (productId: string) => {
  const productIdIndex = productIds.value.findIndex(
    existingProductId => existingProductId === productId
  );

  if (productIdIndex !== -1) {
    productIds.value.splice(productIdIndex, 1);
    emit("update:selectedProductIds", productIds.value);
    return;
  }

  if (productIds.value.length === productsSelectProps.maxQuantity) return;

  productIds.value.push(productId);
  emit("update:selectedProductIds", productIds.value);
};

watch(
  () => productsSelectProps.selectedProductIds,
  updatedIds => {
    productIds.value = updatedIds;
  }
);

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(_unref(vSelect), {
    required: "",
    placeholder: getPlaceholder(),
    options: getItemRooms(),
    multiple: "",
    closeOnSelect: false,
    "calculate-position": _unref(withPopper),
    "append-to-body": "",
    clearable: false,
    label: "comp_id",
    deselectFromDropdown: true,
    reduce: (option)  => option.id
  }, {
    "selected-option-container": _withCtx(() => [
      _createElementVNode("div", _hoisted_1, _toDisplayString(_unref(t)("placeholders.selectedProducts", {
            count: productIds.value.length,
            of: _ctx.maxQuantity
          })), 1)
    ]),
    option: _withCtx(({ id, comp_id, rum }) => [
      (!id)
        ? (_openBlock(), _createElementBlock("div", _hoisted_2, _toDisplayString(_unref(t)("label.room") + " " + _unref(t)("rum." + rum)), 1))
        : _createCommentVNode("", true),
      id
        ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
            (!_ctx.reservedProductIds.includes(id))
              ? (_openBlock(), _createElementBlock("div", {
                  key: 0,
                  class: "d-flex w-100 align-items-center",
                  onClick: ($event: any) => (handleSelectProductId(id))
                }, [
                  (!productIds.value.includes(id) && productIds.value.length === _ctx.maxQuantity)
                    ? (_openBlock(), _createBlock(InformationTooltip, {
                        key: 0,
                        infoText: _unref(t)('paragraph.noMoreProductsCanBeAdded'),
                        class: "w-100"
                      }, {
                        icon: _withCtx(() => [
                          _createElementVNode("div", _hoisted_4, [
                            _cache[0] || (_cache[0] = _createElementVNode("img", {
                              src: _imports_0,
                              class: "small-checkbox"
                            }, null, -1)),
                            _createElementVNode("div", _hoisted_5, _toDisplayString(comp_id), 1)
                          ])
                        ]),
                        _: 2
                      }, 1032, ["infoText"]))
                    : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                        (productIds.value.includes(id))
                          ? (_openBlock(), _createElementBlock("img", _hoisted_6))
                          : (_openBlock(), _createElementBlock("img", _hoisted_7)),
                        _createElementVNode("div", _hoisted_8, _toDisplayString(comp_id), 1)
                      ], 64))
                ], 8, _hoisted_3))
              : (_openBlock(), _createElementBlock("div", _hoisted_9, [
                  _createElementVNode("div", _hoisted_10, _toDisplayString(comp_id), 1),
                  _createElementVNode("p", null, _toDisplayString(_unref(t)("placeholders.reserved")), 1)
                ]))
          ], 64))
        : _createCommentVNode("", true)
    ]),
    "open-indicator": _withCtx(({ attributes }: { attributes: VueSelectSlotScope }) => [
      _createElementVNode("span", _normalizeProps(_guardReactiveProps(attributes)), _cache[1] || (_cache[1] = [
        _createElementVNode("img", { src: _imports_2 }, null, -1)
      ]), 16)
    ]),
    _: 1
  }, 8, ["placeholder", "options", "calculate-position", "reduce"]))
}
}

})