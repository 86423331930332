import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createElementVNode as _createElementVNode, normalizeProps as _normalizeProps, guardReactiveProps as _guardReactiveProps, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, renderSlot as _renderSlot, createVNode as _createVNode, normalizeClass as _normalizeClass, Fragment as _Fragment } from "vue"
import _imports_0 from '@/assets/img/drop-arrow.svg'


const _hoisted_1 = { key: 0 }
const _hoisted_2 = { style: {"font-weight":"400"} }
const _hoisted_3 = { key: 1 }
const _hoisted_4 = { style: {"font-weight":"400"} }
const _hoisted_5 = {
  key: 1,
  class: "position-relative"
}
const _hoisted_6 = { class: "d-flex justify-content-between" }
const _hoisted_7 = {
  key: 0,
  class: "me-3"
}
const _hoisted_8 = {
  key: 1,
  class: "fw-normal me-3"
}
const _hoisted_9 = {
  key: 2,
  class: "me-3"
}
const _hoisted_10 = {
  key: 3,
  class: "fw-normal"
}
const _hoisted_11 = {
  key: 0,
  class: "fst-italic fw-normal"
}
const _hoisted_12 = {
  key: 0,
  class: "fst-italic fw-normal"
}
const _hoisted_13 = ["src"]
const _hoisted_14 = {
  key: 0,
  class: "ms-1"
}
const _hoisted_15 = {
  key: 1,
  class: "ms-1"
}
const _hoisted_16 = {
  key: 0,
  class: "loader-spinner-wrapper"
}

import {
  ref,
  Ref,
  watch,
  onMounted
} from "vue";
import { RoomObject } from "../library/types/room/rooms";
import { getProjectRooms } from "@/library/api/rooms";
import plus from "../assets/img/plus.svg";
import minus from "../assets/img/minus.svg";
// eslint-disable-next-line @typescript-eslint/no-unused-vars, no-unused-vars
import VueSelect, { VueSelectSlotScope } from "vue-select";
import { useI18n } from "vue-i18n";
import LoaderSpinner from "./common/LoaderSpinner.vue";
import { withPopper } from "@/modules/popperModule";
import { getAddresses } from "@/library/api/address";
import { ProjectSettingsAdress } from "@/services/api/addressApi";
import InformationTooltip from "./common/InformationTooltip.vue";

type RoomModelValue = string | RoomObject;

type SelectRoomProps = {
  isLoadingRoom?: boolean;
  newRooms?: boolean;
  modelValue?: RoomModelValue | null;
  placeholder?: string | null;
  excludedRoom?: string;
  isReduced?: boolean;
  append?: boolean;
  withAddress?: boolean;
  inAddress?: string;
  currentAddress?: string | null;
  defaultRooms?: RoomObject[] | null;
};


export default /*@__PURE__*/_defineComponent({
  __name: 'SelectRoom',
  props: {
    isLoadingRoom: { type: Boolean, default: false },
    newRooms: { type: Boolean, default: false },
    modelValue: { default: "" },
    placeholder: { default: null },
    excludedRoom: { default: "" },
    isReduced: { type: Boolean, default: true },
    append: { type: Boolean, default: true },
    withAddress: { type: Boolean, default: false },
    inAddress: { default: "" },
    currentAddress: { default: null },
    defaultRooms: { default: null }
  },
  emits: ["update:modelValue", "update:currentAddress"],
  setup(__props: any, { emit: __emit }) {

const emit = __emit;

const selectRoomProps = __props;

const { t } = useI18n();
const showAllLocations = ref(false);
const allLocations: Ref<RoomObject[]> = ref([]);
const oldLocations: Ref<RoomObject[]> = ref([]);
const newLocations: Ref<RoomObject[]> = ref([]);
const currentLocations: Ref<RoomObject[]> = ref([]);
const selectedRoom: Ref<RoomModelValue> = ref("");
const selectedAddress: Ref<ProjectSettingsAdress | null> = ref(null);
const isLoading = ref(selectRoomProps.isLoadingRoom);
const projectAddresses: Ref<ProjectSettingsAdress[]> = ref([]);

const extendedFilter = (searchValue: string, searchKey: string) => {
  return (searchKey || "").toLowerCase().includes(searchValue.toLowerCase());
};

const getRooms = async () => {
  isLoading.value = true;

  const result = await getProjectRooms();

  if (!result.success) {
    return;
  }

  allLocations.value = result.result;

  setLocationData(allLocations.value);

  isLoading.value = false;

  if (!allLocations.value.length) return;
};

const setLocationData = (allLocations: RoomObject[]) => {
  allLocations.forEach(location => {
    location.new_room === "0"
      ? oldLocations.value.push(location)
      : newLocations.value.push(location);
  });
  currentLocations.value =
    selectRoomProps.newRooms ||
    (!oldLocations.value.length && newLocations.value.length)
      ? newLocations.value
      : oldLocations.value;
  const currentRoom = allLocations.find(
    room => room.rum === selectRoomProps.modelValue
  );
  if (currentRoom) {
    selectedRoom.value = currentRoom;
    return;
  }

  if (
    !selectRoomProps.isReduced &&
    selectRoomProps.modelValue &&
    typeof selectRoomProps.modelValue !== "string"
  ) {
    selectedRoom.value = selectRoomProps.modelValue.rum;
    return;
  }
  selectedRoom.value = selectRoomProps.modelValue ?? "";
};

const getProjectAddress = async () => {
  isLoading.value = true;

  const result = await getAddresses();

  if (!result.success) {
    isLoading.value = false;
    return;
  }
  isLoading.value = false;

  projectAddresses.value = result.result;

  const existingAddress = projectAddresses.value.find(
    address => address.id === selectRoomProps.currentAddress
  );
  if (existingAddress) {
    selectedAddress.value = existingAddress;
    emit("update:currentAddress", selectedAddress.value);
  }
};

onMounted(() => {
  if (selectRoomProps.withAddress) {
    getProjectAddress();
    return;
  }
  if (selectRoomProps.defaultRooms) {
    isLoading.value = true;

    setLocationData(selectRoomProps.defaultRooms);
    isLoading.value = false;
    return;
  }
  getRooms();
});

watch(
  () => showAllLocations.value,
  showAll => {
    if (showAll) {
      currentLocations.value = allLocations.value;
    } else {
      currentLocations.value = selectRoomProps.newRooms
        ? newLocations.value
        : oldLocations.value;
    }
  }
);

return (_ctx: any,_cache: any) => {
  return (_ctx.withAddress)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createVNode(_unref(VueSelect), {
          clearable: false,
          "onOption:selected": _cache[0] || (_cache[0] = 
      (option) =>
        emit('update:currentAddress', option) ),
          options: projectAddresses.value,
          label: "building",
          placeholder: 
        isLoading.value
          ? ''
          : _ctx.placeholder
          ? _ctx.placeholder
          : _unref(t)('placeholders.selectAddress')
      ,
          reduce: (option) => option.id,
          modelValue: selectedAddress.value,
          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((selectedAddress).value = $event)),
          "calculate-position": _unref(withPopper),
          selectable: (option) => option?.rooms.length && option.rooms.some((room) => room.aktiv === '1'),
          "append-to-body": ""
        }, {
          "open-indicator": _withCtx(({ attributes }: { attributes: VueSelectSlotScope }) => [
            _createElementVNode("span", _normalizeProps(_guardReactiveProps(attributes)), _cache[5] || (_cache[5] = [
              _createElementVNode("img", { src: _imports_0 }, null, -1)
            ]), 16)
          ]),
          option: _withCtx(({
          rooms,
          building,
          address
        }: {
          rooms: ProjectSettingsAdress['rooms']
          building: ProjectSettingsAdress['building'],
          address: ProjectSettingsAdress['address']
        }) => [
            (!rooms.length || !rooms.some(room => room.aktiv === '1'))
              ? (_openBlock(), _createBlock(InformationTooltip, {
                  key: 0,
                  infoText: _unref(t)('label.noRooms')
                }, {
                  icon: _withCtx(() => [
                    _createElementVNode("div", null, [
                      _createTextVNode(_toDisplayString(building) + " ", 1),
                      _createElementVNode("p", _hoisted_2, [
                        _createTextVNode(_toDisplayString(address.adress), 1),
                        _cache[6] || (_cache[6] = _createElementVNode("br", null, null, -1)),
                        _createTextVNode(_toDisplayString(address.postnummer) + "," + _toDisplayString(address.ort) + "," + _toDisplayString(address.land), 1)
                      ])
                    ])
                  ]),
                  _: 2
                }, 1032, ["infoText"]))
              : (_openBlock(), _createElementBlock("div", _hoisted_3, [
                  _createTextVNode(_toDisplayString(building) + " ", 1),
                  _createElementVNode("p", _hoisted_4, [
                    _createTextVNode(_toDisplayString(address.adress), 1),
                    _cache[7] || (_cache[7] = _createElementVNode("br", null, null, -1)),
                    _createTextVNode(_toDisplayString(address.postnummer) + "," + _toDisplayString(address.ort) + "," + _toDisplayString(address.land), 1)
                  ])
                ]))
          ]),
          "list-footer": _withCtx(() => [
            _renderSlot(_ctx.$slots, "list-footer")
          ]),
          default: _withCtx(() => [
            _cache[8] || (_cache[8] = _createTextVNode(" >"))
          ]),
          _: 3
        }, 8, ["options", "placeholder", "reduce", "modelValue", "calculate-position", "selectable"])
      ]))
    : (_openBlock(), _createElementBlock("div", _hoisted_5, [
        _createVNode(_unref(VueSelect), {
          options: 
        currentLocations.value.filter(location => location.rum !== _ctx.excludedRoom)
      ,
          class: "room-dropdown",
          clearable: false,
          label: "rumsnamn",
          placeholder: 
        isLoading.value
          ? ''
          : _ctx.placeholder
          ? _ctx.placeholder
          : _unref(t)('placeholders.selectRoom')
      ,
          reduce: (option) => option.rum,
          "append-to-body": _ctx.append,
          isLoading: isLoading.value,
          modelValue: selectedRoom.value,
          "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((selectedRoom).value = $event)),
          filterBy: 
      (option, label, search) => {
        if (option.address?.building)
        return (
          extendedFilter(search, option.rumsnamn) ||
          extendedFilter(search, option.rumskod) ||
          extendedFilter(search, option.van) ||
          extendedFilter(search, option.address?.building)
        );
      }
    ,
          "onOption:selected": _cache[4] || (_cache[4] = 
      (option) =>{
        _ctx.isReduced
          ? emit('update:modelValue', option.rum)
          : emit('update:modelValue', option)}
    )
        }, {
          "open-indicator": _withCtx(({ attributes }: { attributes: VueSelectSlotScope }) => [
            _createElementVNode("span", _normalizeProps(_guardReactiveProps(attributes)), _cache[9] || (_cache[9] = [
              _createElementVNode("img", { src: _imports_0 }, null, -1)
            ]), 16)
          ]),
          option: _withCtx(({ rumsnamn, new_room, address, van, rumskod }: RoomObject) => [
            _createElementVNode("div", _hoisted_6, [
              _createElementVNode("div", {
                class: _normalizeClass(["d-flex", showAllLocations.value && 'me-3'])
              }, [
                (address?.building)
                  ? (_openBlock(), _createElementBlock("p", _hoisted_7, _toDisplayString(address?.building), 1))
                  : _createCommentVNode("", true),
                van
                  ? (_openBlock(), _createElementBlock("p", _hoisted_8, _toDisplayString(_unref(t)("label.floorShortened")) + " " + _toDisplayString(van), 1))
                  : _createCommentVNode("", true),
                rumskod
                  ? (_openBlock(), _createElementBlock("p", _hoisted_9, _toDisplayString(rumskod), 1))
                  : _createCommentVNode("", true),
                rumsnamn
                  ? (_openBlock(), _createElementBlock("p", _hoisted_10, _toDisplayString(rumsnamn), 1))
                  : _createCommentVNode("", true)
              ], 2),
              (!_ctx.newRooms)
                ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                    (new_room === '1')
                      ? (_openBlock(), _createElementBlock("p", _hoisted_11, _toDisplayString(_unref(t)("label.newLocation")), 1))
                      : _createCommentVNode("", true)
                  ], 64))
                : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                    (new_room === '0')
                      ? (_openBlock(), _createElementBlock("p", _hoisted_12, _toDisplayString(_unref(t)("label.oldLocation")), 1))
                      : _createCommentVNode("", true)
                  ], 64))
            ])
          ]),
          "list-header": _withCtx(() => [
            (newLocations.value.length && oldLocations.value.length)
              ? (_openBlock(), _createElementBlock("div", {
                  key: 0,
                  class: "show-more-option",
                  onClick: _cache[2] || (_cache[2] = ($event: any) => (showAllLocations.value = !showAllLocations.value))
                }, [
                  _createElementVNode("img", {
                    src: showAllLocations.value ? _unref(minus) : _unref(plus),
                    alt: "plus icon",
                    class: "add-icon"
                  }, null, 8, _hoisted_13),
                  (!showAllLocations.value)
                    ? (_openBlock(), _createElementBlock("p", _hoisted_14, _toDisplayString(_ctx.newRooms
                ? _unref(t)("label.showRoomsInOldLocations")
                : _unref(t)("label.showRoomsInNewLocations")), 1))
                    : (_openBlock(), _createElementBlock("p", _hoisted_15, _toDisplayString(_ctx.newRooms
                ? _unref(t)("label.hideRoomsInOldLocations")
                : _unref(t)("label.hideRoomsInNewLocations")), 1))
                ]))
              : _createCommentVNode("", true),
            _createTextVNode(" " + _toDisplayString(_ctx.inAddress), 1)
          ]),
          _: 1
        }, 8, ["options", "placeholder", "reduce", "append-to-body", "isLoading", "modelValue", "filterBy"]),
        (isLoading.value)
          ? (_openBlock(), _createElementBlock("div", _hoisted_16, [
              _createVNode(LoaderSpinner)
            ]))
          : _createCommentVNode("", true)
      ]))
}
}

})