import { defineComponent as _defineComponent } from 'vue'
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, unref as _unref, createElementVNode as _createElementVNode, withCtx as _withCtx, resolveDirective as _resolveDirective, withDirectives as _withDirectives, normalizeClass as _normalizeClass, createVNode as _createVNode, toDisplayString as _toDisplayString } from "vue"
import _imports_0 from '../../../assets/img/square-disabled.svg'
import _imports_1 from '@/assets/img/pen.svg'


const _hoisted_1 = {
  key: 0,
  class: "me-4"
}
const _hoisted_2 = ["alt", "onClick"]
const _hoisted_3 = {
  key: 0,
  class: "blurred-img"
}
const _hoisted_4 = { key: 1 }
const _hoisted_5 = ["alt"]
const _hoisted_6 = {
  key: 0,
  class: "blurred-img"
}
const _hoisted_7 = ["alt"]
const _hoisted_8 = {
  key: 0,
  class: "blurred-img"
}
const _hoisted_9 = ["innerHTML"]
const _hoisted_10 = {
  key: 2,
  class: "div d-flex align-items-center justify-content-end w-100"
}
const _hoisted_11 = { class: "text-tiny column-text" }
const _hoisted_12 = {
  key: 0,
  class: "col-2 d-flex align-items-center justify-content-end ps-0 pe-2"
}
const _hoisted_13 = { class: "text-tiny me-1 pt-1" }

import CustomSelect from "@/components/common/CustomSelect.vue";
import GreenifiedButton from "@/components/common/GreenifiedButton.vue";
import GreenifiedCheckbox from "@/components/common/GreenifiedCheckbox.vue";
import InformationTooltip from "@/components/common/InformationTooltip.vue";
// eslint-disable-next-line @typescript-eslint/no-unused-vars, no-unused-vars
import { SelectableAction } from "@/library/helpers/structureTableColumns";

import { Dimensions } from "@/library/types/product/productItem";
import {
  ColumnActions,
  TableColumns,
  TableTypes
} from "@/library/types/tables/columns";
import { ref, Ref } from "vue";
import { useI18n } from "vue-i18n";

type MainTableRowProps = {
  tableColumns: TableColumns["columns"];
  thinRows?: boolean;
  rowImage?: string;
  showCheckbox?: boolean;
  toggleSubTable?: boolean;
  rowIsChecked?: boolean;
  type: TableTypes;
  rowAction?: ColumnActions[];
  disabled?: boolean;
  rowStatus?: string | null;
  defaultSelectedAction?: string | null;
  buttonText?: string | null;
  filteredAmount?: number | null;
  itemLength?: number;
};


export default /*@__PURE__*/_defineComponent({
  __name: 'MainTableRow',
  props: {
    tableColumns: {},
    thinRows: { type: Boolean, default: false },
    rowImage: { default: "" },
    showCheckbox: { type: Boolean, default: false },
    toggleSubTable: { type: Boolean, default: false },
    rowIsChecked: { type: Boolean, default: false },
    type: { default: "standard" },
    rowAction: { default: () => ["handleVariant"] },
    disabled: { type: Boolean, default: false },
    rowStatus: { default: "" },
    defaultSelectedAction: { default: null },
    buttonText: { default: null },
    filteredAmount: { default: null },
    itemLength: { default: 0 }
  },
  emits: [
  "showSubTable",
  "selectRow",
  "showProductInformation",
  "purchaseProduct",
  "selectAction",
  "editAddress",
  "delete",
  "imageError"
],
  setup(__props: any, { emit: __emit }) {

const mainTableRowProps = __props;

const { t } = useI18n();

const emit = __emit;

const selectedAction: Ref<string | null> = ref(
  mainTableRowProps.defaultSelectedAction
);

const getColumnValue = (
  columnKey: string,
  columnValue: string | number | Dimensions
) => {
  const quantityKeys = ["antal", "quantity", "sell_antal", "ange-antal"];
  const currentItemLength = mainTableRowProps.itemLength;
  const filteredQuantity = mainTableRowProps.filteredAmount;
  if (
    filteredQuantity &&
    quantityKeys.includes(columnKey) &&
    (typeof columnValue === "number" || typeof columnValue === "string") &&
    Number(columnValue) !== filteredQuantity &&
    Number(columnValue)
  ) {
    if (columnKey === "sell_antal" || columnKey === "ange-antal") {
      if (Number(columnValue) > currentItemLength) {
        return t("label.sellByGroupFinal", {
          quantity: `${currentItemLength} ${
            filteredQuantity !== currentItemLength
              ? `(${filteredQuantity})`
              : ""
          }`
        });
      }
      return t("label.sellByGroup", {
        quantity: `${currentItemLength} ${
          filteredQuantity !== currentItemLength ? `(${filteredQuantity})` : ""
        }`,
        group: columnValue
      });
    }
    return `${columnValue} (${filteredQuantity})`;
  }

  return columnValue;
};

return (_ctx: any,_cache: any) => {
  const _directive_lazy = _resolveDirective("lazy")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["row ms-0 me-0", [
      _ctx.thinRows ? 'thin-table-row' : 'table-row',
      _ctx.toggleSubTable && 'sticky-row'
    ]])
  }, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.tableColumns, (columnValue, columnKey) => {
      return (_openBlock(), _createElementBlock(_Fragment, { key: columnKey }, [
        (
          (columnValue && columnKey !== 'actions') ||
          (columnKey === 'actions' && !_ctx.rowStatus)
        )
          ? (_openBlock(), _createElementBlock("div", {
              key: 0,
              class: _normalizeClass(["d-flex align-items-center cursor-default ps-0 pe-0", [columnValue.columnStyling, _ctx.disabled && 'blurred']])
            }, [
              (columnValue.actions.includes('checkbox') && _ctx.showCheckbox)
                ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
                    (columnValue.isEditable || _ctx.type === 'internalSold')
                      ? (_openBlock(), _createBlock(GreenifiedCheckbox, {
                          key: 0,
                          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (emit('selectRow'))),
                          isChecked: _ctx.rowIsChecked
                        }, null, 8, ["isChecked"]))
                      : (_openBlock(), _createBlock(InformationTooltip, {
                          key: 1,
                          "info-text": _unref(t)('paragraph.advertisementInformation')
                        }, {
                          icon: _withCtx(() => _cache[12] || (_cache[12] = [
                            _createElementVNode("img", {
                              src: _imports_0,
                              alt: "disabled checkbox"
                            }, null, -1)
                          ])),
                          _: 1
                        }, 8, ["info-text"]))
                  ]))
                : _createCommentVNode("", true),
              (columnKey === 'benamn')
                ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                    (_ctx.type !== 'other' && _ctx.type !== 'purchase')
                      ? (_openBlock(), _createBlock(InformationTooltip, {
                          key: 0,
                          "info-text": 
              !columnValue.isEditable
                ? _unref(t)('paragraph.advertisementInformation')
                : _unref(t)('title.productInformation')
            
                        }, {
                          icon: _withCtx(() => [
                            _createElementVNode("div", {
                              class: _normalizeClass(["me-2 position-relative", [
                  _ctx.thinRows ? 'small-image cursor-default' : 'product-image',
                  !columnValue.isEditable && 'cursor-default'
                ]])
                            }, [
                              _withDirectives(_createElementVNode("img", {
                                alt: `Product image for ${columnValue.value}`,
                                onClick: ($event: any) => (
                    columnValue.isEditable && emit('showProductInformation')
                  ),
                                onError: _cache[1] || (_cache[1] = ($event: any) => (emit('imageError')))
                              }, null, 40, _hoisted_2), [
                                [_directive_lazy, _ctx.rowImage]
                              ]),
                              (_ctx.disabled)
                                ? (_openBlock(), _createElementBlock("div", _hoisted_3))
                                : _createCommentVNode("", true)
                            ], 2)
                          ]),
                          _: 2
                        }, 1032, ["info-text"]))
                      : (_openBlock(), _createElementBlock("div", _hoisted_4, [
                          _createElementVNode("div", {
                            class: _normalizeClass(["me-2 position-relative cursor-default", [
                _ctx.thinRows ? 'small-image cursor-default' : 'product-image'
              ]])
                          }, [
                            _withDirectives(_createElementVNode("img", {
                              alt: `Product image for ${columnValue.value}`,
                              onError: _cache[2] || (_cache[2] = ($event: any) => (emit('imageError')))
                            }, null, 40, _hoisted_5), [
                              [_directive_lazy, _ctx.rowImage]
                            ]),
                            (_ctx.disabled)
                              ? (_openBlock(), _createElementBlock("div", _hoisted_6))
                              : _createCommentVNode("", true)
                          ], 2)
                        ]))
                  ], 64))
                : _createCommentVNode("", true),
              (columnKey === 'usr')
                ? (_openBlock(), _createElementBlock("div", {
                    key: 2,
                    class: _normalizeClass(["me-2 position-relative", [
            columnKey === 'usr'
              ? 'avatar-image-wrapper'
              : 'small-image cursor-default',
            !columnValue.isEditable && 'cursor-default'
          ]])
                  }, [
                    _withDirectives(_createElementVNode("img", {
                      class: _normalizeClass('avatar-icon'),
                      alt: `Avatar image for ${columnValue.value}`,
                      onError: _cache[3] || (_cache[3] = ($event: any) => (emit('imageError')))
                    }, null, 40, _hoisted_7), [
                      [_directive_lazy, _ctx.rowImage]
                    ]),
                    (_ctx.disabled)
                      ? (_openBlock(), _createElementBlock("div", _hoisted_8))
                      : _createCommentVNode("", true)
                  ], 2))
                : _createCommentVNode("", true),
              (!columnValue.tooltip)
                ? (_openBlock(), _createElementBlock(_Fragment, { key: 3 }, [
                    (columnKey !== 'actions')
                      ? (_openBlock(), _createElementBlock("p", {
                          key: 0,
                          class: _normalizeClass(["text-tiny column-text", columnKey === 'benamn' && 'word-wrap']),
                          innerHTML: 
              columnValue.translate
                ? _unref(t)(String(columnValue.value))
                : getColumnValue(String(columnKey), columnValue.value)
            
                        }, null, 10, _hoisted_9))
                      : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                          (
                columnValue.actions.includes('button') &&
                typeof columnValue.value === 'string'
              )
                            ? (_openBlock(), _createBlock(GreenifiedButton, {
                                key: 0,
                                class: _normalizeClass(columnValue.actions.length > 1 && 'me-1'),
                                onClick: _cache[4] || (_cache[4] = ($event: any) => (emit('selectAction'))),
                                text: columnValue.value,
                                size: 'thin',
                                noWrap: ""
                              }, null, 8, ["class", "text"]))
                            : _createCommentVNode("", true),
                          (columnValue.actions.includes('purchase'))
                            ? (_openBlock(), _createBlock(GreenifiedButton, {
                                key: 1,
                                class: _normalizeClass(columnValue.actions.length > 1 && 'me-1'),
                                onClick: _cache[5] || (_cache[5] = ($event: any) => (emit('purchaseProduct'))),
                                text: _unref(t)('button.order'),
                                size: _ctx.type === 'sale' ? 'full' : 'thin',
                                btnStyle: 'green'
                              }, null, 8, ["class", "text", "size"]))
                            : _createCommentVNode("", true),
                          (columnValue.actions.includes('select'))
                            ? (_openBlock(), _createBlock(CustomSelect, {
                                key: 2,
                                class: _normalizeClass(["w-75", columnValue.actions.length > 1 && 'me-1']),
                                hasSelectableOptions: 
                columnValue.selectOptions.some(option => option.selectable)
              ,
                                options: columnValue.selectOptions,
                                modelValue: selectedAction.value,
                                "onUpdate:modelValue": [
                                  _cache[6] || (_cache[6] = ($event: any) => ((selectedAction).value = $event)),
                                  _cache[7] || (_cache[7] = ($event: any) => (emit('selectAction', selectedAction.value)))
                                ],
                                label: "label",
                                value: selectedAction.value,
                                clearable: false,
                                placeholder: _unref(t)('placeholders.action'),
                                keepPlaceholder: _ctx.type === 'sale',
                                reducedKey: 'value',
                                selectable: (option) => option.selectable
                              }, null, 8, ["class", "hasSelectableOptions", "options", "modelValue", "value", "placeholder", "keepPlaceholder", "selectable"]))
                            : _createCommentVNode("", true),
                          (columnValue.actions.includes('handleVariant'))
                            ? (_openBlock(), _createBlock(GreenifiedButton, {
                                key: 3,
                                onClick: _cache[8] || (_cache[8] = ($event: any) => (emit('showSubTable'))),
                                noWrap: "",
                                text: 
                !_ctx.toggleSubTable
                  ? _unref(t)(`${_ctx.buttonText ? _ctx.buttonText : 'button.handleProducts'}`)
                  : _unref(t)('button.hideProducts')
              ,
                                size: columnValue.actions.length > 1 ? 'small' : 'medium',
                                btnStyle: _ctx.toggleSubTable ? 'black' : 'normal'
                              }, null, 8, ["text", "size", "btnStyle"]))
                            : _createCommentVNode("", true),
                          (columnValue.actions.includes('delete'))
                            ? (_openBlock(), _createBlock(GreenifiedButton, {
                                key: 4,
                                size: "thin",
                                text: _unref(t)('button.remove'),
                                btnStyle: "black",
                                onClick: _cache[9] || (_cache[9] = ($event: any) => (emit('delete')))
                              }, null, 8, ["text"]))
                            : _createCommentVNode("", true)
                        ], 64)),
                    (_ctx.type === 'other' && columnValue.actions.includes('edit'))
                      ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
                          _createElementVNode("img", {
                            class: "btn g-0",
                            src: _imports_1,
                            onClick: _cache[10] || (_cache[10] = ($event: any) => (emit('editAddress')))
                          }),
                          _createVNode(GreenifiedButton, {
                            size: "thin",
                            text: _unref(t)('button.remove'),
                            onClick: _cache[11] || (_cache[11] = ($event: any) => (emit('delete')))
                          }, null, 8, ["text"])
                        ]))
                      : _createCommentVNode("", true)
                  ], 64))
                : (_openBlock(), _createBlock(InformationTooltip, {
                    key: 4,
                    infoText: columnValue.tooltip.tip,
                    tooltipWidth: 'small'
                  }, {
                    icon: _withCtx(() => [
                      _createElementVNode("p", _hoisted_11, _toDisplayString(columnValue.tooltip.text), 1)
                    ]),
                    _: 2
                  }, 1032, ["infoText"]))
            ], 2))
          : _createCommentVNode("", true)
      ], 64))
    }), 128)),
    (_ctx.rowStatus)
      ? (_openBlock(), _createElementBlock("div", _hoisted_12, [
          _createElementVNode("p", _hoisted_13, _toDisplayString(_ctx.rowStatus), 1),
          (_ctx.rowAction.includes('tooltip'))
            ? (_openBlock(), _createBlock(InformationTooltip, {
                key: 0,
                infoText: 
          _unref(t)('paragraph.productIsRemovedInfo') +
          '</br>' +
          _unref(t)('paragraph.forQuestionsContact')
        ,
                bgColor: "gr-color"
              }, null, 8, ["infoText"]))
            : _createCommentVNode("", true)
        ]))
      : _createCommentVNode("", true)
  ], 2))
}
}

})