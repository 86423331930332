<template>
  <div
    class="container-fluid p-0 mb-4"
    :key="indexStore.currentProjectNumber"
    :class="currentRoute.meta.middleware === 'auth' && 'pt-5'"
  >
    <div class="row">
      <div class="col">
        <p class="subheading-large">{{ filterTitle }}</p>
      </div>
    </div>
    <div class="row mt-1 align-items-end">
      <div :class="hideFilterOptions ? 'col-12' : 'col-6'">
        <p
          class="text-small mt-2"
          :class="[!hideFilterOptions && 'filter-text']"
          v-html="filterText"
        ></p>
        <div class="d-flex mt-4" v-if="filterButtons.length">
          <template v-for="(button, index) in filterButtons" :key="index">
            <GreenifiedButton
              v-if="button.show"
              class="me-3"
              size="thin"
              :text="
                button.amount !== null
                  ? `${t(button.text)} (${button.amount})`
                  : t(button.text)
              "
              :btnStyle="filterTab === index + 1 ? 'yellow-active' : 'yellow'"
              @click="emit('update:filterTab', index + 1)"
            />
          </template>
        </div>
      </div>

      <div
        class="col-6 d-flex justify-content-end align-items-end"
        v-if="hasProducts"
      >
        <div class="me-2" v-if="showUrlButton">
          <GreenifiedButton
            size="thin"
            :text="t('button.shareURL')"
            @click="showUrlModal = true"
          />
        </div>
        <div class="me-2" v-if="pdfRoute">
          <GreenifiedButton
            size="medium"
            :text="pdfIsLoading ? t('button.loading') : t('button.lists')"
            :disabled="pdfIsLoading"
            @click="
              () => {
                if (!pdfRoute) return;

                if (pdfRoute === 'recycle') {
                  return downloadPdfFromUrl();
                }

                if (pdfRoute === 'internal-order') {
                  showOrderOptions = true;

                  return;
                }

                if (pdfRoutesForDownloadOptions.includes(pdfRoute)) {
                  showDownloadOptions = true;
                  return;
                }

                return downloadPdf(pdfRoute);
              }
            "
          />
        </div>

        <SearchBar
          v-model:defaultFilter="currentFilter"
          :productStatus="productStatus[0]"
          @update:modelValue="
            () => {
              if (productStatus[0] === 'I') return;
              extendedSearchFilters =
                setExtendedSearchFilters(currentFilter.categories) ?? null;
            }
          "
        />
        <GreenifiedButton
          class="ms-2 position-relative"
          :class="openFilter && 'filter-button'"
          :size="'thin'"
          :btnStyle="'yellow'"
          smallerPadding
          @click="
            openFilter = !openFilter;
            showSort = false;
          "
        >
          <template #content>
            <div class="d-flex">
              <p class="cursor-pointer">{{ t("button.filter") }}</p>
              <img class="ms-2" src="@/assets/img/filter.svg" />
            </div>
            <CounterIcon
              v-if="
                Object.keys(currentFilter.categories)?.length ||
                Object.keys(currentFilter.intervals)?.length
              "
              :positionX="'right'"
              :positionY="'bottom'"
              :counter="getFilterAmount(currentFilter)"
            />
          </template>
        </GreenifiedButton>

        <SortingDropdown
          class="ms-2"
          :sortingSettings="sortingSettings"
          :sortOptions="sortingOptions"
          @setSort="(sortingOption: SortingDropdownOption) =>emit('update:defaultSortingSettings', sortingOption)"
        />
      </div>
    </div>
    <div v-if="openFilter" class="gr-color px-4 py-5 mt-2">
      <div class="row">
        <div class="col-3" v-for="(obj, key) in dropdownOptions" :key="key">
          <CustomSelect
            :options="sortFilterOptions(obj, key)"
            :clearable="false"
            :closeOnSelect="false"
            :is-loading="loadingOptions"
            class="placeholder-dark"
            :placeholder="t(`label.${key}`)"
            label="nyckel"
            :filterBy="
            (filterOption: LocalVueSelectOption, label: string, search: string) => {
              const currFilterOption = filterOption as TranslatedFilter
              return (
                (translateHeaders.includes(currFilterOption.kol)
                  ? t(currFilterOption.kol + '.' + currFilterOption.nyckel)
                  : currFilterOption.sokvarde
                )
                  .toLocaleLowerCase()
                  .indexOf(search.toLocaleLowerCase()) > -1
              );
            }"
          >
            <template #selected-option>
              <div>{{ t("label." + key) }}</div>
            </template>
            <template #option="option: { option: TranslatedFilter }">
              <div
                class="d-flex w-100 justify-content-between"
                @click="
                  () => {
                    const optionObject = {
                      group: option.option.group ?? false,
                      key: option.option.kol,
                      value: option.option.nyckel,
                      text: option.option.text,
                      col: '',
                      nyckel: '',
                      sokvarde: '',
                      sort: '',
                      kol: '',
                      projekt: ''
                    };
                    toggleFilter(optionObject);
                  }
                "
              >
                <div v-if="option.option.group" style="color: black">
                  {{ option.option.text }}
                </div>
                <div
                  v-else
                  :style="
                    option.option.kol === 'kategori_id'
                      ? 'margin-left:20px'
                      : ''
                  "
                >
                  {{
                    translateHeaders.includes(option.option.kol)
                      ? t(option.option.kol + "." + option.option.nyckel)
                      : option.option.text
                  }}
                </div>
                <img
                  v-show="
                    Object.keys(currentFilter.categories).includes(
                      option.option.kol
                    ) &&
                    toggleCheckedFilter(
                      currentFilter.categories,
                      option.option.kol,
                      option.option.nyckel
                    )
                  "
                  src="@/assets/img/checkOnly2.svg"
                />
              </div>
            </template>
          </CustomSelect>
        </div>
      </div>
      <div class="row mt-4">
        <div class="col-6 col-xl-3">
          <div class="mx-2">
            <p class="text-tiny fw-bold mb-2">{{ t("label.antal") }}</p>
            <DoubleRangeSlider
              ref="rangeSlider"
              :key="indexStore.maxQuantity"
              :maxValue="indexStore.maxQuantity"
              v-model:intervalsSettings="currentIntervalSettings"
              @update:intervalsSettings="
                currentFilter.intervals.antal = currentIntervalSettings;
                emit('update:defaultFilter', currentFilter);
              "
            >
            </DoubleRangeSlider>
          </div>
        </div>
      </div>
    </div>
    <div class="row mt-3">
      <FilterBreadcrumbs
        v-model:filterOptions="currentFilter"
        :translateHeaders="translateHeaders"
        @update:filterOptions="
          () => {
            if (!Object.keys(currentFilter.categories).length) {
              showExtendedFilteredProducts = false;
              extendedSearchFilters = null;
            }
            if (!Object.keys(currentFilter.intervals).length) {
              currentIntervalSettings = {
                to: indexStore.maxQuantity,
                from: 1
              };
              rangeSlider?.resetIntervalRange();
            }
            emit('update:defaultFilter', currentFilter);
          }
        "
      />
    </div>

    <ExtendedFilteredProducts
      v-if="showExtendedFilteredProducts && extendedSearchFilters"
      :extendedSearchFilters="extendedSearchFilters"
      :productStatus="productStatus"
    />
  </div>

  <PdfModal
    v-if="showDownloadOptions && pdfRoute"
    @close="showDownloadOptions = false"
    :pdfRoute="pdfRoute"
  />

  <UrlModal v-if="showUrlModal" @close="showUrlModal = false" />

  <PackingListModal
    v-if="showOrderOptions"
    @close="showOrderOptions = false"
    :orderStatus="orderStatus"
  />
</template>

<script setup lang="ts">
import {
  withDefaults,
  defineProps,
  defineEmits,
  ref,
  Ref,
  computed,
  onMounted,
  watch
} from "vue";
import {
  FilterOptions,
  FilterSortOption
} from "../../library/types/filter/filter";
import { SortingSettings } from "../../library/types/sorting/sorting";
import { I18nMessage, PdfList } from "../../library/types/locales/i18nMessage";
import { useRouter } from "vue-router";
import { useIndexStore } from "@/store";
import { ExtendedSearchFilters, getSearchTable } from "@/library/api/search";
import { SearchTable, TranslatedFilter } from "@/services/api/searchApi";
import SearchBar from "../common/SearchBar.vue";
import GreenifiedButton from "../common/GreenifiedButton.vue";
import CounterIcon from "../common/CounterIcon.vue";
import SortingDropdown, {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars, no-unused-vars
  SortingDropdownOption
} from "../common/SortingDropdown.vue";
// eslint-disable-next-line @typescript-eslint/no-unused-vars, no-unused-vars
import CustomSelect, { LocalVueSelectOption } from "../common/CustomSelect.vue";
import DoubleRangeSlider from "../common/DoubleRangeSlider.vue";
import FilterBreadcrumbs from "../common/FilterBreadcrumbs.vue";
import { ProductStatuses } from "@/library/types/product/productHeader";
import ExtendedFilteredProducts from "../ExtendedFilteredProducts.vue";
import { getPdfFromRoute, getPdfFromUrl } from "@/library/api/media";
import PdfModal, { PdfRoute } from "../modal/PdfModal.vue";
import UrlModal from "../modal/UrlModal.vue";
import { useI18n } from "vue-i18n";
import { useAuthStore } from "@/store/auth";
import PackingListModal from "../modal/PackingListModal.vue";
import { OrderStatuses } from "@/services/api/salesApi";

export type FilterButton = {
  text: string;
  amount: number | null;
  show: boolean;
};

type MainFilterProps = {
  pdfRoute?: PdfRoute | null;
  orderStatus?: OrderStatuses;
  productStatus?: ProductStatuses[];
  filterTitle: string;
  filterText: string;
  filterQuery?: string;
  defaultFilter?: FilterOptions;
  sortingOptions?: FilterSortOption[];
  translateHeaders?: string[];
  defaultSortingSettings?: SortingSettings;
  hasProducts?: boolean;
  filterButtons?: FilterButton[];
  filterTab?: number;
  showUrlButton?: boolean;
  hideFilterOptions?: boolean;
};

const mainFilterProps = withDefaults(defineProps<MainFilterProps>(), {
  pdfRoute: null,
  orderStatus: "A",
  productStatus: () => ["J"],
  filterTitle: "",
  filterText: "",
  filterQuery: "",
  defaultFilter: () => ({
    categories: {},
    intervals: {}
  }),
  sortingOptions: () => [
    {
      name: "label.name",
      descendingLocale: "label.ZToA",
      ascendingLocale: "label.AToZ",
      colValue: { col: "benamn" }
    },
    {
      name: "label.quantity",
      descendingLocale: "label.desc",
      ascendingLocale: "label.asc",
      colValue: { col: "antal" }
    },
    {
      name: "",
      descendingLocale: "label.reg_dat",
      ascendingLocale: "label.reg_datAsc",
      colValue: { col: "reg_dat" }
    },
    {
      name: "",
      descendingLocale: "label.latestUpdate",
      ascendingLocale: "label.oldestUpdate",
      colValue: { col: "updated_at" }
    }
  ],
  translateHeaders: () => [],
  defaultSortingSettings: () => ({ col: "", asc: true }),
  hasProducts: false,
  filterButtons: () => [],
  filterTab: 1,
  showUrlButton: false,
  hideFilterOptions: false
});

const showOrderOptions = ref(false);
const rangeSlider = ref<InstanceType<typeof DoubleRangeSlider>>();
const indexStore = useIndexStore();
const { t, messages, locale } = useI18n();
const emit = defineEmits([
  "update:defaultSortingSettings",
  "update:defaultFilter",
  "update:filterTab"
]);

const showUrlModal = ref(false);
const pdfRoutesForDownloadOptions: PdfRoute[] = [
  "repurpose-overview",
  "recondition"
];
const currentIntervalSettings = ref({
  to: indexStore.maxQuantity,
  from: 1
});
const showDownloadOptions = ref(false);
const loadingOptions = ref(false);
const pdfIsLoading = ref(false);
const showExtendedFilteredProducts = ref(false);
const extendedSearchFilters: Ref<ExtendedSearchFilters | null> = ref(null);

const { currentRoute } = useRouter();
const sortingSettings: Ref<SortingSettings> = ref(
  mainFilterProps.defaultSortingSettings
);

const currentFilter = ref(mainFilterProps.defaultFilter);
const dropdownOptions: Ref<{ [key: string]: SearchTable[] }> = ref({
  kategori_id: [],
  levkod: [],
  rum: [],
  skick2: []
});
const openFilter = ref(false);
const showSort = ref(false);
const currentProject = computed(() => {
  return indexStore.currentProjectNumber;
});

const getFilterAmount = (filter: FilterOptions) => {
  let categoryAmount = 0;
  let intervalAmount = Object.keys(filter.intervals).length;
  Object.keys(filter.categories).forEach(
    categoryKey => (categoryAmount += filter.categories[categoryKey].length)
  );

  return intervalAmount + categoryAmount;
};

const setExtendedSearchFilters = (
  filterCategories: FilterOptions["categories"]
) => {
  if (!currentProject.value || !useAuthStore().isAuthenticated) return;
  showExtendedFilteredProducts.value = false;
  const searchFilters: ExtendedSearchFilters = {};

  for (const filterKey in filterCategories) {
    filterCategories[filterKey].forEach(categoryKey => {
      const searchKey =
        categoryKey.key === "hit_on" ? "search" : categoryKey.key;
      if (Object.keys(searchFilters).includes(searchKey)) {
        searchFilters[searchKey].push(categoryKey.value);
      } else {
        searchFilters[searchKey] = [categoryKey.value];
      }
    });
  }
  showExtendedFilteredProducts.value = true;
  return searchFilters;
};

const toggleCheckedFilter = (
  categories: FilterOptions["categories"],
  categoryKey: string,
  categoryValue: string
) => {
  return categories[categoryKey].find(f => f.value === categoryValue)
    ? true
    : false;
};

const getMainCategories = (): TranslatedFilter[] => {
  const mainCategories = [];
  const sourceObject = messages.value[locale.value.toLocaleLowerCase()]
    .huvud_id as I18nMessage["huvud_id"];

  for (const key in sourceObject) {
    mainCategories.push({
      nyckel: key,
      text: sourceObject[key],
      sokvarde: sourceObject[key],
      group: true,
      sort: key + ".0",
      kol: "main_category",
      key: key,
      projekt: indexStore.currentProjectNumber,
      value: ""
    });
  }
  return mainCategories;
};

const sortFilterOptions = (
  filterOptions: SearchTable[],
  filterKey: string | number
) => {
  if (filterKey === "kategori_id") {
    const translatedFilters = filterOptions.map(filterOption => {
      if (filterOption)
        filterOption["text"] = (
          mainFilterProps.translateHeaders.includes(filterOption.kol)
            ? t(filterOption.kol + "." + filterOption.nyckel)
            : filterOption.text
        ).toLocaleLowerCase();
      return filterOption;
    });
    const mergedFilter = [...getMainCategories(), ...translatedFilters];
    return mergedFilter.sort((a, b) => {
      return parseInt(a.sort) - parseInt(b.sort);
    });
  }

  if (filterKey === "levkod") {
    const filterSuppliers = filterOptions
      .filter(filterSupplier => {
        return !/^ex\d{1,2}/.test(filterSupplier.nyckel);
      })
      .concat([
        {
          nyckel: "ex",
          text: t("levkod.ex"),
          kol: "levkod",
          projekt: "10007",
          sokvarde: "",
          sort: ""
        }
      ])
      .map(supplierOption => {
        supplierOption["text"] = (
          mainFilterProps.translateHeaders.includes(supplierOption.kol)
            ? t(supplierOption.kol + "." + supplierOption.nyckel)
            : supplierOption.text
        ).toLocaleLowerCase();
        return supplierOption;
      });
    return filterSuppliers.sort((a, b) => {
      return a.text?.localeCompare(b.text);
    });
  }
  const currentTranslatedFilters = filterOptions.map(currOption => {
    currOption["text"] = (
      mainFilterProps.translateHeaders.includes(currOption.kol)
        ? t(currOption.kol + "." + currOption.nyckel)
        : currOption.text
    ).toLocaleLowerCase();
    return currOption;
  });
  return currentTranslatedFilters.sort((a, b) => {
    return a.text?.localeCompare(b.text);
  });
};

const toggleFilter = (filterOption: TranslatedFilter) => {
  const categoryKeys = Object.keys(currentFilter.value.categories);
  if (categoryKeys.includes(filterOption.key)) {
    const categoryIndex = currentFilter.value.categories[
      filterOption.key
    ].findIndex(category => category.value === filterOption.value);
    if (categoryIndex !== -1) {
      currentFilter.value.categories[filterOption.key].splice(categoryIndex, 1);
      if (!currentFilter.value.categories[filterOption.key].length) {
        delete currentFilter.value.categories[filterOption.key];
      }
    } else {
      currentFilter.value.categories[filterOption.key].push({
        key: filterOption.key,
        value: filterOption.value
      });
    }
  } else {
    currentFilter.value.categories[filterOption.key] = [
      { key: filterOption.key, value: filterOption.value }
    ];
  }

  emit("update:defaultFilter", currentFilter.value);
};

const getDropdownData = async () => {
  if (!currentRoute.value.meta.hasFilter) return;

  loadingOptions.value = true;

  const result = await getSearchTable(
    ["kategori_id", "levkod", "skick2", "rum"],
    currentRoute.value.params.token as string
  );
  loadingOptions.value = false;

  if (!result.success) return;

  dropdownOptions.value = result.result;
};

const downloadPdf = async (reportType: string) => {
  pdfIsLoading.value = true;

  const result = await getPdfFromRoute(reportType);
  pdfIsLoading.value = false;

  if (!result.success) return;

  let blob = new Blob([result.result], { type: "application/pdf" }),
    url = window.URL.createObjectURL(blob);
  window.open(url);
};

const downloadPdfFromUrl = async () => {
  pdfIsLoading.value = true;

  const currentList = localeRecycleLists()[0];

  const pdfUrl = currentList.url
    .replace(/\{projectNr\}/g, indexStore.currentProjectNumber)
    .replace(/\{note\}/g, " ");

  const result = await getPdfFromUrl(pdfUrl);

  if (!result.success) return;

  const blob = new Blob([result.result], { type: "application/pdf" }),
    url = window.URL.createObjectURL(blob);

  window.open(url);
  pdfIsLoading.value = false;
};

const localeRecycleLists = (): PdfList[] => {
  const recycleLists = (
    messages.value[locale.value.toLocaleLowerCase()].list as I18nMessage["list"]
  ).recycleLists;
  return Object.keys(recycleLists).map(
    localeKey =>
      recycleLists[localeKey as keyof I18nMessage["list"]["recycleLists"]]
  );
};

onMounted(() => {
  getDropdownData();
  if (mainFilterProps.filterQuery) {
    const parsedQuery = JSON.parse(mainFilterProps.filterQuery);
    for (const key in parsedQuery) {
      const keyExists = Object.keys(currentFilter.value.categories).includes(
        key
      );
      if (keyExists) {
        currentFilter.value.categories[key].push({
          key: key,
          value: parsedQuery[key]
        });
      } else {
        const newFilterKey = key === "hit_on" ? "search" : key;
        currentFilter.value.categories[newFilterKey] = [
          { key: newFilterKey, value: parsedQuery[key] }
        ];
      }
    }
  }
  window.addEventListener("keydown", (event: KeyboardEvent) => {
    if (Number(event.code) === 38) {
      event.preventDefault();
    }
  });
});

watch(
  () => currentProject.value,
  () => {
    currentFilter.value.categories = {};
    currentFilter.value.intervals = {};

    openFilter.value = false;
    getDropdownData();
  }
);

watch(
  () => indexStore.maxQuantity,
  maxQuantity => {
    currentIntervalSettings.value = {
      to: maxQuantity,
      from: 1
    };
  }
);
</script>

<style scoped>
.filter-text {
  max-width: 35rem;
}

.filter-button {
  position: absolute;
  top: 10px;
  height: 2.6rem;
  padding-bottom: 10px;
}
</style>
