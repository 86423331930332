<template>
  <div class="container-fluid ps-0 pe-0">
    <div
      class="row ms-0 me-0 d-flex table-header mt-0 align-items-center"
      @click="
        () => {
          if (!isExpandable) return;

          toggleAccordion = !toggleAccordion;
          if (toggleAccordion) {
            emit('expandedField', projectType);
          }
        }
      "
    >
      <div class="col-8 ps-0">{{ title }}</div>
      <div class="col-4 d-flex justify-content-end" v-if="isExpandable">
        <RotateArrow size="small" :rotate="toggleAccordion" />
      </div>
    </div>
    <AccordionContainer
      :toggleAccordion="toggleAccordion"
      :backgroundColor="accordionBg"
    >
      <template #content>
        <div
          class="row table-item align-items-center"
          v-for="project in projects"
          :key="project.projektnr"
        >
          <div class="col-8">
            <p>
              {{ project.projektbenamn }}
            </p>
          </div>
          <div class="col-4">
            <GreenifiedButton
              :disabled="
                indexStore.currentProjectNumber === project.projektnr ||
                (isLoading && project.projektnr === currentProject)
              "
              :btnStyle="'green'"
              @click="setProject(project.projektnr)"
              :text="
                isLoading && project.projektnr === currentProject
                  ? t('button.loading')
                  : indexStore.currentProjectNumber !== project.projektnr
                  ? t('button.select')
                  : t('button.selected')
              "
            />
          </div>
        </div>
      </template>
    </AccordionContainer>
  </div>
</template>

<script setup lang="ts">
import RotateArrow from "../common/RotateArrow.vue";
import AccordionContainer from "../common/AccordionContainer.vue";
import { SelectableProject } from "../../library/types/project/project";
import { defineEmits, ref, watch, withDefaults, defineProps } from "vue";
import { useI18n } from "vue-i18n";
import { useIndexStore } from "@/store";
import GreenifiedButton from "../common/GreenifiedButton.vue";

type ProjectListProps = {
  projects: SelectableProject[];
  title: string;
  isCollapsed?: boolean;
  projectType: number;
  accordionBg?: string;
  isExpandable?: boolean;
};

const projectListProps = withDefaults(defineProps<ProjectListProps>(), {
  projects: () => [],
  title: "",
  isCollapsed: false,
  projectType: 1,
  accordionBg: "white",
  isExpandable: true
});

const { t, messages, locale } = useI18n();
const currentProject = ref("");
const isLoading = ref(false);
const indexStore = useIndexStore();
const toggleAccordion = ref(!projectListProps.isCollapsed);
const emit = defineEmits(["projectIsSelected", "expandedField", "isLoading"]);

const setProject = async (projectNumber: string) => {
  currentProject.value = projectNumber;
  isLoading.value = true;
  emit("isLoading", isLoading.value);
  const result = await indexStore.changeProject(
    projectNumber,
    messages.value[locale.value]
  );
  isLoading.value = false;

  if (result !== "success") return;

  emit("isLoading", isLoading.value);

  emit("projectIsSelected");
};

watch(
  () => projectListProps.isCollapsed,
  collapsed => {
    if (collapsed) {
      toggleAccordion.value = false;
    }
  }
);
</script>

<style scoped>
.modal-title {
  cursor: pointer;
}

.arrow-down {
  transition: 0.3s;
}

.arrow-up {
  transform: rotate(180deg);
  transition: 0.3s;
}

.accordion-wrapper {
  width: 100%;
  overflow: hidden;
}

.accordion-content {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.4s;
}

.expanded {
  max-height: 4000px;
  transition: max-height 1.4s;
}
</style>
