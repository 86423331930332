<template>
  <div
    class="row ms-0 me-0"
    :class="[
      thinRows ? 'thin-table-row' : 'table-row',
      toggleSubTable && 'sticky-row'
    ]"
  >
    <template v-for="(columnValue, columnKey) in tableColumns" :key="columnKey">
      <div
        v-if="
          (columnValue && columnKey !== 'actions') ||
          (columnKey === 'actions' && !rowStatus)
        "
        class="d-flex align-items-center cursor-default ps-0 pe-0"
        :class="[columnValue.columnStyling, disabled && 'blurred']"
      >
        <div
          class="me-4"
          v-if="columnValue.actions.includes('checkbox') && showCheckbox"
        >
          <GreenifiedCheckbox
            v-if="columnValue.isEditable || type === 'internalSold'"
            @update:modelValue="emit('selectRow')"
            :isChecked="rowIsChecked"
          />
          <InformationTooltip
            v-else
            :info-text="t('paragraph.advertisementInformation')"
            ><template #icon>
              <img
                src="../../../assets/img/square-disabled.svg"
                alt="disabled checkbox"
              /> </template
          ></InformationTooltip>
        </div>
        <template v-if="columnKey === 'benamn'">
          <InformationTooltip
            v-if="type !== 'other' && type !== 'purchase'"
            :info-text="
              !columnValue.isEditable
                ? t('paragraph.advertisementInformation')
                : t('title.productInformation')
            "
          >
            <template #icon>
              <div
                class="me-2 position-relative"
                :class="[
                  thinRows ? 'small-image cursor-default' : 'product-image',
                  !columnValue.isEditable && 'cursor-default'
                ]"
              >
                <img
                  v-lazy="rowImage"
                  :alt="`Product image for ${columnValue.value}`"
                  @click="
                    columnValue.isEditable && emit('showProductInformation')
                  "
                  @error="emit('imageError')"
                />
                <div class="blurred-img" v-if="disabled"></div>
              </div> </template
          ></InformationTooltip>

          <div v-else>
            <div
              class="me-2 position-relative cursor-default"
              :class="[
                thinRows ? 'small-image cursor-default' : 'product-image'
              ]"
            >
              <img
                v-lazy="rowImage"
                :alt="`Product image for ${columnValue.value}`"
                @error="emit('imageError')"
              />
              <div class="blurred-img" v-if="disabled"></div>
            </div>
          </div>
        </template>

        <div
          v-if="columnKey === 'usr'"
          class="me-2 position-relative"
          :class="[
            columnKey === 'usr'
              ? 'avatar-image-wrapper'
              : 'small-image cursor-default',
            !columnValue.isEditable && 'cursor-default'
          ]"
        >
          <img
            v-lazy="rowImage"
            :class="'avatar-icon'"
            :alt="`Avatar image for ${columnValue.value}`"
            @error="emit('imageError')"
          />
          <div class="blurred-img" v-if="disabled"></div>
        </div>

        <template v-if="!columnValue.tooltip">
          <p
            v-if="columnKey !== 'actions'"
            class="text-tiny column-text"
            :class="columnKey === 'benamn' && 'word-wrap'"
            v-html="
              columnValue.translate
                ? t(String(columnValue.value))
                : getColumnValue(String(columnKey), columnValue.value)
            "
          ></p>
          <template v-else>
            <GreenifiedButton
              :class="columnValue.actions.length > 1 && 'me-1'"
              v-if="
                columnValue.actions.includes('button') &&
                typeof columnValue.value === 'string'
              "
              @click="emit('selectAction')"
              :text="columnValue.value"
              :size="'thin'"
              noWrap
            />

            <GreenifiedButton
              v-if="columnValue.actions.includes('purchase')"
              :class="columnValue.actions.length > 1 && 'me-1'"
              @click="emit('purchaseProduct')"
              :text="t('button.order')"
              :size="type === 'sale' ? 'full' : 'thin'"
              :btnStyle="'green'"
            />

            <CustomSelect
              class="w-75"
              :class="columnValue.actions.length > 1 && 'me-1'"
              v-if="columnValue.actions.includes('select')"
              :hasSelectableOptions="
                columnValue.selectOptions.some(option => option.selectable)
              "
              :options="columnValue.selectOptions"
              v-model="selectedAction"
              @update:modelValue="emit('selectAction', selectedAction)"
              label="label"
              :value="selectedAction"
              :clearable="false"
              :placeholder="t('placeholders.action')"
              :keepPlaceholder="type === 'sale'"
              :reducedKey="'value'"
              :selectable="(option: SelectableAction) => option.selectable"
            />

            <GreenifiedButton
              v-if="columnValue.actions.includes('handleVariant')"
              @click="emit('showSubTable')"
              noWrap
              :text="
                !toggleSubTable
                  ? t(`${buttonText ? buttonText : 'button.handleProducts'}`)
                  : t('button.hideProducts')
              "
              :size="columnValue.actions.length > 1 ? 'small' : 'medium'"
              :btnStyle="toggleSubTable ? 'black' : 'normal'"
            />

            <GreenifiedButton
              v-if="columnValue.actions.includes('delete')"
              size="thin"
              :text="t('button.remove')"
              btnStyle="black"
              @click="emit('delete')"
            />
          </template>

          <template
            v-if="type === 'other' && columnValue.actions.includes('edit')"
          >
            <div
              class="div d-flex align-items-center justify-content-end w-100"
            >
              <img
                class="btn g-0"
                src="@/assets/img/pen.svg"
                @click="emit('editAddress')"
              />
              <GreenifiedButton
                size="thin"
                :text="t('button.remove')"
                @click="emit('delete')"
              />
            </div>
          </template>
        </template>
        <template v-else>
          <InformationTooltip
            :infoText="columnValue.tooltip.tip"
            :tooltipWidth="'small'"
          >
            <template #icon>
              <p class="text-tiny column-text">
                {{ columnValue.tooltip.text }}
              </p>
            </template>
          </InformationTooltip>
        </template>
      </div>
    </template>

    <div
      class="col-2 d-flex align-items-center justify-content-end ps-0 pe-2"
      v-if="rowStatus"
    >
      <p class="text-tiny me-1 pt-1">{{ rowStatus }}</p>
      <InformationTooltip
        v-if="rowAction.includes('tooltip')"
        :infoText="
          t('paragraph.productIsRemovedInfo') +
          '</br>' +
          t('paragraph.forQuestionsContact')
        "
        bgColor="gr-color"
      />
    </div>
  </div>
</template>

<script lang="ts" setup>
import CustomSelect from "@/components/common/CustomSelect.vue";
import GreenifiedButton from "@/components/common/GreenifiedButton.vue";
import GreenifiedCheckbox from "@/components/common/GreenifiedCheckbox.vue";
import InformationTooltip from "@/components/common/InformationTooltip.vue";
// eslint-disable-next-line @typescript-eslint/no-unused-vars, no-unused-vars
import { SelectableAction } from "@/library/helpers/structureTableColumns";

import { Dimensions } from "@/library/types/product/productItem";
import {
  ColumnActions,
  TableColumns,
  TableTypes
} from "@/library/types/tables/columns";
import { withDefaults, defineProps, defineEmits, ref, Ref } from "vue";
import { useI18n } from "vue-i18n";

type MainTableRowProps = {
  tableColumns: TableColumns["columns"];
  thinRows?: boolean;
  rowImage?: string;
  showCheckbox?: boolean;
  toggleSubTable?: boolean;
  rowIsChecked?: boolean;
  type: TableTypes;
  rowAction?: ColumnActions[];
  disabled?: boolean;
  rowStatus?: string | null;
  defaultSelectedAction?: string | null;
  buttonText?: string | null;
  filteredAmount?: number | null;
  itemLength?: number;
};

const mainTableRowProps = withDefaults(defineProps<MainTableRowProps>(), {
  rowImage: "",
  thinRows: false,
  showCheckbox: false,
  toggleSubTable: false,
  rowIsChecked: false,
  type: "standard",
  rowAction: () => ["handleVariant"],
  disabled: false,
  rowStatus: "",
  defaultSelectedAction: null,
  buttonText: null,
  filteredAmount: null,
  itemLength: 0
});

const { t } = useI18n();

const emit = defineEmits([
  "showSubTable",
  "selectRow",
  "showProductInformation",
  "purchaseProduct",
  "selectAction",
  "editAddress",
  "delete",
  "imageError"
]);

const selectedAction: Ref<string | null> = ref(
  mainTableRowProps.defaultSelectedAction
);

const getColumnValue = (
  columnKey: string,
  columnValue: string | number | Dimensions
) => {
  const quantityKeys = ["antal", "quantity", "sell_antal", "ange-antal"];
  const currentItemLength = mainTableRowProps.itemLength;
  const filteredQuantity = mainTableRowProps.filteredAmount;
  if (
    filteredQuantity &&
    quantityKeys.includes(columnKey) &&
    (typeof columnValue === "number" || typeof columnValue === "string") &&
    Number(columnValue) !== filteredQuantity &&
    Number(columnValue)
  ) {
    if (columnKey === "sell_antal" || columnKey === "ange-antal") {
      if (Number(columnValue) > currentItemLength) {
        return t("label.sellByGroupFinal", {
          quantity: `${currentItemLength} ${
            filteredQuantity !== currentItemLength
              ? `(${filteredQuantity})`
              : ""
          }`
        });
      }
      return t("label.sellByGroup", {
        quantity: `${currentItemLength} ${
          filteredQuantity !== currentItemLength ? `(${filteredQuantity})` : ""
        }`,
        group: columnValue
      });
    }
    return `${columnValue} (${filteredQuantity})`;
  }

  return columnValue;
};
</script>

<style scoped lang="scss">
.blurred {
  color: rgb(162, 162, 162);
}

.blurred-img {
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(254, 254, 254, 0.65);
}

.sticky-row {
  position: sticky;
  top: 2.7rem;
  left: 0;
  background-color: white;
  z-index: 2;
}

.small-image {
  width: 2.6rem;
  height: 2.6rem;
  background-color: #f5f2eb;
  display: flex;
  cursor: pointer;

  img {
    max-width: 2.6rem;
    max-height: 2.6rem;
    margin: auto;

    object-fit: contain;
  }
}

.product-image {
  width: 3.5rem;
  height: 3.5rem;
  background-color: #f5f2eb;
  display: flex;
  cursor: pointer;

  img {
    max-width: 3.5rem;
    max-height: 3.5rem;
    margin: auto;

    object-fit: contain;
  }
}
</style>
