import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, withCtx as _withCtx, normalizeStyle as _normalizeStyle, vShow as _vShow, withDirectives as _withDirectives } from "vue"
import _imports_0 from '@/assets/img/filter.svg'
import _imports_1 from '@/assets/img/checkOnly2.svg'


const _hoisted_1 = { class: "row" }
const _hoisted_2 = { class: "col" }
const _hoisted_3 = { class: "subheading-large" }
const _hoisted_4 = { class: "row mt-1 align-items-end" }
const _hoisted_5 = ["innerHTML"]
const _hoisted_6 = {
  key: 0,
  class: "d-flex mt-4"
}
const _hoisted_7 = {
  key: 0,
  class: "col-6 d-flex justify-content-end align-items-end"
}
const _hoisted_8 = {
  key: 0,
  class: "me-2"
}
const _hoisted_9 = {
  key: 1,
  class: "me-2"
}
const _hoisted_10 = { class: "d-flex" }
const _hoisted_11 = { class: "cursor-pointer" }
const _hoisted_12 = {
  key: 0,
  class: "gr-color px-4 py-5 mt-2"
}
const _hoisted_13 = { class: "row" }
const _hoisted_14 = ["onClick"]
const _hoisted_15 = {
  key: 0,
  style: {"color":"black"}
}
const _hoisted_16 = { src: _imports_1 }
const _hoisted_17 = { class: "row mt-4" }
const _hoisted_18 = { class: "col-6 col-xl-3" }
const _hoisted_19 = { class: "mx-2" }
const _hoisted_20 = { class: "text-tiny fw-bold mb-2" }
const _hoisted_21 = { class: "row mt-3" }

import {
  ref,
  Ref,
  computed,
  onMounted,
  watch
} from "vue";
import {
  FilterOptions,
  FilterSortOption
} from "../../library/types/filter/filter";
import { SortingSettings } from "../../library/types/sorting/sorting";
import { I18nMessage, PdfList } from "../../library/types/locales/i18nMessage";
import { useRouter } from "vue-router";
import { useIndexStore } from "@/store";
import { ExtendedSearchFilters, getSearchTable } from "@/library/api/search";
import { SearchTable, TranslatedFilter } from "@/services/api/searchApi";
import SearchBar from "../common/SearchBar.vue";
import GreenifiedButton from "../common/GreenifiedButton.vue";
import CounterIcon from "../common/CounterIcon.vue";
import SortingDropdown, {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars, no-unused-vars
  SortingDropdownOption
} from "../common/SortingDropdown.vue";
// eslint-disable-next-line @typescript-eslint/no-unused-vars, no-unused-vars
import CustomSelect, { LocalVueSelectOption } from "../common/CustomSelect.vue";
import DoubleRangeSlider from "../common/DoubleRangeSlider.vue";
import FilterBreadcrumbs from "../common/FilterBreadcrumbs.vue";
import { ProductStatuses } from "@/library/types/product/productHeader";
import ExtendedFilteredProducts from "../ExtendedFilteredProducts.vue";
import { getPdfFromRoute, getPdfFromUrl } from "@/library/api/media";
import PdfModal, { PdfRoute } from "../modal/PdfModal.vue";
import UrlModal from "../modal/UrlModal.vue";
import { useI18n } from "vue-i18n";
import { useAuthStore } from "@/store/auth";
import PackingListModal from "../modal/PackingListModal.vue";
import { OrderStatuses } from "@/services/api/salesApi";

export type FilterButton = {
  text: string;
  amount: number | null;
  show: boolean;
};

type MainFilterProps = {
  pdfRoute?: PdfRoute | null;
  orderStatus?: OrderStatuses;
  productStatus?: ProductStatuses[];
  filterTitle: string;
  filterText: string;
  filterQuery?: string;
  defaultFilter?: FilterOptions;
  sortingOptions?: FilterSortOption[];
  translateHeaders?: string[];
  defaultSortingSettings?: SortingSettings;
  hasProducts?: boolean;
  filterButtons?: FilterButton[];
  filterTab?: number;
  showUrlButton?: boolean;
  hideFilterOptions?: boolean;
};


export default /*@__PURE__*/_defineComponent({
  __name: 'MainFilter',
  props: {
    pdfRoute: { default: null },
    orderStatus: { default: "A" },
    productStatus: { default: () => ["J"] },
    filterTitle: { default: "" },
    filterText: { default: "" },
    filterQuery: { default: "" },
    defaultFilter: { default: () => ({
    categories: {},
    intervals: {}
  }) },
    sortingOptions: { default: () => [
    {
      name: "label.name",
      descendingLocale: "label.ZToA",
      ascendingLocale: "label.AToZ",
      colValue: { col: "benamn" }
    },
    {
      name: "label.quantity",
      descendingLocale: "label.desc",
      ascendingLocale: "label.asc",
      colValue: { col: "antal" }
    },
    {
      name: "",
      descendingLocale: "label.reg_dat",
      ascendingLocale: "label.reg_datAsc",
      colValue: { col: "reg_dat" }
    },
    {
      name: "",
      descendingLocale: "label.latestUpdate",
      ascendingLocale: "label.oldestUpdate",
      colValue: { col: "updated_at" }
    }
  ] },
    translateHeaders: { default: () => [] },
    defaultSortingSettings: { default: () => ({ col: "", asc: true }) },
    hasProducts: { type: Boolean, default: false },
    filterButtons: { default: () => [] },
    filterTab: { default: 1 },
    showUrlButton: { type: Boolean, default: false },
    hideFilterOptions: { type: Boolean, default: false }
  },
  emits: [
  "update:defaultSortingSettings",
  "update:defaultFilter",
  "update:filterTab"
],
  setup(__props: any, { emit: __emit }) {

const mainFilterProps = __props;

const showOrderOptions = ref(false);
const rangeSlider = ref<InstanceType<typeof DoubleRangeSlider>>();
const indexStore = useIndexStore();
const { t, messages, locale } = useI18n();
const emit = __emit;

const showUrlModal = ref(false);
const pdfRoutesForDownloadOptions: PdfRoute[] = [
  "repurpose-overview",
  "recondition"
];
const currentIntervalSettings = ref({
  to: indexStore.maxQuantity,
  from: 1
});
const showDownloadOptions = ref(false);
const loadingOptions = ref(false);
const pdfIsLoading = ref(false);
const showExtendedFilteredProducts = ref(false);
const extendedSearchFilters: Ref<ExtendedSearchFilters | null> = ref(null);

const { currentRoute } = useRouter();
const sortingSettings: Ref<SortingSettings> = ref(
  mainFilterProps.defaultSortingSettings
);

const currentFilter = ref(mainFilterProps.defaultFilter);
const dropdownOptions: Ref<{ [key: string]: SearchTable[] }> = ref({
  kategori_id: [],
  levkod: [],
  rum: [],
  skick2: []
});
const openFilter = ref(false);
const showSort = ref(false);
const currentProject = computed(() => {
  return indexStore.currentProjectNumber;
});

const getFilterAmount = (filter: FilterOptions) => {
  let categoryAmount = 0;
  let intervalAmount = Object.keys(filter.intervals).length;
  Object.keys(filter.categories).forEach(
    categoryKey => (categoryAmount += filter.categories[categoryKey].length)
  );

  return intervalAmount + categoryAmount;
};

const setExtendedSearchFilters = (
  filterCategories: FilterOptions["categories"]
) => {
  if (!currentProject.value || !useAuthStore().isAuthenticated) return;
  showExtendedFilteredProducts.value = false;
  const searchFilters: ExtendedSearchFilters = {};

  for (const filterKey in filterCategories) {
    filterCategories[filterKey].forEach(categoryKey => {
      const searchKey =
        categoryKey.key === "hit_on" ? "search" : categoryKey.key;
      if (Object.keys(searchFilters).includes(searchKey)) {
        searchFilters[searchKey].push(categoryKey.value);
      } else {
        searchFilters[searchKey] = [categoryKey.value];
      }
    });
  }
  showExtendedFilteredProducts.value = true;
  return searchFilters;
};

const toggleCheckedFilter = (
  categories: FilterOptions["categories"],
  categoryKey: string,
  categoryValue: string
) => {
  return categories[categoryKey].find(f => f.value === categoryValue)
    ? true
    : false;
};

const getMainCategories = (): TranslatedFilter[] => {
  const mainCategories = [];
  const sourceObject = messages.value[locale.value.toLocaleLowerCase()]
    .huvud_id as I18nMessage["huvud_id"];

  for (const key in sourceObject) {
    mainCategories.push({
      nyckel: key,
      text: sourceObject[key],
      sokvarde: sourceObject[key],
      group: true,
      sort: key + ".0",
      kol: "main_category",
      key: key,
      projekt: indexStore.currentProjectNumber,
      value: ""
    });
  }
  return mainCategories;
};

const sortFilterOptions = (
  filterOptions: SearchTable[],
  filterKey: string | number
) => {
  if (filterKey === "kategori_id") {
    const translatedFilters = filterOptions.map(filterOption => {
      if (filterOption)
        filterOption["text"] = (
          mainFilterProps.translateHeaders.includes(filterOption.kol)
            ? t(filterOption.kol + "." + filterOption.nyckel)
            : filterOption.text
        ).toLocaleLowerCase();
      return filterOption;
    });
    const mergedFilter = [...getMainCategories(), ...translatedFilters];
    return mergedFilter.sort((a, b) => {
      return parseInt(a.sort) - parseInt(b.sort);
    });
  }

  if (filterKey === "levkod") {
    const filterSuppliers = filterOptions
      .filter(filterSupplier => {
        return !/^ex\d{1,2}/.test(filterSupplier.nyckel);
      })
      .concat([
        {
          nyckel: "ex",
          text: t("levkod.ex"),
          kol: "levkod",
          projekt: "10007",
          sokvarde: "",
          sort: ""
        }
      ])
      .map(supplierOption => {
        supplierOption["text"] = (
          mainFilterProps.translateHeaders.includes(supplierOption.kol)
            ? t(supplierOption.kol + "." + supplierOption.nyckel)
            : supplierOption.text
        ).toLocaleLowerCase();
        return supplierOption;
      });
    return filterSuppliers.sort((a, b) => {
      return a.text?.localeCompare(b.text);
    });
  }
  const currentTranslatedFilters = filterOptions.map(currOption => {
    currOption["text"] = (
      mainFilterProps.translateHeaders.includes(currOption.kol)
        ? t(currOption.kol + "." + currOption.nyckel)
        : currOption.text
    ).toLocaleLowerCase();
    return currOption;
  });
  return currentTranslatedFilters.sort((a, b) => {
    return a.text?.localeCompare(b.text);
  });
};

const toggleFilter = (filterOption: TranslatedFilter) => {
  const categoryKeys = Object.keys(currentFilter.value.categories);
  if (categoryKeys.includes(filterOption.key)) {
    const categoryIndex = currentFilter.value.categories[
      filterOption.key
    ].findIndex(category => category.value === filterOption.value);
    if (categoryIndex !== -1) {
      currentFilter.value.categories[filterOption.key].splice(categoryIndex, 1);
      if (!currentFilter.value.categories[filterOption.key].length) {
        delete currentFilter.value.categories[filterOption.key];
      }
    } else {
      currentFilter.value.categories[filterOption.key].push({
        key: filterOption.key,
        value: filterOption.value
      });
    }
  } else {
    currentFilter.value.categories[filterOption.key] = [
      { key: filterOption.key, value: filterOption.value }
    ];
  }

  emit("update:defaultFilter", currentFilter.value);
};

const getDropdownData = async () => {
  if (!currentRoute.value.meta.hasFilter) return;

  loadingOptions.value = true;

  const result = await getSearchTable(
    ["kategori_id", "levkod", "skick2", "rum"],
    currentRoute.value.params.token as string
  );
  loadingOptions.value = false;

  if (!result.success) return;

  dropdownOptions.value = result.result;
};

const downloadPdf = async (reportType: string) => {
  pdfIsLoading.value = true;

  const result = await getPdfFromRoute(reportType);
  pdfIsLoading.value = false;

  if (!result.success) return;

  let blob = new Blob([result.result], { type: "application/pdf" }),
    url = window.URL.createObjectURL(blob);
  window.open(url);
};

const downloadPdfFromUrl = async () => {
  pdfIsLoading.value = true;

  const currentList = localeRecycleLists()[0];

  const pdfUrl = currentList.url
    .replace(/\{projectNr\}/g, indexStore.currentProjectNumber)
    .replace(/\{note\}/g, " ");

  const result = await getPdfFromUrl(pdfUrl);

  if (!result.success) return;

  const blob = new Blob([result.result], { type: "application/pdf" }),
    url = window.URL.createObjectURL(blob);

  window.open(url);
  pdfIsLoading.value = false;
};

const localeRecycleLists = (): PdfList[] => {
  const recycleLists = (
    messages.value[locale.value.toLocaleLowerCase()].list as I18nMessage["list"]
  ).recycleLists;
  return Object.keys(recycleLists).map(
    localeKey =>
      recycleLists[localeKey as keyof I18nMessage["list"]["recycleLists"]]
  );
};

onMounted(() => {
  getDropdownData();
  if (mainFilterProps.filterQuery) {
    const parsedQuery = JSON.parse(mainFilterProps.filterQuery);
    for (const key in parsedQuery) {
      const keyExists = Object.keys(currentFilter.value.categories).includes(
        key
      );
      if (keyExists) {
        currentFilter.value.categories[key].push({
          key: key,
          value: parsedQuery[key]
        });
      } else {
        const newFilterKey = key === "hit_on" ? "search" : key;
        currentFilter.value.categories[newFilterKey] = [
          { key: newFilterKey, value: parsedQuery[key] }
        ];
      }
    }
  }
  window.addEventListener("keydown", (event: KeyboardEvent) => {
    if (Number(event.code) === 38) {
      event.preventDefault();
    }
  });
});

watch(
  () => currentProject.value,
  () => {
    currentFilter.value.categories = {};
    currentFilter.value.intervals = {};

    openFilter.value = false;
    getDropdownData();
  }
);

watch(
  () => indexStore.maxQuantity,
  maxQuantity => {
    currentIntervalSettings.value = {
      to: maxQuantity,
      from: 1
    };
  }
);

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_openBlock(), _createElementBlock("div", {
      class: _normalizeClass(["container-fluid p-0 mb-4", _unref(currentRoute).meta.middleware === 'auth' && 'pt-5']),
      key: _unref(indexStore).currentProjectNumber
    }, [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("p", _hoisted_3, _toDisplayString(_ctx.filterTitle), 1)
        ])
      ]),
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("div", {
          class: _normalizeClass(_ctx.hideFilterOptions ? 'col-12' : 'col-6')
        }, [
          _createElementVNode("p", {
            class: _normalizeClass(["text-small mt-2", [!_ctx.hideFilterOptions && 'filter-text']]),
            innerHTML: _ctx.filterText
          }, null, 10, _hoisted_5),
          (_ctx.filterButtons.length)
            ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.filterButtons, (button, index) => {
                  return (_openBlock(), _createElementBlock(_Fragment, { key: index }, [
                    (button.show)
                      ? (_openBlock(), _createBlock(GreenifiedButton, {
                          key: 0,
                          class: "me-3",
                          size: "thin",
                          text: 
                button.amount !== null
                  ? `${_unref(t)(button.text)} (${button.amount})`
                  : _unref(t)(button.text)
              ,
                          btnStyle: _ctx.filterTab === index + 1 ? 'yellow-active' : 'yellow',
                          onClick: ($event: any) => (emit('update:filterTab', index + 1))
                        }, null, 8, ["text", "btnStyle", "onClick"]))
                      : _createCommentVNode("", true)
                  ], 64))
                }), 128))
              ]))
            : _createCommentVNode("", true)
        ], 2),
        (_ctx.hasProducts)
          ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
              (_ctx.showUrlButton)
                ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
                    _createVNode(GreenifiedButton, {
                      size: "thin",
                      text: _unref(t)('button.shareURL'),
                      onClick: _cache[0] || (_cache[0] = ($event: any) => (showUrlModal.value = true))
                    }, null, 8, ["text"])
                  ]))
                : _createCommentVNode("", true),
              (_ctx.pdfRoute)
                ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
                    _createVNode(GreenifiedButton, {
                      size: "medium",
                      text: pdfIsLoading.value ? _unref(t)('button.loading') : _unref(t)('button.lists'),
                      disabled: pdfIsLoading.value,
                      onClick: _cache[1] || (_cache[1] = 
              () => {
                if (!_ctx.pdfRoute) return;

                if (_ctx.pdfRoute === 'recycle') {
                  return downloadPdfFromUrl();
                }

                if (_ctx.pdfRoute === 'internal-order') {
                  showOrderOptions.value = true;

                  return;
                }

                if (pdfRoutesForDownloadOptions.includes(_ctx.pdfRoute)) {
                  showDownloadOptions.value = true;
                  return;
                }

                return downloadPdf(_ctx.pdfRoute);
              }
            )
                    }, null, 8, ["text", "disabled"])
                  ]))
                : _createCommentVNode("", true),
              _createVNode(SearchBar, {
                defaultFilter: currentFilter.value,
                "onUpdate:defaultFilter": _cache[2] || (_cache[2] = ($event: any) => ((currentFilter).value = $event)),
                productStatus: _ctx.productStatus[0],
                "onUpdate:modelValue": _cache[3] || (_cache[3] = 
            () => {
              if (_ctx.productStatus[0] === 'I') return;
              extendedSearchFilters.value =
                setExtendedSearchFilters(currentFilter.value.categories) ?? null;
            }
          )
              }, null, 8, ["defaultFilter", "productStatus"]),
              _createVNode(GreenifiedButton, {
                class: _normalizeClass(["ms-2 position-relative", openFilter.value && 'filter-button']),
                size: 'thin',
                btnStyle: 'yellow',
                smallerPadding: "",
                onClick: _cache[4] || (_cache[4] = ($event: any) => {
            openFilter.value = !openFilter.value;
            showSort.value = false;
          })
              }, {
                content: _withCtx(() => [
                  _createElementVNode("div", _hoisted_10, [
                    _createElementVNode("p", _hoisted_11, _toDisplayString(_unref(t)("button.filter")), 1),
                    _cache[13] || (_cache[13] = _createElementVNode("img", {
                      class: "ms-2",
                      src: _imports_0
                    }, null, -1))
                  ]),
                  (
                Object.keys(currentFilter.value.categories)?.length ||
                Object.keys(currentFilter.value.intervals)?.length
              )
                    ? (_openBlock(), _createBlock(CounterIcon, {
                        key: 0,
                        positionX: 'right',
                        positionY: 'bottom',
                        counter: getFilterAmount(currentFilter.value)
                      }, null, 8, ["counter"]))
                    : _createCommentVNode("", true)
                ]),
                _: 1
              }, 8, ["class"]),
              _createVNode(SortingDropdown, {
                class: "ms-2",
                sortingSettings: sortingSettings.value,
                sortOptions: _ctx.sortingOptions,
                onSetSort: _cache[5] || (_cache[5] = (sortingOption) =>emit('update:defaultSortingSettings', sortingOption))
              }, null, 8, ["sortingSettings", "sortOptions"])
            ]))
          : _createCommentVNode("", true)
      ]),
      (openFilter.value)
        ? (_openBlock(), _createElementBlock("div", _hoisted_12, [
            _createElementVNode("div", _hoisted_13, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(dropdownOptions.value, (obj, key) => {
                return (_openBlock(), _createElementBlock("div", {
                  class: "col-3",
                  key: key
                }, [
                  _createVNode(CustomSelect, {
                    options: sortFilterOptions(obj, key),
                    clearable: false,
                    closeOnSelect: false,
                    "is-loading": loadingOptions.value,
                    class: "placeholder-dark",
                    placeholder: _unref(t)(`label.${key}`),
                    label: "nyckel",
                    filterBy: 
            (filterOption, label, search) => {
              const currFilterOption = filterOption as TranslatedFilter
              return (
                (_ctx.translateHeaders.includes(currFilterOption.kol)
                  ? _unref(t)(currFilterOption.kol + '.' + currFilterOption.nyckel)
                  : currFilterOption.sokvarde
                )
                  .toLocaleLowerCase()
                  .indexOf(search.toLocaleLowerCase()) > -1
              );
            }
                  }, {
                    "selected-option": _withCtx(() => [
                      _createElementVNode("div", null, _toDisplayString(_unref(t)("label." + key)), 1)
                    ]),
                    option: _withCtx((option) => [
                      _createElementVNode("div", {
                        class: "d-flex w-100 justify-content-between",
                        onClick: 
                  () => {
                    const optionObject = {
                      group: option.option.group ?? false,
                      key: option.option.kol,
                      value: option.option.nyckel,
                      text: option.option.text,
                      col: '',
                      nyckel: '',
                      sokvarde: '',
                      sort: '',
                      kol: '',
                      projekt: ''
                    };
                    toggleFilter(optionObject);
                  }
                
                      }, [
                        (option.option.group)
                          ? (_openBlock(), _createElementBlock("div", _hoisted_15, _toDisplayString(option.option.text), 1))
                          : (_openBlock(), _createElementBlock("div", {
                              key: 1,
                              style: _normalizeStyle(
                    option.option.kol === 'kategori_id'
                      ? 'margin-left:20px'
                      : ''
                  )
                            }, _toDisplayString(_ctx.translateHeaders.includes(option.option.kol)
                      ? _unref(t)(option.option.kol + "." + option.option.nyckel)
                      : option.option.text), 5)),
                        _withDirectives(_createElementVNode("img", _hoisted_16, null, 512), [
                          [_vShow, 
                    Object.keys(currentFilter.value.categories).includes(
                      option.option.kol
                    ) &&
                    toggleCheckedFilter(
                      currentFilter.value.categories,
                      option.option.kol,
                      option.option.nyckel
                    )
                  ]
                        ])
                      ], 8, _hoisted_14)
                    ]),
                    _: 2
                  }, 1032, ["options", "is-loading", "placeholder", "filterBy"])
                ]))
              }), 128))
            ]),
            _createElementVNode("div", _hoisted_17, [
              _createElementVNode("div", _hoisted_18, [
                _createElementVNode("div", _hoisted_19, [
                  _createElementVNode("p", _hoisted_20, _toDisplayString(_unref(t)("label.antal")), 1),
                  (_openBlock(), _createBlock(DoubleRangeSlider, {
                    ref_key: "rangeSlider",
                    ref: rangeSlider,
                    key: _unref(indexStore).maxQuantity,
                    maxValue: _unref(indexStore).maxQuantity,
                    intervalsSettings: currentIntervalSettings.value,
                    "onUpdate:intervalsSettings": [
                      _cache[6] || (_cache[6] = ($event: any) => ((currentIntervalSettings).value = $event)),
                      _cache[7] || (_cache[7] = ($event: any) => {
                currentFilter.value.intervals.antal = currentIntervalSettings.value;
                emit('update:defaultFilter', currentFilter.value);
              })
                    ]
                  }, null, 8, ["maxValue", "intervalsSettings"]))
                ])
              ])
            ])
          ]))
        : _createCommentVNode("", true),
      _createElementVNode("div", _hoisted_21, [
        _createVNode(FilterBreadcrumbs, {
          filterOptions: currentFilter.value,
          "onUpdate:filterOptions": [
            _cache[8] || (_cache[8] = ($event: any) => ((currentFilter).value = $event)),
            _cache[9] || (_cache[9] = 
          () => {
            if (!Object.keys(currentFilter.value.categories).length) {
              showExtendedFilteredProducts.value = false;
              extendedSearchFilters.value = null;
            }
            if (!Object.keys(currentFilter.value.intervals).length) {
              currentIntervalSettings.value = {
                to: _unref(indexStore).maxQuantity,
                from: 1
              };
              rangeSlider.value?.resetIntervalRange();
            }
            emit('update:defaultFilter', currentFilter.value);
          }
        )
          ],
          translateHeaders: _ctx.translateHeaders
        }, null, 8, ["filterOptions", "translateHeaders"])
      ]),
      (showExtendedFilteredProducts.value && extendedSearchFilters.value)
        ? (_openBlock(), _createBlock(ExtendedFilteredProducts, {
            key: 1,
            extendedSearchFilters: extendedSearchFilters.value,
            productStatus: _ctx.productStatus
          }, null, 8, ["extendedSearchFilters", "productStatus"]))
        : _createCommentVNode("", true)
    ], 2)),
    (showDownloadOptions.value && _ctx.pdfRoute)
      ? (_openBlock(), _createBlock(PdfModal, {
          key: 0,
          onClose: _cache[10] || (_cache[10] = ($event: any) => (showDownloadOptions.value = false)),
          pdfRoute: _ctx.pdfRoute
        }, null, 8, ["pdfRoute"]))
      : _createCommentVNode("", true),
    (showUrlModal.value)
      ? (_openBlock(), _createBlock(UrlModal, {
          key: 1,
          onClose: _cache[11] || (_cache[11] = ($event: any) => (showUrlModal.value = false))
        }))
      : _createCommentVNode("", true),
    (showOrderOptions.value)
      ? (_openBlock(), _createBlock(PackingListModal, {
          key: 2,
          onClose: _cache[12] || (_cache[12] = ($event: any) => (showOrderOptions.value = false)),
          orderStatus: _ctx.orderStatus
        }, null, 8, ["orderStatus"]))
      : _createCommentVNode("", true)
  ], 64))
}
}

})