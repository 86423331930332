import { ToastError } from "@/modules/errorToastModule";
import { useAuthStore } from "@/store/auth";
import axios, { AxiosResponse } from "axios";

let toastError: ToastError | null = null;

export function initializeApiService(errorHandler: ToastError) {
  toastError = errorHandler;
}

export async function handleApiRequest<T>(
  request: Promise<AxiosResponse<T>>,
  context: string
): Promise<Result<T, CustomError>> {
  const authStore = useAuthStore();
  if (!toastError) {
    throw new Error("toastError function not initialized.");
  }

  let response: AxiosResponse<T>;

  try {
    response = await request;

    return ok(response.data);
  } catch (error) {
    const customError: CustomError = {};

    if (axios.isAxiosError(error)) {
      if (error.status === 429) {
        authStore.logout();
        return bail(customError);
      }
      if (error.status === 428) {
        return bail(customError);
      }
      customError.status = error.response?.status;
      toastError(error, context);
    }
    return bail(customError);
  }
}

export type Result<R, E> =
  | {
      success: true;
      result: R;
    }
  | {
      success: false;
      error: E;
    };

// eslint-disable-next-line no-unused-vars
export function ok<R, E>(result: R): Result<R, E>;
export function ok<E>(): Result<void, E>;

export function ok<R, E>(result?: R): Result<R | void, E> {
  return {
    success: true,
    result
  };
}

export const bail = <R, E>(error: E): Result<R, E> => ({
  success: false,
  error
});

export interface CustomError {
  status?: number;
}
