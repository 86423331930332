import { defineComponent as _defineComponent } from 'vue'
import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, unref as _unref, toDisplayString as _toDisplayString, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Transition as _Transition, withCtx as _withCtx, renderSlot as _renderSlot, Fragment as _Fragment } from "vue"
import _imports_0 from '@/assets/img/drop-arrow-white.svg'


const _hoisted_1 = { class: "row align-items-center h-100" }
const _hoisted_2 = { class: "nav-link" }
const _hoisted_3 = { class: "row nav-link" }
const _hoisted_4 = { class: "col d-flex justify-content-end align-items-center" }
const _hoisted_5 = { class: "cursor-pointer" }

import GrLayout from "@/layouts/GrLayout.vue";
import SidebarProjects from "@/components/project/SidebarProjects.vue";
import { computed, onMounted, ref } from "vue";
import { useIndexStore } from "@/store";
import { useAuthStore } from "@/store/auth";
import { useI18n } from "vue-i18n";
import SideBarContent from "./sidebar/SideBarContent.vue";
import { CustomSetLocaleMessage } from "@/library/types/locales/i18nUtilities";
import SelectLanguage from "@/components/common/SelectLanguage.vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'MainLayout',
  setup(__props) {

const { t, locale, messages, setLocaleMessage } = useI18n();
const store = useIndexStore();
const authStore = useAuthStore();
const showMenu = ref(true);
const projectsOpen = ref(false);

const projectName = computed(() => {
  return store.currentProject.namn;
});

const logout = () => {
  authStore.logout();
};

onMounted(() => {
  if (!store.currentProjectNumber) return;

  store.getProjectDetails(
    store.currentProjectNumber,
    setLocaleMessage as CustomSetLocaleMessage,
    messages.value[locale.value]
  );
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(GrLayout, { openSideMenu: showMenu.value }, {
      "top-navbar-left": _withCtx(() => [
        _createElementVNode("div", _hoisted_1, [
          _createElementVNode("div", {
            class: "col d-flex align-items-center",
            onClick: _cache[0] || (_cache[0] = ($event: any) => (showMenu.value = !showMenu.value))
          }, [
            _createElementVNode("img", {
              class: _normalizeClass([showMenu.value ? 'left-arrow' : 'right-arrow', "me-2"]),
              src: _imports_0,
              alt: "menu arrow"
            }, null, 2),
            _createElementVNode("p", _hoisted_2, _toDisplayString(showMenu.value ? _unref(t)("menu.hideMenu") : _unref(t)("menu.showMenu")), 1)
          ])
        ])
      ]),
      "top-navbar-right": _withCtx(() => [
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("div", _hoisted_4, [
            _createElementVNode("div", {
              class: "me-5 d-flex align-items-center cursor-pointer",
              onClick: _cache[1] || (_cache[1] = ($event: any) => (projectsOpen.value = !projectsOpen.value))
            }, [
              _createElementVNode("p", _hoisted_5, _toDisplayString(`${_unref(t)("title.project")}: ${projectName.value}`), 1)
            ]),
            _createVNode(SelectLanguage),
            _createElementVNode("p", {
              onClick: logout,
              class: "ms-5 cursor-pointer"
            }, _toDisplayString(_unref(t)("menu.signOut")), 1)
          ])
        ])
      ]),
      "side-bar": _withCtx(() => [
        _createVNode(SideBarContent, { showContent: showMenu.value }, null, 8, ["showContent"])
      ]),
      container: _withCtx(() => [
        _createVNode(_Transition, { name: "fade" }, {
          default: _withCtx(() => [
            (!showMenu.value)
              ? (_openBlock(), _createElementBlock("div", {
                  key: 0,
                  onClick: _cache[2] || (_cache[2] = ($event: any) => (showMenu.value = !showMenu.value)),
                  class: "g-icon"
                }, " g "))
              : _createCommentVNode("", true)
          ]),
          _: 1
        }),
        _renderSlot(_ctx.$slots, "default")
      ]),
      footer: _withCtx(() => [
        _renderSlot(_ctx.$slots, "footer")
      ]),
      _: 3
    }, 8, ["openSideMenu"]),
    _createVNode(SidebarProjects, {
      openSidebar: projectsOpen.value,
      "onUpdate:openSidebar": _cache[3] || (_cache[3] = ($event: any) => ((projectsOpen).value = $event))
    }, null, 8, ["openSidebar"])
  ], 64))
}
}

})