<template>
  <MainFilter
    :filterTitle="`${t('title.recondition')} - ${quantities} ${t(
      'title.products'
    )}`"
    :filterText="t('paragraph.recondition')"
    :filterQuery="filterQuery"
    v-model:defaultFilter="filter"
    v-model:defaultSortingSettings="sortingSettings"
    :translateHeaders="translateHeaders"
    :productStatus="presetFilter.status"
    :pdfRoute="'recondition'"
    :hasProducts="quantities > 0"
  />
  <ProductTable
    :actionStatuses="['M', 'CP', 'F', 'CR', 'KC', 'CA']"
    :noRowsText="t('label.noRowsPositive')"
    v-model:filter="filter"
    :sortingSettings="sortingSettings"
    :translateHeaders="translateHeaders"
    :presetFilter="presetFilter"
    :tableType="'recondition'"
  />
</template>

<script setup lang="ts">
import MainFilter from "@/components/filter/MainFilter.vue";
import { computed, Ref, ref, defineProps, withDefaults } from "vue";
import { FilterOptions, PresetFilter } from "../library/types/filter/filter";
import { SortingSettings } from "../library/types/sorting/sorting";
import { useI18n } from "vue-i18n";
import ProductTable from "@/components/table/ProductTable.vue";
import { getQuantitiesByProductStatus } from "@/library/helpers/getQuantitiesByProductStatus";

type ProjectReconditionProps = {
  filterQuery?: string;
};

withDefaults(defineProps<ProjectReconditionProps>(), {
  filterQuery: ""
});
const { t } = useI18n();

const quantities = computed(() => {
  return getQuantitiesByProductStatus("R");
});

const sortingSettings: Ref<SortingSettings> = ref({
  col: "updated_at",
  asc: false
});
const translateHeaders = [
  "levkod",
  "kategori_id",
  "skick2",
  "rum",
  "till_rum",
  "main_category"
];

const filter: Ref<FilterOptions> = ref({
  categories: {},
  intervals: {}
});
const presetFilter: PresetFilter = {
  filter: [],
  group: [
    "benamn",
    "levkod",
    "besk",
    "kategori_id",
    "recondition",
    "kommentar",
    "status",
    "green_hash"
  ],
  status: ["R"]
};
</script>
