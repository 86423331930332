<template>
  <GreenifiedModal
    type="verification"
    :closable="false"
    @close="emit('close')"
    :title="t('title.resetPassword')"
    :confirm-text="t('button.send')"
    :button-type="'submit'"
    :form="'resetform'"
    @confirm="
      () => {
        if (resetform && resetform.reportValidity()) {
          resetPassword();
        }
      }
    "
  >
    <template v-slot:container>
      <div class="row">
        <div class="col-sm-12 d-flex align-items-center">
          <p class="subheading-tiny">
            {{ t("label.enterYourEmail") }}
          </p>
          <RequiredWarning
            v-if="showWarning"
            :warningText="t('msg.requiredField')"
          />
        </div>
      </div>
      <div class="row mt-2">
        <div class="col-sm-12">
          <form
            ref="resetform"
            @submit.prevent="
              () => {
                if (resetform && resetform.reportValidity()) {
                  resetPassword();
                }
              }
            "
          >
            <InputField type="email" v-model:field-value="email" required />
          </form>
        </div>
      </div>
    </template>
  </GreenifiedModal>
  <GreenifiedModal
    type="response"
    :closable="false"
    v-if="resetSuccess"
    :title="t('responseTitle.passwordReset')"
    :text="translatedText"
    @close="emit('accept')"
  />
  <GreenifiedModal
    type="response"
    :closable="false"
    v-if="resetError"
    :title="t('responseTitle.passwordResetError')"
    :text="t('msg.passwordResetError')"
    @close="resetError = false"
  />
</template>

<script setup lang="ts">
import RequiredWarning from "@/components/common/RequiredWarning.vue";
import { computed, defineEmits, Ref, ref } from "vue";

import { postPasswordReset } from "@/library/api/user";
import { useI18n } from "vue-i18n";
import GreenifiedModal from "./GreenifiedModal.vue";
import InputField from "../common/InputField.vue";
const { t } = useI18n();

const emit = defineEmits(["accept", "close"]);

const email = ref("");
const resetSuccess = ref(false);
const resetError = ref(false);
const showWarning = ref(false);
const resetform: Ref<HTMLFormElement | null> = ref(null);

const translatedText = computed(() => {
  return t("msg.passwordResetSuccess", [
    `<span style="font-weight: bolder">${email.value}</span>`
  ]);
});

const resetPassword = async () => {
  if (email.value.length) {
    const result = await postPasswordReset(email.value);

    if (!result.success) {
      resetError.value = true;
      return;
    }

    resetSuccess.value = true;
  } else {
    showWarning.value = true;
  }
};
</script>
