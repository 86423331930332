import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

import CustomSelect from "@/components/common/CustomSelect.vue";
// eslint-disable-next-line @typescript-eslint/no-unused-vars, no-unused-vars
import { ProjectCategory } from "@/library/types/project/project";
import { FormFields } from "@/library/types/structure/formStructure";
import { TaxonomyTermsFieldType } from "@/library/types/structure/subfieldTypes";
import { useIndexStore } from "@/store";
import { ref } from "vue";

type TaxonomyFieldProps = {
  taxonomyField: TaxonomyTermsFieldType | null;
  taxonomyFieldValues: FormFields["key"];
  showError?: boolean;
};


export default /*@__PURE__*/_defineComponent({
  __name: 'TaxonomyField',
  props: {
    taxonomyField: { default: null },
    taxonomyFieldValues: { default: () => ({}) },
    showError: { type: Boolean, default: false }
  },
  emits: ["update:taxonomyFieldValues"],
  setup(__props: any, { emit: __emit }) {

const indexStore = useIndexStore();

const taxonomyFieldProps = __props;

const emit = __emit;

const localTaxonomyFieldValues = ref(
  typeof taxonomyFieldProps.taxonomyFieldValues === "string"
    ? taxonomyFieldProps.taxonomyFieldValues
    : ""
);

return (_ctx: any,_cache: any) => {
  return (_ctx.taxonomyField)
    ? (_openBlock(), _createBlock(CustomSelect, {
        key: 0,
        required: _ctx.taxonomyField.required === 1 ? true : false,
        showError: _ctx.showError,
        id: _ctx.taxonomyField.key,
        name: _ctx.taxonomyField.key,
        options: 
      _unref(indexStore).currentProject.product_cat.filter(option =>
        _unref(indexStore).currentProject.sprak !== 'fi'
          ? _ctx.taxonomyField?.allow_terms.includes(option.id)
          : _ctx.taxonomyField?.allow_terms.includes(option.sv_id) &&
            option.locale === 'fi'
      )
    ,
        reducedKey: "id",
        withComponents: true,
        appendToBody: true,
        label: "namn",
        placeholder: _ctx.taxonomyField.placeholder,
        modelValue: localTaxonomyFieldValues.value,
        "onUpdate:modelValue": [
          _cache[0] || (_cache[0] = ($event: any) => ((localTaxonomyFieldValues).value = $event)),
          _cache[1] || (_cache[1] = (option) => {
      emit('update:taxonomyFieldValues', option)})
        ],
        clearable: _ctx.taxonomyField.required === 1 ? false : true
      }, null, 8, ["required", "showError", "id", "name", "options", "placeholder", "modelValue", "clearable"]))
    : _createCommentVNode("", true)
}
}

})