import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, Fragment as _Fragment, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "row pb-2" }
const _hoisted_2 = { class: "col-12 d-flex" }
const _hoisted_3 = { class: "subheading-medium" }
const _hoisted_4 = { class: "col-12 d-flex" }
const _hoisted_5 = { class: "subheading-medium" }

import MainFilter, { FilterButton } from "@/components/filter/MainFilter.vue";
import {
  computed,
  Ref,
  ref,
  watch,
  onMounted
} from "vue";
import { FilterOptions, PresetFilter } from "../library/types/filter/filter";
import { SortingSettings } from "../library/types/sorting/sorting";
import { useI18n } from "vue-i18n";
import { getQuantitiesByProductStatus } from "@/library/helpers/getQuantitiesByProductStatus";

import ProductTable from "@/components/table/ProductTable.vue";
import {
  AdvertisementProductHeader,
  OrderProductHeader,
  ProductHeaderSale
} from "@/library/types/product/productHeader";
import { useIndexStore } from "@/store";
import LoadingOverlay from "@/components/common/LoadingOverlay.vue";
import TableHeaderRow from "@/components/table/tableHeader/TableHeaderRow.vue";
import { getInternalAdvertisements } from "@/library/api/sales";

type InternalSalesViewProps = {
  filterQuery?: string;
  setTab: "1" | "2" | "3";
};


export default /*@__PURE__*/_defineComponent({
  __name: 'InternalSalesView',
  props: {
    filterQuery: { default: "" },
    setTab: { default: "1" }
  },
  setup(__props: any) {

const internalSalesViewProps = __props;

const indexStore = useIndexStore();

const currentFilter: Ref<FilterOptions> = ref({
  categories: {},
  intervals: {}
});

const availableFilter: PresetFilter = {
  filter: [],
  group: [
    "benamn",
    "levkod",
    "kategori_id",
    "sell_antal",
    "sell_grupp",
    "sell_pris",
    "green_hash"
  ],
  status: ["I"]
};

const deliveredFilter: PresetFilter = {
  filter: [],
  group: [
    "benamn",
    "levkod",
    "kategori_id",
    "sell_antal",
    "sell_grupp",
    "sell_pris",
    "green_hash"
  ],
  status: ["B"]
};

const ongoingFilter: PresetFilter = {
  filter: [],
  group: [
    "benamn",
    "levkod",
    "kategori_id",
    "sell_antal",
    "sell_grupp",
    "sell_pris",
    "green_hash"
  ],
  status: ["I", "O"]
};

const isLoading = ref(false);
const orderTableFooterIsViewable = ref(true);
const soldTableFooterIsViewable = ref(true);

const { t } = useI18n();
const filterButtons: Ref<FilterButton[]> = computed(() => [
  {
    text: "button.available",
    amount: countTotalQuantities(availableProductHeaders.value),
    show: true
  },
  {
    text: "button.ongoing",
    amount: countTotalQuantities(ongoingProductHeaders.value),
    show: indexStore.currentProjectRole === "P"
  },
  {
    text: "button.delivered",
    amount: getQuantitiesByProductStatus("B"),
    show: indexStore.currentProjectRole === "P"
  }
]);

const quantities = computed(() => {
  return getQuantitiesByProductStatus("I") + getQuantitiesByProductStatus("B");
});

const currentTab = ref(1);
const availableProductHeaders: Ref<AdvertisementProductHeader> = ref({});
const ongoingProductHeaders: Ref<OrderProductHeader> = ref({});
const soldProductHeaders: Ref<ProductHeaderSale> = ref({});
const deliveredProductHeaders: Ref<OrderProductHeader> = ref({});

const sortingSettings: Ref<SortingSettings> = ref({
  col: "updated_at",
  asc: false
});
const translateHeaders = [
  "levkod",
  "kategori_id",
  "skick2",
  "rum",
  "till_rum",
  "main_category"
];

const selectedProductIds: Ref<string[]> = ref([]);

const countTotalQuantities = (
  productHeaders: OrderProductHeader | AdvertisementProductHeader
) => {
  let quantity = 0;

  for (const key in productHeaders) {
    const currentProductHeader = productHeaders[key];
    const currentQuantity =
      currentProductHeader.group.quantity ?? currentProductHeader.group.antal;
    quantity += Number(currentQuantity);
  }

  return quantity;
};

const getProductHeaders = async () => {
  isLoading.value = true;
  soldProductHeaders.value = {};
  availableProductHeaders.value = {};
  ongoingProductHeaders.value = {};
  deliveredProductHeaders.value = {};

  const result = await getInternalAdvertisements();

  if (!result.success) {
    isLoading.value = false;
    return;
  }

  availableProductHeaders.value = result.result.advertisements;
  ongoingProductHeaders.value = result.result.ongoingOrders;
  deliveredProductHeaders.value = result.result.completedOrders;
  soldProductHeaders.value = result.result.oldOrders;

  if (
    Object.keys(internalSalesViewProps.filterQuery).length &&
    "hit_on" in JSON.parse(internalSalesViewProps.filterQuery)
  ) {
    if (
      internalSalesViewProps.setTab === "1" &&
      !productHeadersMatchSearchValue(
        availableProductHeaders.value,
        JSON.parse(internalSalesViewProps.filterQuery).hit_on
      )
    ) {
      currentTab.value = 2;
    } else if (
      internalSalesViewProps.setTab === "2" &&
      !productHeadersMatchSearchValue(
        ongoingProductHeaders.value,
        JSON.parse(internalSalesViewProps.filterQuery).hit_on
      )
    ) {
      currentTab.value = 1;
    }
  }

  isLoading.value = false;
};

const productHeadersMatchSearchValue = (
  productHeaders: AdvertisementProductHeader | OrderProductHeader,
  searchValue: string
) => {
  for (const key in productHeaders) {
    const currentProductHeader = productHeaders[key];
    if (
      currentProductHeader.group.benamn
        .toLowerCase()
        .includes(searchValue.toLowerCase())
    ) {
      return true;
    }
  }
  return false;
};

onMounted(() => {
  getProductHeaders();
  const tabOptions = ["1", "2", "3"];
  if (
    internalSalesViewProps.setTab &&
    indexStore.currentProjectRole === "P" &&
    tabOptions.includes(internalSalesViewProps.setTab)
  ) {
    currentTab.value = Number(internalSalesViewProps.setTab);
  }
});

watch(
  () => currentTab.value,
  () => {
    selectedProductIds.value = [];
  }
);

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(MainFilter, {
      filterTitle: `${_unref(t)('button.otherSales')} - ${quantities.value} ${_unref(t)(
      'title.products'
    )}`,
      filterText: _unref(t)('paragraph.overviewSell'),
      filterQuery: _ctx.filterQuery,
      defaultFilter: currentFilter.value,
      "onUpdate:defaultFilter": _cache[0] || (_cache[0] = ($event: any) => ((currentFilter).value = $event)),
      defaultSortingSettings: sortingSettings.value,
      "onUpdate:defaultSortingSettings": _cache[1] || (_cache[1] = ($event: any) => ((sortingSettings).value = $event)),
      translateHeaders: translateHeaders,
      productStatus: currentTab.value === 1 || currentTab.value === 2 ? ['I'] : ['B'],
      hasProducts: quantities.value > 0,
      filterButtons: filterButtons.value,
      pdfRoute: currentTab.value !== 1 ? 'internal-order' : null,
      orderStatus: currentTab.value === 2 ? 'A' : 'C',
      showUrlButton: currentTab.value === 1,
      filterTab: currentTab.value,
      "onUpdate:filterTab": _cache[2] || (_cache[2] = ($event: any) => ((currentTab).value = $event))
    }, null, 8, ["filterTitle", "filterText", "filterQuery", "defaultFilter", "defaultSortingSettings", "productStatus", "hasProducts", "filterButtons", "pdfRoute", "orderStatus", "showUrlButton", "filterTab"]),
    (isLoading.value)
      ? (_openBlock(), _createBlock(LoadingOverlay, { key: 0 }))
      : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
          (currentTab.value === 1)
            ? (_openBlock(), _createBlock(ProductTable, {
                key: 0,
                actionStatuses: ['CP', 'CA', 'EI'],
                noRowsText: _unref(t)('label.noRowsPositive'),
                tableType: 'internal',
                filter: currentFilter.value,
                "onUpdate:filter": _cache[3] || (_cache[3] = ($event: any) => ((currentFilter).value = $event)),
                sortingSettings: sortingSettings.value,
                translateHeaders: translateHeaders,
                presetFilter: availableFilter,
                externalProducts: availableProductHeaders.value,
                onFetchProducts: _cache[4] || (_cache[4] = ($event: any) => (getProductHeaders()))
              }, null, 8, ["noRowsText", "filter", "sortingSettings", "externalProducts"]))
            : _createCommentVNode("", true),
          (currentTab.value === 2)
            ? (_openBlock(), _createBlock(ProductTable, {
                key: 1,
                actionStatuses: ['CA', 'EI'],
                noRowsText: _unref(t)('label.noRowsPositive'),
                tableType: 'internalOngoing',
                filter: currentFilter.value,
                "onUpdate:filter": _cache[5] || (_cache[5] = ($event: any) => ((currentFilter).value = $event)),
                sortingSettings: sortingSettings.value,
                translateHeaders: translateHeaders,
                presetFilter: ongoingFilter,
                externalProducts: ongoingProductHeaders.value,
                onFetchProducts: _cache[6] || (_cache[6] = ($event: any) => (getProductHeaders()))
              }, null, 8, ["noRowsText", "filter", "sortingSettings", "externalProducts"]))
            : _createCommentVNode("", true),
          (currentTab.value === 3)
            ? (_openBlock(), _createElementBlock(_Fragment, { key: 2 }, [
                (
          !Object.keys(deliveredProductHeaders.value).length &&
          !Object.keys(soldProductHeaders.value).length
        )
                  ? (_openBlock(), _createBlock(TableHeaderRow, {
                      key: 0,
                      tableHeaders: null,
                      noRowsText: _unref(t)('label.noRowsPositive')
                    }, null, 8, ["noRowsText"]))
                  : _createCommentVNode("", true),
                (Object.keys(deliveredProductHeaders.value).length)
                  ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                      _createElementVNode("div", _hoisted_1, [
                        _createElementVNode("div", _hoisted_2, [
                          _createElementVNode("p", _hoisted_3, _toDisplayString(_unref(t)("title.sold")), 1)
                        ])
                      ]),
                      _createVNode(ProductTable, {
                        actionStatuses: ['A'],
                        noRowsText: _unref(t)('label.noRowsPositive'),
                        tableType: 'internalSold',
                        filter: currentFilter.value,
                        "onUpdate:filter": _cache[7] || (_cache[7] = ($event: any) => ((currentFilter).value = $event)),
                        sortingSettings: sortingSettings.value,
                        translateHeaders: translateHeaders,
                        presetFilter: deliveredFilter,
                        externalProducts: deliveredProductHeaders.value,
                        onFetchProducts: _cache[8] || (_cache[8] = ($event: any) => (getProductHeaders())),
                        footerIsViewable: orderTableFooterIsViewable.value,
                        onFooterIsOpen: _cache[9] || (_cache[9] = 
            bool => {
              soldTableFooterIsViewable.value = !bool;
            }
          )
                      }, null, 8, ["noRowsText", "filter", "sortingSettings", "externalProducts", "footerIsViewable"])
                    ], 64))
                  : _createCommentVNode("", true),
                (Object.keys(soldProductHeaders.value).length)
                  ? (_openBlock(), _createElementBlock(_Fragment, { key: 2 }, [
                      _createElementVNode("div", {
                        class: _normalizeClass(["row pb-2", Object.keys(deliveredProductHeaders.value).length && 'mt-4'])
                      }, [
                        _createElementVNode("div", _hoisted_4, [
                          _createElementVNode("p", _hoisted_5, _toDisplayString(_unref(t)("title.sold")) + " (" + _toDisplayString(_unref(t)("label.withoutOrders")) + ") ", 1)
                        ])
                      ], 2),
                      (_openBlock(), _createBlock(ProductTable, {
                        actionStatuses: ['SI'],
                        noRowsText: _unref(t)('label.noRowsPositive'),
                        tableType: 'internalSold',
                        key: String(isLoading.value),
                        filter: currentFilter.value,
                        "onUpdate:filter": _cache[10] || (_cache[10] = ($event: any) => ((currentFilter).value = $event)),
                        sortingSettings: sortingSettings.value,
                        translateHeaders: translateHeaders,
                        presetFilter: deliveredFilter,
                        externalProducts: soldProductHeaders.value,
                        onFetchProducts: _cache[11] || (_cache[11] = ($event: any) => (getProductHeaders())),
                        footerIsViewable: soldTableFooterIsViewable.value,
                        onFooterIsOpen: _cache[12] || (_cache[12] = 
            bool => {
              orderTableFooterIsViewable.value = !bool;
            }
          )
                      }, null, 8, ["noRowsText", "filter", "sortingSettings", "externalProducts", "footerIsViewable"]))
                    ], 64))
                  : _createCommentVNode("", true)
              ], 64))
            : _createCommentVNode("", true)
        ], 64))
  ], 64))
}
}

})