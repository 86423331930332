import { createPopper } from "@popperjs/core";

export const withPopper = (
  dropdownList: HTMLElement,
  component: PopperComponent,
  { width }: PopperWidth
) => {
  dropdownList.style.width = width;

  const popper = createPopper(component.$refs.toggle, dropdownList, {
    placement: "bottom",
    modifiers: [
      {
        name: "offset",
        options: {
          offset: [0, -1]
        }
      },
      {
        name: "toggleClass",
        enabled: true,
        phase: "write",
        fn({ state }) {
          component.$el.classList.toggle("drop-up", state.placement === "top");
        }
      }
    ]
  });

  return () => popper.destroy();
};

type PopperWidth = {
  width: string;
};

type PopperComponent = {
  $el: {
    classList: {
      // eslint-disable-next-line no-unused-vars
      toggle: (direction: string, state: boolean) => void;
    };
  };
  $refs: {
    toggle: Element;
  };
};
