<template>
  <div v-if="withAddress">
    <VueSelect
      :clearable="false"
      @option:selected="
      (option: ProjectSettingsAdress) =>
        emit('update:currentAddress', option) "
      :options="projectAddresses"
      label="building"
      :placeholder="
        isLoading
          ? ''
          : placeholder
          ? placeholder
          : t('placeholders.selectAddress')
      "
      :reduce="(option: ProjectSettingsAdress) => option.id"
      v-model="selectedAddress"
      :calculate-position="withPopper"
      :selectable="(option: ProjectSettingsAdress) => option?.rooms.length && option.rooms.some((room) => room.aktiv === '1')"
      append-to-body
    >
      <template
        #open-indicator="{ attributes }: { attributes: VueSelectSlotScope }"
      >
        <span v-bind="attributes">
          <img src="@/assets/img/drop-arrow.svg" />
        </span>
      </template>

      <template
        #option="{
          rooms,
          building,
          address
        }: {
          rooms: ProjectSettingsAdress['rooms']
          building: ProjectSettingsAdress['building'],
          address: ProjectSettingsAdress['address']
        }"
      >
        <InformationTooltip
          :infoText="t('label.noRooms')"
          v-if="!rooms.length || !rooms.some(room => room.aktiv === '1')"
        >
          <template #icon>
            <div>
              {{ building }}
              <p style="font-weight: 400">
                {{ address.adress }}<br />{{ address.postnummer }},{{
                  address.ort
                }},{{ address.land }}
              </p>
            </div>
          </template>
        </InformationTooltip>
        <div v-else>
          {{ building }}
          <p style="font-weight: 400">
            {{ address.adress }}<br />{{ address.postnummer }},{{
              address.ort
            }},{{ address.land }}
          </p>
        </div>
      </template>
      <template #list-footer>
        <slot name="list-footer" />
      </template>
      ></VueSelect
    >
  </div>

  <div class="position-relative" v-else>
    <VueSelect
      :options="
        currentLocations.filter(location => location.rum !== excludedRoom)
      "
      class="room-dropdown"
      :clearable="false"
      label="rumsnamn"
      :placeholder="
        isLoading
          ? ''
          : placeholder
          ? placeholder
          : t('placeholders.selectRoom')
      "
      :reduce="(option: RoomObject) => option.rum"
      :append-to-body="append"
      :isLoading="isLoading"
      v-model="selectedRoom"
      :filterBy="
      (option: RoomObject, label: string, search: string) => {
        if (option.address?.building)
        return (
          extendedFilter(search, option.rumsnamn) ||
          extendedFilter(search, option.rumskod) ||
          extendedFilter(search, option.van) ||
          extendedFilter(search, option.address?.building)
        );
      }
    "
      @option:selected="
      (option: RoomObject) =>{
        isReduced
          ? emit('update:modelValue', option.rum)
          : emit('update:modelValue', option)}
    "
    >
      <template
        #open-indicator="{ attributes }: { attributes: VueSelectSlotScope }"
      >
        <span v-bind="attributes">
          <img src="@/assets/img/drop-arrow.svg" />
        </span>
      </template>
      <template
        #option="{ rumsnamn, new_room, address, van, rumskod }: RoomObject"
      >
        <div class="d-flex justify-content-between">
          <div class="d-flex" :class="showAllLocations && 'me-3'">
            <p class="me-3" v-if="address?.building">{{ address?.building }}</p>
            <p class="fw-normal me-3" v-if="van">
              {{ t("label.floorShortened") }} {{ van }}
            </p>
            <p class="me-3" v-if="rumskod">
              {{ rumskod }}
            </p>
            <p class="fw-normal" v-if="rumsnamn">
              {{ rumsnamn }}
            </p>
          </div>

          <template v-if="!newRooms">
            <p class="fst-italic fw-normal" v-if="new_room === '1'">
              {{ t("label.newLocation") }}
            </p>
          </template>
          <template v-else>
            <p class="fst-italic fw-normal" v-if="new_room === '0'">
              {{ t("label.oldLocation") }}
            </p>
          </template>
        </div>
      </template>
      <template #list-header>
        <div
          v-if="newLocations.length && oldLocations.length"
          class="show-more-option"
          @click="showAllLocations = !showAllLocations"
        >
          <img
            :src="showAllLocations ? minus : plus"
            alt="plus icon"
            class="add-icon"
          />
          <p class="ms-1" v-if="!showAllLocations">
            {{
              newRooms
                ? t("label.showRoomsInOldLocations")
                : t("label.showRoomsInNewLocations")
            }}
          </p>
          <p class="ms-1" v-else>
            {{
              newRooms
                ? t("label.hideRoomsInOldLocations")
                : t("label.hideRoomsInNewLocations")
            }}
          </p>
        </div>
        {{ inAddress }}
      </template>
    </VueSelect>
    <div class="loader-spinner-wrapper" v-if="isLoading">
      <LoaderSpinner />
    </div>
  </div>
</template>

<script setup lang="ts">
import {
  defineEmits,
  withDefaults,
  defineProps,
  ref,
  Ref,
  watch,
  onMounted
} from "vue";
import { RoomObject } from "../library/types/room/rooms";
import { getProjectRooms } from "@/library/api/rooms";
import plus from "../assets/img/plus.svg";
import minus from "../assets/img/minus.svg";
// eslint-disable-next-line @typescript-eslint/no-unused-vars, no-unused-vars
import VueSelect, { VueSelectSlotScope } from "vue-select";
import { useI18n } from "vue-i18n";
import LoaderSpinner from "./common/LoaderSpinner.vue";
import { withPopper } from "@/modules/popperModule";
import { getAddresses } from "@/library/api/address";
import { ProjectSettingsAdress } from "@/services/api/addressApi";
import InformationTooltip from "./common/InformationTooltip.vue";

type RoomModelValue = string | RoomObject;

type SelectRoomProps = {
  isLoadingRoom?: boolean;
  newRooms?: boolean;
  modelValue?: RoomModelValue | null;
  placeholder?: string | null;
  excludedRoom?: string;
  isReduced?: boolean;
  append?: boolean;
  withAddress?: boolean;
  inAddress?: string;
  currentAddress?: string | null;
  defaultRooms?: RoomObject[] | null;
};

const emit = defineEmits(["update:modelValue", "update:currentAddress"]);

const selectRoomProps = withDefaults(defineProps<SelectRoomProps>(), {
  isLoadingRoom: false,
  newRooms: false,
  modelValue: "",
  placeholder: null,
  excludedRoom: "",
  isReduced: true,
  append: true,
  rightMenu: false,
  withAddress: false,
  inAddress: "",
  defaultRooms: null,
  currentAddress: null
});

const { t } = useI18n();
const showAllLocations = ref(false);
const allLocations: Ref<RoomObject[]> = ref([]);
const oldLocations: Ref<RoomObject[]> = ref([]);
const newLocations: Ref<RoomObject[]> = ref([]);
const currentLocations: Ref<RoomObject[]> = ref([]);
const selectedRoom: Ref<RoomModelValue> = ref("");
const selectedAddress: Ref<ProjectSettingsAdress | null> = ref(null);
const isLoading = ref(selectRoomProps.isLoadingRoom);
const projectAddresses: Ref<ProjectSettingsAdress[]> = ref([]);

const extendedFilter = (searchValue: string, searchKey: string) => {
  return (searchKey || "").toLowerCase().includes(searchValue.toLowerCase());
};

const getRooms = async () => {
  isLoading.value = true;

  const result = await getProjectRooms();

  if (!result.success) {
    return;
  }

  allLocations.value = result.result;

  setLocationData(allLocations.value);

  isLoading.value = false;

  if (!allLocations.value.length) return;
};

const setLocationData = (allLocations: RoomObject[]) => {
  allLocations.forEach(location => {
    location.new_room === "0"
      ? oldLocations.value.push(location)
      : newLocations.value.push(location);
  });
  currentLocations.value =
    selectRoomProps.newRooms ||
    (!oldLocations.value.length && newLocations.value.length)
      ? newLocations.value
      : oldLocations.value;
  const currentRoom = allLocations.find(
    room => room.rum === selectRoomProps.modelValue
  );
  if (currentRoom) {
    selectedRoom.value = currentRoom;
    return;
  }

  if (
    !selectRoomProps.isReduced &&
    selectRoomProps.modelValue &&
    typeof selectRoomProps.modelValue !== "string"
  ) {
    selectedRoom.value = selectRoomProps.modelValue.rum;
    return;
  }
  selectedRoom.value = selectRoomProps.modelValue ?? "";
};

const getProjectAddress = async () => {
  isLoading.value = true;

  const result = await getAddresses();

  if (!result.success) {
    isLoading.value = false;
    return;
  }
  isLoading.value = false;

  projectAddresses.value = result.result;

  const existingAddress = projectAddresses.value.find(
    address => address.id === selectRoomProps.currentAddress
  );
  if (existingAddress) {
    selectedAddress.value = existingAddress;
    emit("update:currentAddress", selectedAddress.value);
  }
};

onMounted(() => {
  if (selectRoomProps.withAddress) {
    getProjectAddress();
    return;
  }
  if (selectRoomProps.defaultRooms) {
    isLoading.value = true;

    setLocationData(selectRoomProps.defaultRooms);
    isLoading.value = false;
    return;
  }
  getRooms();
});

watch(
  () => showAllLocations.value,
  showAll => {
    if (showAll) {
      currentLocations.value = allLocations.value;
    } else {
      currentLocations.value = selectRoomProps.newRooms
        ? newLocations.value
        : oldLocations.value;
    }
  }
);
</script>

<style scoped>
@import "@/assets/scss/select.scss";
@import "vue-select/dist/vue-select.css";
.show-more-option {
  padding-left: 12px;
  font-size: 12px;
  display: flex;
  align-items: center;
  cursor: pointer;
  height: 2rem;
}

.show-more-option:hover p {
  text-decoration: underline;
  transition: 0.2s;
}

.add-icon {
  width: 1.4rem;
}

.loader-spinner-wrapper {
  position: absolute;
  top: 6px;
  left: 20px;
}
</style>
