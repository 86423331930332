<template v-if="currentFormValues">
  <template
    v-for="(formField, fieldIndex) in filteredFormFields(formFields)
      .validFields"
    :key="formField"
  >
    <div
      :ref="
        element => {
          formRefs[fieldIndex] = element as HTMLDivElement | null;
        }
      "
      :class="getContainerOrRowStyling(fieldIndex, formField)"
    >
      <FieldLabel
        v-if="!hideTitle"
        :showLabel="fieldHasLabel(formField.type)"
        :fieldKey="formField.key"
        :fieldLabel="formField.label"
        :requiredField="formField.required"
        :presetFieldValue="currentFormValues[formField.key]"
        :showTooltip="formField.instructions ? true : false"
        :tooltipInstructions="formField.instructions"
        :formIsSubmitted="formIsSubmitted"
      />

      <FormImages
        v-if="
          Object.keys(formImages).length === 4 &&
          formField.name === 'bilder' &&
          productImages
        "
        v-model:formImages="currentFormImages"
        :productImages="productImages"
        @update:formImages="
          () => {
            currentFormValues = updateFormImages(
              currentFormValues,
              currentFormImages
            );
            emit('update:formValues', currentFormValues);
          }
        "
        @update:productImages="
          (updatedImages: ProductImages) => emit('update:productImages', updatedImages)
        "
      />
      <GreenifiedForm
        v-if="formField.type === 'group'"
        :key="currentSupplierCode"
        v-model:formValues="currentFormValues"
        :priceSuggestion="priceSuggestion"
        :formFields="formField.sub_fields"
        :hideTitle="hideTitle"
        @input="e => emit('input', e)"
        :addressSuggestion="addressSuggestion"
        :excludedFields="excludedFields"
        @priceChange="emit('priceChange')"
        :includedFields="includedFields"
        @showAddSupplier="emit('showAddSupplier')"
        @update:formValues="emit('update:formValues', currentFormValues)"
        @update:supplierCode="
          supplierCode => emit('update:supplierCode', supplierCode)
        "
        @update:productCategory="
          category => emit('update:productCategory', category)
        "
        :supplierCode="currentSupplierCode"
        :formIsSubmitted="formIsSubmitted"
      ></GreenifiedForm>

      <div :class="!hideTitle ? 'col-8' : 'col-12'" v-else>
        <div class="position-relative">
          <InputField
            v-if="formField.type === 'text' && formField.name !== 'varumarke'"
            :required="formField.required === 0 ? false : true"
            :name="formField.key"
            :type="
              numericFieldTypes.includes(formField.name) ? 'number' : 'text'
            "
            maxlength="60"
            :id="formField.key"
            :autocomplete="
              formField.name === 'pris' || formField.name === 'adress'
                ? 'off'
                : 'on'
            "
            :key="String(addressFieldUpdated)"
            :placeHolder="formField.placeholder"
            v-model:fieldValue="currentFormValues[formField.key]"
            :showUnit="'append' in formField && formField.append ? true : false"
            :errorWithInputValue="
              formField.required === 1 &&
              !String(currentFormValues[formField.key]).length &&
              formIsSubmitted
            "
            @update:fieldValue="
              inputText(currentFormValues[formField.key], formField);
              if (
                formField.name === 'pris' ||
                formField.name === 'saljs_styckvis_eller_i_grupp' ||
                formField.name === 'ange-antal'
              )
                emit('priceChange');
            "
            @click="toggleDropdown = true"
          />
          <AddressField
            v-if="
              formField.type === 'text' &&
              formField.name === 'adress' &&
              addressSuggestion?.length > 0
            "
            :addressField="formField"
            :addressSuggestions="greenifiedFormProps.addressSuggestion"
            v-model:addressFieldValues="currentFormValues"
            v-model:toggleDropdown="toggleDropdown"
            @update:addressFieldValues="
              addressFieldUpdated = !addressFieldUpdated
            "
          />
        </div>

        <MessageField
          v-if="formField.type === 'message'"
          :messageField="formField"
        />

        <PriceField
          v-if="formField.name === 'pris' && priceSuggestion.length > 0"
          v-model:currentPrice="currentFormValues[formField.key]"
          :priceSuggestions="priceSuggestion"
          @update:currentPrice="emit('update:formValues', currentFormValues)"
        />

        <InputField
          v-if="formField.type === 'date_picker'"
          :required="formField.required === 0 ? false : true"
          type="date"
          id="name-id"
          maxValue="9999-12-31"
          :name="formField.key"
          maxlength="60"
          v-model:fieldValue="currentFormValues[formField.key]"
          @update:fieldValue="
            inputText(currentFormValues[formField.key], formField)
          "
          :errorWithInputValue="
            formField.required === 1 &&
            !String(currentFormValues[formField.key]).length &&
            formIsSubmitted
          "
        />

        <div class="row row-gap-2" v-if="formField.type === 'checkbox'">
          <div
            :class="formField.name === 'lest_og_godkjent' ? 'col-12' : 'col-4'"
            v-for="(choice, choiceKey) in formField.choices"
            :key="choice"
          >
            <CheckboxField
              v-if="Object.keys(formField.choices).length === 1"
              :option="choice"
              v-model:presetCheckboxValues="currentFormValues[formField.key]"
              :checked="currentFormValues[formField.key] === choiceKey"
              :checkboxField="formField"
              :trueValue="choiceKey"
              :falseValue="null"
              @delete="delete currentFormValues[formField.key]"
              @input="(inputValue: InputValue) => emit('input', inputValue)"
              @update:presetCheckboxValues="
                emit('update:formValues', currentFormValues)
              "
            />
            <MultipleCheckboxField
              v-if="Object.keys(formField.choices).length > 1"
              :option="choice"
              v-model:presetMultipleCheckboxValues="
                currentFormValues[formField.key]
              "
              :multipleCheckboxField="formField"
              @delete="delete currentFormValues[formField.key]"
              @update:presetMultipleCheckboxValues="
                emit('update:formValues', currentFormValues)
              "
            />
          </div>
        </div>

        <template v-if="formField.type === 'radio'">
          <div
            class="row-gap-2"
            :class="
              formField.name === 'skick' ? 'col-12 d-flex flex-wrap' : 'row'
            "
          >
            <div
              v-for="(choice, choiceKey) in formField.choices"
              :key="choice"
              :class="formField.name === 'skick' ? 'me-3' : 'col-4'"
            >
              <RadioField
                v-if="Object.keys(formField.choices).length === 1"
                :option="choice"
                :optionKey="choiceKey"
                :presetRadioFieldValues="currentFormValues[formField.key]"
                :radioField="formField"
                @update:presetRadioFieldValues="
                  emit('update:formValues', currentFormValues)
                "
              />
              <MultipleRadioField
                v-if="Object.keys(formField.choices).length > 1"
                :thinButton="formField.name === 'skick' ? true : false"
                v-model:presetMultipleRadioFieldValues="
                  currentFormValues[formField.key]
                "
                :multipleRadioField="formField"
                :optionKey="choiceKey"
                :option="choice"
                :checked="
                  currentFormValues[formField.key] === choiceKey ? true : false
                "
                @update:presetMultipleRadioFieldValues="
                  emit('update:formValues', currentFormValues)
                "
              />
            </div>
          </div>
        </template>
        <CheckboxField
          v-if="formField.type === 'true_false'"
          :checkboxField="formField"
          :checked="currentFormValues[formField.key] === 1"
          :trueValue="1"
          :falseValue="0"
          v-model:presetCheckboxValues="currentFormValues[formField.key]"
          @delete="delete currentFormValues[formField.key]"
          @input="(inputValue: InputValue) => emit('input', inputValue)"
          @update:presetCheckboxValues="
            emit('update:formValues', currentFormValues)
          "
        />
        <SupplierField
          v-if="formField.name === 'varumarke' && formField.type === 'text'"
          :presetSupplierField="formField"
          v-model:supplierFieldValues="currentFormValues[formField.key]"
          v-model:currentSupplierCode="currentSupplierCode"
          @showSupplierModal="emit('showAddSupplier')"
          @update:supplierFieldValues="
            emit('update:formValues', currentFormValues)
          "
          :key="currentSupplierCode"
          @update:currentSupplierCode="
            supplierCode => emit('update:supplierCode', supplierCode)
          "
          :showError="
            formField.required === 1 &&
            formIsSubmitted &&
            (!currentFormValues[formField.key] ||
              !String(currentFormValues[formField.key]).length)
          "
        />
        <TaxonomyField
          v-if="
            formField.type === 'acfe_taxonomy_terms' &&
            formField.field_type === 'select'
          "
          :taxonomyField="formField"
          v-model:taxonomyFieldValues="currentFormValues[formField.key]"
          @update:taxonomyFieldValues="(option: ProjectCategory['id']) => { changeSelect(formField, option)}"
          :showError="
            formField.required === 1 &&
            formIsSubmitted &&
            (!currentFormValues[formField.key] ||
              !String(currentFormValues[formField.key]).length)
          "
        />

        <SelectField
          v-if="formField.type === 'select'"
          :selectField="formField"
          :key="String(addressFieldUpdated)"
          v-model:selectFieldValues="currentFormValues[formField.key]"
          @update:selectFieldValues="(option: SelectOptions['key']) => changeSelect(formField, option)"
          :showError="
            formField.required === 1 &&
            formIsSubmitted &&
            (!currentFormValues[formField.key] ||
              !String(currentFormValues[formField.key]).length)
          "
        />
      </div>
    </div>
  </template>
</template>

<script setup lang="ts">
import {
  ref,
  defineEmits,
  defineExpose,
  defineProps,
  withDefaults,
  watch
} from "vue";
import { AddressInformation } from "@/library/types/room/rooms";
import { Subfields } from "@/library/types/structure/subfieldTypes";
import {
  ConditionalLogic,
  FormFields,
  GroupFields
} from "@/library/types/structure/formStructure";
import AddressField from "./fields/AddressField.vue";
import PriceField from "./fields/PriceField.vue";
// eslint-disable-next-line @typescript-eslint/no-unused-vars, no-unused-vars
import CheckboxField, { InputValue } from "./fields/CheckboxField.vue";
import MultipleCheckboxField from "./fields/MultipleCheckboxField.vue";
import RadioField from "./fields/RadioField.vue";
import MultipleRadioField from "./fields/MultipleRadioField.vue";
// eslint-disable-next-line @typescript-eslint/no-unused-vars, no-unused-vars
import { ProjectCategory } from "@/library/types/project/project";
import SupplierField from "./fields/SupplierField.vue";
import TaxonomyField from "./fields/TaxonomyField.vue";
// eslint-disable-next-line @typescript-eslint/no-unused-vars, no-unused-vars
import SelectField, { SelectOptions } from "./fields/SelectField.vue";
import InputField from "../common/InputField.vue";
import FormImages from "./fields/ImageField.vue";
import FieldLabel from "./fields/FieldLabel.vue";
import MessageField from "./fields/MessageField.vue";
import {
  FormImagesObject,
  ProductImages
} from "@/library/types/product/productImage";

type GreenifiedFormProps = {
  formValues: FormFields;
  formFields: GroupFields[] | Subfields[];
  hideTitle?: boolean;
  addressSuggestion?: AddressInformation[];
  priceSuggestion?: { estimatedvalue: string }[];
  excludedFields?: string[];
  includedFields?: string[];
  supplierCode?: string;
  formImages?: FormImagesObject;
  formIsSubmitted?: boolean;
  productImages?: ProductImages | null;
};

const greenifiedFormProps = withDefaults(defineProps<GreenifiedFormProps>(), {
  formValues: () => ({}),
  formFields: () => [],
  hideTitle: false,
  addressSuggestion: () => [],
  priceSuggestion: () => [],
  excludedFields: () => [],
  includedFields: () => [],
  supplierCode: "",
  formImages: () => ({
    bild_1: {
      fieldKey: "",
      imageHash: ""
    },
    bild_2: {
      fieldKey: "",
      imageHash: ""
    }
  }),
  formIsSubmitted: false,
  productImages: null
});

const emit = defineEmits([
  "priceChange",
  "input",
  "showAddSupplier",
  "update:formValues",
  "update:formIsSubmitted",
  "update:productImages",
  "update:supplierCode",
  "update:productCategory"
]);
const numericFieldTypes = [
  "langd",
  "bredd",
  "djup",
  "hojd",
  "diameter",
  "sitthojd",
  "pris"
];
const addressFieldUpdated = ref(false);
const toggleDropdown = ref(false);
const currentFormImages = ref(greenifiedFormProps.formImages);
const currentFormValues = ref(greenifiedFormProps.formValues);
const currentSupplierCode = ref(greenifiedFormProps.supplierCode);
const formRefs = ref<(HTMLDivElement | null)[]>([]);
const fieldHasLabel = (
  formFieldType: GroupFields["type"] | Subfields["type"]
) => {
  if (greenifiedFormProps.hideTitle) return false;

  if (
    formFieldType !== "group" &&
    formFieldType !== "acfe_hidden" &&
    formFieldType !== "image" &&
    formFieldType !== "message"
  )
    return true;

  return false;
};

const filteredFormFields = (formFields: GroupFields[] | Subfields[]) => {
  const validFields: (GroupFields | Subfields)[] = [];
  const invalidFields: (GroupFields | Subfields)[] = [];

  formFields.forEach(formField => {
    if (
      checkConditions(formField) &&
      (greenifiedFormProps.includedFields.length
        ? greenifiedFormProps.includedFields.includes(formField.name)
        : !greenifiedFormProps.excludedFields.includes(formField.name))
    ) {
      validFields.push(formField);
    } else {
      invalidFields.push(formField);
    }
  });
  return { validFields: validFields, invalidFields: invalidFields };
};

const updateFormImages = (
  formValues: FormFields,
  formImages: FormImagesObject
) => {
  const currFormValues = { ...formValues };
  const currFormImages = { ...formImages };
  for (const imageKey in currFormImages) {
    const formImage = currFormImages[imageKey as keyof FormImagesObject];
    if (formImage) {
      if (formImage.fieldKey in currFormValues && !formImage.imageHash) {
        delete currFormValues[formImage.fieldKey];
      }

      if (formImage.imageHash) {
        currFormValues[formImage.fieldKey] = formImage.imageHash;
      }
    }
  }

  return currFormValues;
};

const getContainerOrRowStyling = (
  index: number,
  formField: GroupFields | Subfields
) => {
  if (formField.type === "group") return "container-fluid ps-0 pe-0 m-0";

  const elementHeight = formRefs.value[index]?.clientHeight;
  if (!elementHeight) return "row";

  if (elementHeight > 35) return "row mb-3";

  return "row mb-3 align-items-center";
};

const operatorMeetsConditions = (conditions: ConditionalLogic) => {
  for (let i = 0; i < conditions.length; i++) {
    const condition = conditions[i];
    if (
      condition.operator === "==empty" &&
      currentFormValues.value[condition.field as keyof Subfields] != null
    ) {
      return false;
    } else if (
      condition.operator === "!=empty" &&
      currentFormValues.value[condition.field as keyof Subfields] == null
    ) {
      return false;
    } else if (
      !condition.operator.includes("empty") &&
      eval(
        '"' +
          String(currentFormValues.value[condition.field as keyof Subfields]) +
          '"' +
          condition.operator +
          ('"' + String(condition["value"]) + '"')
      ) === false
    ) {
      return false;
    }
  }

  return true;
};

const checkConditions = (structureField: GroupFields | Subfields) => {
  const structureConditions = structureField.conditional_logic;
  if (structureConditions === 0) return true;

  if (structureConditions.some(condition => operatorMeetsConditions(condition)))
    return true;

  delete currentFormValues.value[structureField.key as keyof Subfields];
  return false;
};

const inputText = (
  fieldValue: FormFields["key"],
  field: GroupFields | Subfields
) => {
  emit("input", {
    key: field.key,
    name: field.name,
    value: fieldValue
  });
  emit("update:formValues", currentFormValues.value);
};

const changeSelect = (field: Subfields, fieldValue: string) => {
  currentFormValues.value[field.key as keyof Subfields] = fieldValue;

  emit("input", {
    key: field.key,
    name: field.name,
    value: fieldValue
  });

  emit("update:formValues", currentFormValues.value);
  if (field.name === "kategori") {
    emit("update:productCategory", fieldValue);
  }
};

const fieldIsValid = (field: Subfields | GroupFields) => {
  if (field.required === 0) return true;

  const currentFieldValue = currentFormValues.value[field.key];
  if (field.required === 1 && !currentFieldValue) return false;

  if (typeof currentFieldValue === "number" && currentFieldValue > 0) {
    return true;
  }

  if (typeof currentFieldValue === "string" && currentFieldValue.length) {
    return true;
  }

  return false;
};

const removeUnusedFieldValues = (
  formFieldsToRemove: (GroupFields | Subfields)[]
) => {
  formFieldsToRemove.forEach(formField => {
    delete currentFormValues.value[formField.key];

    if ("sub_fields" in formField && formField.sub_fields.length > 0) {
      removeUnusedFieldValues(formField.sub_fields);
    }
  });
};

const validateGreenifiedForm = (): boolean => {
  removeUnusedFieldValues(
    filteredFormFields(greenifiedFormProps.formFields).invalidFields
  );

  const filteredFormGroupFields = filteredFormFields(
    greenifiedFormProps.formFields
  ).validFields;
  const formHasInvalidGroupFields = filteredFormGroupFields.some(
    groupField => !fieldIsValid(groupField)
  );

  if (formHasInvalidGroupFields) return false;

  let formHasInvalidSubFields = false;

  for (let i = 0; i < filteredFormGroupFields.length; i++) {
    const groupField = filteredFormGroupFields[i];
    if ("sub_fields" in groupField) {
      const filteredFormSubFields = filteredFormFields(
        groupField.sub_fields
      ).validFields;

      formHasInvalidSubFields = filteredFormSubFields.some(
        subField => !fieldIsValid(subField)
      );
      if (formHasInvalidSubFields) return false;
    }
  }

  emit("update:formIsSubmitted", false);

  return true;
};

watch(
  () => greenifiedFormProps.supplierCode,
  newSupplier => {
    currentSupplierCode.value = newSupplier;
  }
);

defineExpose({ validateGreenifiedForm });
</script>
